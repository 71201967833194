import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
	selector: 'cactussoft-required-checkbox',
	templateUrl: 'required-checkbox.component.html',
	styleUrls: ['required-checkbox.component.scss']
})
export class RequiredCheckboxComponent {
	@Input() public formGroup: UntypedFormGroup;
	constructor() {}
}
