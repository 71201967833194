import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'cactussoft-badge',
	templateUrl: './badge.component.html',
	styleUrls: ['./badge.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgeComponent {
	@Input() public badgeData: number;
	@Output() public openDialog: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor() {}

	public onOpenDialog(): void {
		this.openDialog.emit(true);
	}
}
