export const SNACK_BAR_MESSAGES: any = {
	created: 'was created.',
	updated: 'was updated.',
	deleted: 'was deleted.',
	creationFailed: 'Creation failed',
	updateFailed: 'Update failed',
	deleteFailed: 'Deletion failed',
	adminUnableToDeactivate: 'Admin is unable to deactivate pending user',
	profileHasBeenUpdated: 'Profile has been successfully updated'
};
