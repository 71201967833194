import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { isNullOrUndefined } from '@shared/tools/is-undefined-null';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FormValidationService } from '@shared/services/update-validity/update-validity.service';
import { UploaderData, UploaderTypes } from '@shared/components/uploader/uploader-types';
import {
	CreateIntegrationDtoInterface,
	IntegrationDtoInterface
} from '@cactussoft/services/services-board/create-integration/integration-dto.interface';
import { MultiLanguageDialogComponent } from '@shared/components/multi-language-dialog/multi-language-dialog.component';
import { DeleteServiceComponent } from '@cactussoft/services/services-board/delete-service/delete-service.component';
import { of, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { ClientServicesService } from '@cactussoft/services/services/client-services.service';
import { SNACK_BAR_MESSAGES } from '@shared/services/snack-bar/snack-bar-messages.constants';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarService } from '@shared/services/snack-bar/snack-bar.service';
import { switchMap } from 'rxjs/operators';
import { SERVICE_TYPES } from '@cactussoft/services/constants/service-types.constants';

@Component({
	selector: 'cactussoft-create-integration',
	templateUrl: './create-integration.component.html',
	styleUrls: ['./create-integration.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateIntegrationComponent implements OnInit, OnDestroy {
	public instanceId: string;
	public newIntegrationForm: UntypedFormGroup;
	public uploaderType: string = UploaderTypes.integration;
	public initialImage: UploaderData;
	public dialogRef: MatDialogRef<DeleteServiceComponent>;
	public integration: IntegrationDtoInterface;
	private readonly destroy$ = new Subject();
	public disabledCreateButton: boolean = false;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: UntypedFormBuilder,
		private matDialog: MatDialog,
		private updateValidityService: FormValidationService,
		private dialog: MatDialog,
		private snackBarService: SnackBarService,
		private clientsService: ClientServicesService,
		private checkFormWasInitialized: ChangeDetectorRef
	) {}

	public getHeaderLabel(): string {
		return !isNullOrUndefined(this.instanceId) ? `Edit integration` : 'Create new integration';
	}

	public uploadIntegrationPicture(url: string): void {
		this.newIntegrationForm.controls['picture'].setValue(url);
	}

	public ngOnInit(): void {
		this.route.params
			.pipe(
				takeUntil(this.destroy$),
				tap((params: Params) => (this.instanceId = params['id'])),
				switchMap((params: Params) => {
					return params['id'] ? this.clientsService.getServiceById(params['id']) : of(null);
				}),
				tap((integration: IntegrationDtoInterface) => {
					this.integration = integration;
					this.createForm(integration);
				})
			)
			.subscribe();
	}

	public ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.complete();
	}

	private createForm(integration: IntegrationDtoInterface): void {
		this.initialImage = integration ? { image: integration?.pictureUrl, title: 'Integration image' } : undefined;
		this.newIntegrationForm = this.formBuilder.group({
			picture: new UntypedFormControl(integration ? integration.pictureUrl : null),
			names: this.formBuilder.group({
				English: new UntypedFormControl(integration ? integration.names.English : null, [
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(61)
				]),
				French: new UntypedFormControl(integration ? integration.names.French : null, []),
				Dutch: new UntypedFormControl(integration ? integration.names.Dutch : null, []),
				German: new UntypedFormControl(integration ? integration.names.German : null, [])
			}),
			url: new UntypedFormControl(integration ? integration.url : null, [Validators.required, this.urlValidator])
		});
		this.checkFormWasInitialized.markForCheck();
	}

	public openMultiLanguageDialog(inputFormGroup: any, type: string, placeholder: string): void {
		const dialogRef: MatDialogRef<MultiLanguageDialogComponent> = this.matDialog.open(MultiLanguageDialogComponent, {
			data: { value: inputFormGroup.value, type: type, placeholder: placeholder },
			width: '800px',
			height: 'auto'
		});
		dialogRef.componentInstance.dialogSource.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
			inputFormGroup.setValue(data);
			inputFormGroup.markAllAsTouched();
			dialogRef.close();
		});
	}

	public cancel(): void {
		this.router.navigate(['/services'], { relativeTo: this.route });
	}

	public composeIntegrationFormData = (): CreateIntegrationDtoInterface => {
		return {
			names: this.newIntegrationForm.value.names,
			pictureUrl: this.newIntegrationForm.value.picture,
			url: this.newIntegrationForm.value.url,
			serviceType: SERVICE_TYPES.integration,
			isInactive: false
		};
	};

	public createIntegration(): void {
		const newIntegrationData = this.composeIntegrationFormData();
		this.updateValidityService.triggerFormValidation();
		if (!this.disabledCreateButton) {
			// basically 'integration' is the same as service, but with specific type and with smaller amount of required fields
			this.clientsService
				.createService(newIntegrationData)
				.pipe(takeUntil(this.destroy$))
				.subscribe(
					() => {
						this.disabledCreateButton = true;
						this.router.navigate(['/services'], { relativeTo: this.route });
						this.snackBarService.showSnackBar(`${newIntegrationData.names.English} ${SNACK_BAR_MESSAGES.created}`);
					},
					(err: HttpErrorResponse) => {
						this.disabledCreateButton = false;
						this.snackBarService.showSnackBar(SNACK_BAR_MESSAGES.creationFailed);
					}
				);
		}
		this.updateValidityService.triggerFormValidation();
	}

	public editIntegration(): void {
		const newIntegrationData = this.composeIntegrationFormData();
		this.updateValidityService.triggerFormValidation();

		this.clientsService.editService(newIntegrationData, this.instanceId).subscribe(
			() => {
				this.router.navigate(['/services'], { relativeTo: this.route });
				this.snackBarService.showSnackBar(`${newIntegrationData.names.English} ${SNACK_BAR_MESSAGES.updated}`);
			},
			(err: HttpErrorResponse) => {
				this.snackBarService.showSnackBar(SNACK_BAR_MESSAGES.updateFailed);
			}
		);
	}

	public removeIntegration(): void {
		this.dialogRef = this.dialog.open(DeleteServiceComponent, {
			data: {
				id: this.instanceId,
				serviceType: this.integration.serviceType
			},
			width: '430px'
		});
		this.dialogRef.componentInstance.dialogSource.subscribe(() => {
			this.dialogRef.close();
			this.router.navigate(['/services']);
		});
	}

	get namesFormGroup(): any {
		return this.newIntegrationForm.controls.names as UntypedFormGroup;
	}

	urlValidator(control: AbstractControl): { [key: string]: boolean } | null {
		const value = control.value;
		if (value && !value.startsWith('http') && !value.startsWith('https')) {
			return { invalidUrl: true };
		}
		return null;
	}
}
