import { DeliveryCost } from '@cactussoft/services/interfaces/service-dto.interface';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DeliveryPriceService {
	public isDeliveryPriceValuesNeed: Subject<boolean> = new Subject();
	public deliveryPriceValues: DeliveryCost[] = null;
	public isDeliveryValid: boolean = true;
	constructor() {}
}
