import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SpaceAttachment } from '@cactussoft/buildings/interfaces/building-dto.interface';
import { NewsAttachment } from '@cactussoft/news/interfaces/news-dto.interface';

const baseUrl: string = `${JSON.parse(localStorage.getItem('env'))?.fileServer}`;

@Injectable({
	providedIn: 'root'
})
export class FileService {
	constructor(private http: HttpClient) {}

	public uploadFile(file: FormData): Observable<{ fileExtension: string; fileUrl: string }> {
		return this.http.post<{ fileExtension: string; fileUrl: string }>(`${baseUrl}/api/v1/files`, file);
	}

	public deleteUserAvatar(userId: number, avatarName: string): Observable<any> {
		return avatarName ? this.http.delete(`${baseUrl}/api/v1/files/all/${userId}?avatarkey=${avatarName}`) : of({});
	}

	public formatFilesForServer(files: SpaceAttachment[] | NewsAttachment[]): SpaceAttachment[] | NewsAttachment[] {
		if (!files || files.length <= 0) {
			return [];
		}

		return files.map((file: SpaceAttachment | NewsAttachment) => {
			const fileCopy: SpaceAttachment | NewsAttachment = { ...file };
			delete fileCopy.previewUrl;
			return fileCopy;
		});
	}
}
