<div class='multi-uploader-container'>
	<div class='uploaded-files'>
		<div class='uploaded-files__item' *ngFor='let uploaderData of uploadedFiles;let i = index'>
			<img [src]='uploaderData.previewUrl' height='24px' width='24px' />
			<span>{{uploaderData.name}}</span>
			<mat-icon (click)='removeFile(i)'>close</mat-icon>
		</div>
	</div>
	<label class='multi-uploader-label' (click)='fileInput.value = null'>
		<div class='multi-uploader-label__button'>
			<mat-icon>attach_file</mat-icon>
			Attach file
			<input
				#fileInput
				type='file'
				[accept]='".rtf,.xlxs,application/rtf,application/msword, application/octet-stream, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*,application/vnd.openxmlformats-officedocument.wordprocessingml.document"'
				style='display: none;'
				(change)='onFileChange(fileInput.files)'
				multiple
			/>
		</div>
		<span class='multi-uploader-label__prompt'>Max 3</span>
	</label>
</div>
