<div class='save-as-template-dialog-container'>
	<div class='save-as-template-dialog-container__header'>
		<span>Save as template</span>
		<mat-icon mat-dialog-close class='cancel-button'>clear</mat-icon>
	</div>
	<form [formGroup]='saveAsTemplateForm' *ngIf='saveAsTemplateForm'>
		<div class='save-as-template-form'>
			<div class='save-as-template-form__row'>
				<div class='save-as-template-form__row-block' [formGroup]='namesFormGroup'>
					<mat-form-field class='save-as-template-form__row-input' appearance='fill'>
						<mat-label> Name
						</mat-label>
						<input matInput formControlName='English' required>
						<mat-error
							*ngIf='namesFormGroup.controls.English.touched && namesFormGroup.controls.English.invalid'>
							<span *ngIf='namesFormGroup.controls.English.errors.required'>Required field</span>
						</mat-error>
					</mat-form-field>
					<mat-icon class='save-as-template-form__language-icon'
							  (click)='openMultiLanguageDialog(namesFormGroup,"input","Name")'>language
					</mat-icon>
				</div>
			</div>
		</div>

		<div class='save-as-template-form__buttons'>
			<div class='button-item secondary' mat-dialog-close>
				Cancel
			</div>
			<div class='button-item primary' (click)='emitPageValue()'>
				Save
			</div>
		</div>
	</form>
</div>
