import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { AuthService } from '@shared/services/auth/auth.service';
import { SnackBarService } from '@shared/services/snack-bar/snack-bar.service';

import { ADMIN_SLIDE_MENU_LINKS, MANAGER_SLIDE_MENU_LINKS, MenuLink } from '../accordion-menu/slide-menu-links.constants';

@Component({
	selector: 'cactussoft-container',
	templateUrl: './container.component.html',
	styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit, AfterViewInit {
	public slideMenuLinks: MenuLink[] = [];
	public isLogin: boolean;

	@ViewChild('container') private _container: any;

	constructor(public authService: AuthService, private snackBarService: SnackBarService, private materialSnackBar: MatSnackBar) {}

	public ngOnInit(): void {
		this.authService.updateContainerSubject.subscribe(() => {
			this.isLogin = Boolean(localStorage.getItem('JWT_TOKEN'));
			this.slideMenuLinks = this.authService.getUserRole() === 'Admin' ? ADMIN_SLIDE_MENU_LINKS : MANAGER_SLIDE_MENU_LINKS;
		});
		this.snackBarService.snackBarChanged$.subscribe(
			(message: string) => {
				this.materialSnackBar.open(message, 'Close', {
					duration: 3000,
					// to change container position only for snackbar - check shared.scss, .cdk-overlay-container, .cdk-global-overlay-wrapper
					horizontalPosition: 'end',
					verticalPosition: 'bottom'
				});
			},
			() => this.snackBarService.showSnackBar('Unknown error')
		);
	}

	public ngAfterViewInit(): void {
		this._container._ngZone.onMicrotaskEmpty.subscribe(
			() => {
				// this._container._updateStyles();
				this._container._changeDetectorRef.markForCheck();
			},
			() => this.snackBarService.showSnackBar('Unknown error')
		);
	}

	public logout(): void {
		this.authService.doLogoutUser();
	}
}
