<div class='labels-builder-container' [formGroup]='formGroup'>
	<div class='component-container'>
		<div style='width: 100%;'>
			<div class='component'>
				<div class='draggable-icon-wrapper' cdkDrag>
					<mat-icon class='component-icon'>drag_indicator</mat-icon>
				</div>
				<div class='component-icon-wrapper'>
					<mat-icon class='component-icon'>{{iconName}}</mat-icon>
				</div>
				<div class='component-input' [formGroup]='titlesFormGroup'>
					<mat-form-field class='form__row-input' appearance='fill'>
						<mat-label> {{placeholder.title}}
						</mat-label>
						<input matInput formControlName='English' required>
						<mat-error
							*ngIf='titlesFormGroup.controls.English.touched && titlesFormGroup.controls.English.invalid'>
							<span *ngIf='titlesFormGroup.controls.English.errors.required'>Required field</span>
							<span
								*ngIf='titlesFormGroup.controls.English.errors.maxlength'> Max length - 400 char</span>
						</mat-error>
					</mat-form-field>
				</div>
				<div class='option-actions'>
					<mat-icon class='icon' (click)='openMultiLanguageDialog(titlesFormGroup,"input",placeholder.title)'>
						language
					</mat-icon>
				</div>
			</div>
		</div>
		<div class='remove-container'>
			<mat-icon class='icon' (click)='remove()'>delete</mat-icon>
		</div>
	</div>
	<ng-container *ngFor='let labelFormGroup of variantsControls; let i = index'>
		<div class='component-container' [formGroup]='labelFormGroup'>
			<div style='width: 100%;'>
				<div class='component'>
					<div class='draggable-icon-wrapper'></div>
					<div class='component-icon-wrapper empty'></div>
					<div class='component-input' [formGroup]='labelFormGroup.controls.names'>
						<mat-form-field appearance='fill'>
							<mat-label> {{placeholder.option}} {{i + 1}}
							</mat-label>
							<input matInput formControlName='English' required>
							<mat-error
								*ngIf='labelFormGroup.controls.names.controls.English.touched && labelFormGroup.controls.names.controls.English.invalid'>
								<span *ngIf='labelFormGroup.controls.names.controls.English.errors.required'>Required field</span>
								<span *ngIf='labelFormGroup.controls.names.controls.English.errors.maxlength'> Max length - 25 char</span>
							</mat-error>
						</mat-form-field>
					</div>
					<div class='option-actions'>
						<mat-icon class='icon'
								  (click)='openMultiLanguageDialog(labelFormGroup.controls.names,"input",placeholder.option)'>
							language
						</mat-icon>
						<mat-icon class='icon' *ngIf='i!== 0' (click)='remove(i)'>delete</mat-icon>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
	<span class='add-variant-label' *ngIf='variantsControls.length < 8'
		  (click)='addFormControl()'>+ Add label</span>
	<div class="required-checkbox-container">
		<cactussoft-required-checkbox [formGroup]="formGroup"></cactussoft-required-checkbox>
	</div>
</div>
