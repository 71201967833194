<div class='forgot-password-dialog-container'>
	<div class='forgot-password-dialog-container__header'>
		<h2>Forgot password</h2>
		<mat-icon mat-dialog-close class='cancel-button'>clear</mat-icon>
	</div>
	<div class='forgot-password-dialog-container__content'>
		We’ll email you a link to reset your password
	</div>
	<div class='forgot-password-dialog-container__input'>
		<mat-form-field appearance='fill'>
			<mat-label> Email
			</mat-label>
			<span matPrefix><mat-icon>email</mat-icon></span>
			<input matInput required [(ngModel)]="email">
		</mat-form-field>
	</div>
	<div class='forgot-password-dialog-container__buttons' mat-dialog-actions>
		<div class='button-item secondary' (click)='onChange(false)'>
			Cancel
		</div>
		<div class='button-item primary' (click)='onChange(true)'>
			Reset
		</div>
	</div>
</div>
