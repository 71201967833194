/* eslint-disable no-magic-numbers */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { forkJoin, Subject } from 'rxjs';
import { UploaderData, UploaderTypes } from '@shared/components/uploader/uploader-types';
import { SnackBarService } from '@shared/services/snack-bar/snack-bar.service';
import { isNullOrUndefined } from '@shared/tools/is-undefined-null';
import { SNACK_BAR_MESSAGES } from '@shared/services/snack-bar/snack-bar-messages.constants';
import { CURRENCY_TYPES } from '@cactussoft/services/constants/currency-types.constants';
import { CreateServiceDtoInterface } from '@cactussoft/services/interfaces/create-service-dto.interface';
import { ClientServicesService } from '@cactussoft/services/services/client-services.service';
import { DraggableListInterface, SubcategoriesInterface } from './draggable-components.interface';
import { DRAGGABLE_COMPONENTS, SUBCATEGORIES_NAMES } from './draggable-components.constants';
import { ServiceDtoInterface } from '@cactussoft/services/interfaces/service-dto.interface';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SaveAsTamplateDialogComponent } from '@shared/components/save-as-tamplate-dialog/save-as-tamplate-dialog.component';
import { TemplatesService } from '../../../../../templates/src/lib/services/templates.service';
import { TemplateInterface } from '../../../../../templates/src/lib/interfaces/template.interface';
import { MultiLanguageDialogComponent } from '@shared/components/multi-language-dialog/multi-language-dialog.component';
import { MultiLanguageValueInterface } from '@shared/interfaces/multi-language.interface';
import { FormValidationService } from '@shared/services/update-validity/update-validity.service';
import { DeleteServiceComponent } from '@cactussoft/services/services-board/delete-service/delete-service.component';
import { QuillModules } from 'ngx-quill';
import { DeliveryPriceService } from '@shared/components/delivery-price/delivery-price.service';
import { ComposeService } from '@cactussoft/services/services-board/create-service/service-composer';

@Component({
	selector: 'cactussoft-create-service',
	templateUrl: './create-service.component.html',
	styleUrls: ['./create-service.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateServiceComponent implements OnInit, OnDestroy {
	public listOfComponents: DraggableListInterface = DRAGGABLE_COMPONENTS;
	public categoriesLabels: SubcategoriesInterface = SUBCATEGORIES_NAMES;
	public currencyTypes: string[] = CURRENCY_TYPES;
	public keysOfCategories: string[] = Object.keys(this.listOfComponents);
	public allTemplates: TemplateInterface[];
	public service: ServiceDtoInterface;
	public instanceId: string;
	public typeOfInstance: string;
	public initialImage: UploaderData;
	public uploaderType: string = UploaderTypes.services;
	public newServiceForm: UntypedFormGroup;
	public serviceComponentsList: any = [];
	public selectedTemplate: any;
	public disabledCreateButton: boolean = false;
	public dialogRef: MatDialogRef<DeleteServiceComponent>;
	public destroy$: Subject<void> = new Subject();

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: UntypedFormBuilder,
		private cdr: ChangeDetectorRef,
		private clientsService: ClientServicesService,
		private snackBarService: SnackBarService,
		private templatesService: TemplatesService,
		private matDialog: MatDialog,
		private updateValidityService: FormValidationService,
		public dialog: MatDialog,
		private deliveryPriceService: DeliveryPriceService
	) {}

	public ngOnInit(): void {
		this.route.params.subscribe((params: Params) => {
			this.instanceId = params['id'];
			this.typeOfInstance = params['instance'];
			if (this.typeOfInstance === 'template') {
				this.templatesService.getTemplateById(this.instanceId).subscribe(
					(service: ServiceDtoInterface) => {
						this.initServiceInstance(service);
					},
					() => this.snackBarService.showSnackBar('Unknown error')
				);
			} else {
				this.getService();
			}
		});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
	}

	public getHeaderLabel(): string {
		return !isNullOrUndefined(this.instanceId) ? `Edit ${this.typeOfInstance}` : 'Create new service';
	}

	public noReturnPredicate(): boolean {
		return false;
	}

	public uploadServicePicture(url: string): void {
		this.newServiceForm.controls['picture'].setValue(url);
	}

	public openMultiLanguageDialog(inputFormGroup: any, type: string, placeholder: string): void {
		const dialogRef: MatDialogRef<MultiLanguageDialogComponent> = this.matDialog.open(MultiLanguageDialogComponent, {
			data: { value: inputFormGroup.value, type: type, placeholder: placeholder },
			width: '800px',
			height: 'auto'
		});
		dialogRef.componentInstance.dialogSource.subscribe((data: any) => {
			inputFormGroup.setValue(data);
			inputFormGroup.markAllAsTouched();
			dialogRef.close();
		});
	}

	public setComponentInitialValues(contents: any): void {
		contents.forEach((content: any) => {
			switch (content.type) {
				case 1:
				case 2:
				case 3:
				case 5:
				case 10:
				case 14: {
					this.serviceComponentsList.push({
						...this.getDraggableComponentByType('listComponents', content.type),
						controlName: this.addingFormControl(content),
						initialValues: content
					});
					break;
				}
				case 6:
				case 7:
				case 8:
				case 9:
				case 15:
				case 13: {
					this.serviceComponentsList.push({
						...(this.getDraggableComponentByType('textComponents', content.type) ||
							this.getDraggableComponentByType('otherComponents', content.type)),
						controlName: this.addingFormControl(content),
						initialValues: content
					});
					break;
				}
				case 4:
				case 11: {
					this.serviceComponentsList.push({
						...this.getDraggableComponentByType('timeAndDateComponents', content.type),
						controlName: this.addingFormControl(content),
						initialValues: content
					});
					break;
				}
				case 12: {
					this.serviceComponentsList.push({
						...this.getDraggableComponentByType('imageComponents', content.type),
						controlName: this.addingFormControl(content),
						initialValues: content
					});
					break;
				}
			}
		});
	}

	public getDraggableComponentByType(groupName: string, type: number): any {
		return this.listOfComponents[groupName].find((component: any) => component.type === type);
	}

	public drop(event: any): void {
		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			// TODO: issue with coping elements. Need to do deep copy.
			//  But with copyArrayItem it's copy with one object link, but we need different to each.
			const clone: any = { ...event.previousContainer.data[event.previousIndex] };
			event.container.data.splice(event.currentIndex, 0, clone);
			this.serviceComponentsList[event.currentIndex].controlName = this.addingFormControl();
		}
	}

	public addingFormControl(value?: any, index?: number): string {
		const controlId: string = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
		this.newServiceForm.addControl(controlId, this.formBuilder.control(value ? value : '', Validators.required));
		return controlId;
	}

	public updateFormControlValue(value: any, controlName: string): void {
		this.newServiceForm.controls[controlName].setValue(value);
	}

	public removeFormControl(controlName: string): void {
		this.newServiceForm.removeControl(controlName);
		this.serviceComponentsList = this.serviceComponentsList.filter((component: any) => {
			return component.controlName !== controlName;
		});
	}

	public createService(): void {
		const newService: CreateServiceDtoInterface = new ComposeService(this.composeServiceParams).serviceObject;
		this.updateValidityService.triggerFormValidation();
		if (!this.disabledCreateButton) {
			this.clientsService.createService(newService).subscribe(
				() => {
					this.disabledCreateButton = true;
					this.router.navigate(['/services'], { relativeTo: this.route });
					this.snackBarService.showSnackBar(`${newService.names.English} ${SNACK_BAR_MESSAGES.created}`);
				},
				(err: HttpErrorResponse) => {
					this.disabledCreateButton = false;
					this.snackBarService.showSnackBar(SNACK_BAR_MESSAGES.creationFailed);
					// TODO: create errors subscription
				}
			);
		}
		this.updateValidityService.triggerFormValidation();
	}

	public editService(): void {
		const newService: CreateServiceDtoInterface = new ComposeService(this.composeServiceParams).serviceObject;
		this.updateValidityService.triggerFormValidation();

		this.clientsService.editService(newService, this.instanceId).subscribe(
			() => {
				this.router.navigate(['/services'], { relativeTo: this.route });
				this.snackBarService.showSnackBar(`${newService.names.English} ${SNACK_BAR_MESSAGES.updated}`);
			},
			(err: HttpErrorResponse) => {
				this.snackBarService.showSnackBar(SNACK_BAR_MESSAGES.updateFailed);
				// TODO: create errors subscription
			}
		);
	}

	public createTemplate(service: CreateServiceDtoInterface, names: MultiLanguageValueInterface): void {
		const template: TemplateInterface = {
			contents: service.contents,
			names: names,
			pictureUrl: service.pictureUrl
		};
		this.templatesService.createTemplate(template).subscribe(
			() => {
				this.snackBarService.showSnackBar(`Template with name: '${template.names.English}' ${SNACK_BAR_MESSAGES.created}`);
			},
			(err: HttpErrorResponse) => {
				this.snackBarService.showSnackBar(SNACK_BAR_MESSAGES.creationFailed);
				// TODO: create errors subscription
			}
		);
	}

	public editTemplate(): void {
		const updatedTemplate: CreateServiceDtoInterface = new ComposeService(this.composeServiceParams).serviceObject;
		const template: TemplateInterface = {
			contents: updatedTemplate.contents,
			names: updatedTemplate.names,
			pictureUrl: updatedTemplate.pictureUrl
		};
		this.templatesService.editTemplate(template, this.instanceId).subscribe(
			() => {
				this.router.navigate(['/templates'], { relativeTo: this.route });
				this.snackBarService.showSnackBar(`Template with name: '${template.name}' ${SNACK_BAR_MESSAGES.updated}`);
			},
			(err: HttpErrorResponse) => {
				this.snackBarService.showSnackBar(SNACK_BAR_MESSAGES.updateFailed);
				// TODO: create errors subscription
			}
		);
	}

	public openSaveAsTemplateDialog(): void {
		const dialogRef: MatDialogRef<SaveAsTamplateDialogComponent> = this.matDialog.open(SaveAsTamplateDialogComponent, {
			width: '430px'
		});
		dialogRef.componentInstance.dialogSource.subscribe((templateData: { names: MultiLanguageValueInterface }) => {
			this.createTemplate(new ComposeService(this.composeServiceParams).serviceObject, templateData.names);
			dialogRef.close();
		});
	}

	public get composeServiceParams(): any {
		return {
			newServiceForm: this.newServiceForm,
			serviceComponentsList: this.serviceComponentsList,
			deliveryPriceService: this.deliveryPriceService
		};
	}

	public selectTemplate(templateId: string): void {
		if (isNullOrUndefined(templateId)) {
			this.serviceComponentsList = [];
			this.getService();
			return;
		}

		this.templatesService.getTemplateById(templateId).subscribe((service: TemplateInterface) => {
			this.serviceComponentsList = [];
			this.initServiceInstance(service, true);
		});
	}

	public cancel(): void {
		this.typeOfInstance === 'template'
			? this.router.navigate(['/templates'], { relativeTo: this.route })
			: this.router.navigate(['/services'], { relativeTo: this.route });
	}

	public compareWith(a: string, b: string): boolean {
		return Boolean(a) && Boolean(b) && a === b;
	}

	public removeService(): void {
		this.dialogRef = this.dialog.open(DeleteServiceComponent, {
			data: {
				id: this.instanceId
			},
			width: '430px'
		});
		this.dialogRef.componentInstance.dialogSource.subscribe(() => {
			this.dialogRef.close();
			this.router.navigate(['/services']);
		});
	}

	private getService(): void {
		!isNullOrUndefined(this.instanceId)
			? this.clientsService.getServiceById(this.instanceId).subscribe((service: ServiceDtoInterface) => {
					this.initServiceInstance(service);
					this.service = service;
			  })
			: this.initServiceInstance();
	}

	private initServiceInstance(service?: ServiceDtoInterface | TemplateInterface, createFromTemplate?: boolean): void {
		this.templatesService.getAllTemplates().subscribe(
			(allTemplates: TemplateInterface[]) => {
				if (!createFromTemplate) {
					this.allTemplates = allTemplates;
				}
				if (isNullOrUndefined(service)) {
					this.createDefaultForm();
				} else {
					this.createFormWithValues(service);
					this.setComponentInitialValues(service.contents);
				}
				this.cdr.detectChanges();
			},
			() => this.snackBarService.showSnackBar('Unknown error')
		);
	}

	private createDefaultForm(): void {
		this.initialImage = undefined;
		this.newServiceForm = this.formBuilder.group({
			picture: new UntypedFormControl(null),
			hasDelivery: new UntypedFormControl(false),
			hasInvoicing: new UntypedFormControl(false),
			hasSubmit: new UntypedFormControl(false),
			names: this.formBuilder.group({
				English: new UntypedFormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(61)]),
				French: new UntypedFormControl(null, []),
				Dutch: new UntypedFormControl(null, []),
				German: new UntypedFormControl(null, [])
			}),
			currency: new UntypedFormControl(0, Validators.required)
		});
	}

	private createFormWithValues(service: ServiceDtoInterface | TemplateInterface): void {
		this.initialImage = { image: service.pictureUrl, title: 'Service image' };
		this.newServiceForm = this.formBuilder.group({
			picture: new UntypedFormControl(service.pictureUrl),
			hasDelivery: new UntypedFormControl(!isNullOrUndefined(service.hasDelivery) ? service.hasDelivery : false),
			hasInvoicing: new UntypedFormControl(!isNullOrUndefined(service.hasInvoicing) ? service.hasInvoicing : false),
			hasSubmit: new UntypedFormControl(!isNullOrUndefined(service.hasSubmit) ? service.hasSubmit : false),
			names: this.formBuilder.group({
				English: new UntypedFormControl(service.names.English, [
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(61)
				]),
				French: new UntypedFormControl(service.names.French, []),
				Dutch: new UntypedFormControl(service.names.Dutch, []),
				German: new UntypedFormControl(service.names.German, [])
			}),
			currency: new UntypedFormControl(service.currencyType, Validators.required)
		});
	}

	get namesFormGroup(): any {
		return this.newServiceForm.controls.names as UntypedFormGroup;
	}
}
