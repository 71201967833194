import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'cactussoft-badge-cell',
	templateUrl: './badge-cell.component.html',
	styleUrls: ['./badge-cell.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgeCellComponent {
	// TODO: unused component!!!!
	@Input() public popoverData: string[];
	public isOpen: boolean = false;

	constructor() {}

	public changePopoverState(): void {
		this.isOpen = !this.isOpen;
	}
}
