import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@shared/services/auth/auth.service';

@Injectable({
	providedIn: 'root'
})
export class ActiveUserGuard {
	constructor(private authService: AuthService, private router: Router) {}

	public canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		let isActivate: boolean | Promise<boolean> = true;
		this.authService.isLoggedIn().subscribe(
			(result: boolean) => {
				isActivate = result ? this.router.navigate(['/news']) : true;
			},
			() => {
				isActivate = true;
			}
		);
		return isActivate;
	}
}
