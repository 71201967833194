import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuItem } from '@shared/components/menu/menu-component.interface';

/**
 * @title Basic menu
 */
@Component({
	selector: 'cactussoft-menu',
	templateUrl: 'menu.component.html',
	styleUrls: ['./menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent {
	@Input() menuIconName?: string = '';
	@Input() menuLabel: string = '';
	@Input() menuItemsList: MenuItem[] = [];

	public isMenuOpened = false;

	public changeState(): void {
		this.isMenuOpened = !this.isMenuOpened;
	}
}
