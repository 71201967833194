import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService } from '@shared/services/auth/auth.service';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Token } from '@shared/interfaces/token.interface';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	private isRefreshing: boolean = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(private authService: AuthService) {}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (Boolean(this.authService.getJwtToken())) {
			request = this.addToken(request, this.authService.getJwtToken());
		}

		return next.handle(request).pipe(
			catchError((error: HttpResponse<any>) => {
				// eslint-disable-next-line no-magic-numbers
				if (error instanceof HttpErrorResponse && error.status === 401) {
					return this.handle401Error(request, next);
				} else {
					return throwError(error);
				}
			})
		);
	}

	private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.refreshTokenSubject.next(null);

			return this.authService.refreshToken().pipe(
				switchMap((token: Token) => {
					this.isRefreshing = false;
					this.refreshTokenSubject.next(token.access_token);
					return next.handle(this.addToken(request, token.access_token));
				})
			);
		} else {
			return this.refreshTokenSubject.pipe(
				filter((token: Token) => token != null),
				take(1),
				switchMap((access_token: any) => {
					return next.handle(this.addToken(request, access_token));
				})
			);
		}
	}

	private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`
			}
		});
	}
}
