<div class='picker-builder-container' [formGroup]='formGroup'>
	<div style='width: 100%;'>
		<div class='component'>
			<div class='draggable-icon-wrapper' cdkDrag>
				<mat-icon class='component-icon'>drag_indicator</mat-icon>
			</div>
			<div class='component-icon-wrapper'>
				<mat-icon class='component-icon'>{{iconName}}</mat-icon>
			</div>
			<div class='component-input' [formGroup]="titlesFormGroup">
				<mat-form-field appearance='fill'>
					<mat-label> {{placeholder.title}}
					</mat-label>
					<input matInput formControlName='English' required>
					<mat-error
						*ngIf='titlesFormGroup.controls.English.touched && titlesFormGroup.controls.English.invalid'>
						<span *ngIf='titlesFormGroup.controls.English.errors.required'>Required field</span>
						<span *ngIf='titlesFormGroup.controls.English.errors.maxlength'>Max length - 400 char</span>
					</mat-error>
				</mat-form-field>
			</div>
			<div class='option-actions'>
				<mat-icon class='icon' (click)='openMultiLanguageDialog(titlesFormGroup,"input",placeholder.title)'>language</mat-icon>
			</div>
		</div>
		<div class='component-description'>
			<span class='component-description__title'>{{placeholder.title}}</span>
			<span class='component-description__text'>Title or text information</span>
		</div>
		<div class="required-checkbox-container">
			<cactussoft-required-checkbox [formGroup]="formGroup"></cactussoft-required-checkbox>
		</div>
	</div>
	<div class='remove-container'>
		<mat-icon class='icon' (click)="removeComponent()">delete</mat-icon>
	</div>
</div>

