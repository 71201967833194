import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	OnDestroy,
	OnInit,
	Output,
	ViewEncapsulation
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { isNullOrUndefined } from '@shared/tools/is-undefined-null';
import { SnackBarService } from '@shared/services/snack-bar/snack-bar.service';

import { UserInterface } from '@cactussoft/mobile-users/interfaces/user.interface';
import { UsersService } from '@cactussoft/mobile-users/services/users.service';
import { forkJoin, ReplaySubject } from 'rxjs';
import { FileService } from '@shared/services/file/file.service';
import { AddressTypes } from '@shared/enums/address-types.enum';
import { UserAddressInterface } from '@shared/interfaces/user-address.interface';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'cactussoft-detail-view-user',
	templateUrl: './detail-view-user.component.html',
	styleUrls: ['./detail-view-user.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailViewUserComponent implements OnInit, OnDestroy {
	@Output() public dialogSource: EventEmitter<any> = new EventEmitter<any>();

	public user: UserInterface;
	public userState: boolean;
	public addressTypes: typeof AddressTypes = AddressTypes;
	public isChecked: boolean = false;

	private destroy$: ReplaySubject<void> = new ReplaySubject<void>();

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { id: number; pictureUrl: string },
		private cdr: ChangeDetectorRef,
		private usersService: UsersService,
		private fileService: FileService,
		private snackBarService: SnackBarService
	) {}

	public ngOnInit(): void {
		this.usersService
			.getUserById(this.data.id)
			.pipe(takeUntil(this.destroy$))
			.subscribe((_user: UserInterface) => {
				this.user = _user;
				this.userState = !isNullOrUndefined(_user.deleted);
				this.isChecked = !isNullOrUndefined(_user?.invoicePaymentEnabled) ? _user?.invoicePaymentEnabled : false;
				this.cdr.detectChanges();
			});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
	}

	public changeUserState(): void {
		this.usersService
			.changeUserState(this.data.id, !this.userState)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.dialogSource.emit({});
				this.snackBarService.showSnackBar('User state was changed');
			});
	}

	public deleteUser(): void {
		forkJoin([this.usersService.deleteUser(this.data.id), this.fileService.deleteUserAvatar(this.data.id, this.data.pictureUrl)])
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.dialogSource.emit({});
				this.snackBarService.showSnackBar('User was deleted.');
			});
	}

	public changeInvoiceState(matSlideToggleChange: MatSlideToggleChange): void {
		this.usersService
			.changeStateInvoicePayment(this.user.id, matSlideToggleChange.checked)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.dialogSource.emit({});
				this.snackBarService.showSnackBar('User invoice payment state was changed.');
			});
	}

	public getAddressView(addressType: AddressTypes): string {
		const address: UserAddressInterface = this.user.addresses.find(
			(_address: UserAddressInterface) => _address?.addressType === addressType
		);
		return address
			? `
		${address?.firstName}
		${address?.lastName}${address?.companyName ? `, ${address?.companyName}` : ``}<br>
		${address?.street}
		${address?.houseNumber},
		${address?.zip}
		${address?.city},
		${address?.country}<br>
		${address?.deliveryContact}<br>
		${address?.addressComplement ? `${address?.addressComplement}` : ``}`
			: 'No saved address';
	}

	get invoiceView(): string {
		return !!this.user?.invoicing
			? `
		${this.user?.invoicing?.companyName}, ${this.user?.invoicing?.vat}<br>
		${this.user?.invoicing?.street} ${this.user?.invoicing?.number}<br>
		${this.user?.invoicing?.zip} ${this.user?.invoicing?.city}<br>
		${this.user?.invoicing?.country}<br>
		${this.user?.invoicing?.reference}<br>
		${this.user?.invoicing?.costCenter}`
			: 'No saved invoicing';
	}
}
