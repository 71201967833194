<div class='input-with-variants-container' [formGroup]="formGroup">
	<div class="component-container">
		<div class='component'>
			<div class='draggable-icon-wrapper' cdkDrag>
				<mat-icon class='component-icon'>drag_indicator</mat-icon>
			</div>
			<div class='component-icon-wrapper'>
				<mat-icon class='component-icon'>{{iconName}}</mat-icon>
			</div>
			<div class='component-input' [formGroup]="titlesFormGroup">
				<mat-form-field class='form__row-input' appearance='fill'>
					<mat-label> {{placeholder.title}}
					</mat-label>
					<input matInput formControlName='English' required>
					<mat-error
						*ngIf='titlesFormGroup.controls.English.touched && titlesFormGroup.controls.English.invalid'>
						<span *ngIf='titlesFormGroup.controls.English.errors.required'>Required field</span>
						<span *ngIf='titlesFormGroup.controls.English.errors.minlength'>Min length - 3 char</span>
						<span *ngIf='titlesFormGroup.controls.English.errors.maxlength'> Max length - 60 char</span>
					</mat-error>
				</mat-form-field>
			</div>
			<div class='option-actions'>
				<mat-icon class='icon' (click)='openMultiLanguageDialog(titlesFormGroup,"input",placeholder.title)'>language</mat-icon>
			</div>
		</div>
		<div class='remove-container'>
			<mat-icon class='icon' (click)="removeComponent()">delete</mat-icon>
		</div>
	</div>

	<div class="component-option-container" *ngFor="let optionFormGroup of variantsControls; let i = index">
		<div [formGroup]="optionFormGroup" class='component'>
			<div class='draggable-icon-wrapper'>
			</div>
			<div class='component-icon-wrapper empty'>
				<mat-icon class='component-icon'>{{variantIcon}}</mat-icon>
			</div>
			<div class='component-input' [formGroup]="optionFormGroup.controls.names">
				<mat-form-field appearance='fill'>
					<mat-label> {{placeholder.option}} {{i + 1}}
					</mat-label>
					<input matInput formControlName='English' required>
					<mat-error
						*ngIf='optionFormGroup.controls.names.controls.English.touched && optionFormGroup.controls.names.controls.English.invalid'>
						<span *ngIf='optionFormGroup.controls.names.controls.English.errors.required'>Required field</span>
						<span *ngIf='optionFormGroup.controls.names.controls.English.errors.minlength'>Min length - 3 char</span>
						<span *ngIf='optionFormGroup.controls.names.controls.English.errors.maxlength'> Max length - 60 char</span>
					</mat-error>
				</mat-form-field>
			</div>
			<div class='option-actions'>
				<mat-icon class='icon' (click)='openMultiLanguageDialog(optionFormGroup.controls.names,"input",placeholder.option)'>language</mat-icon>
				<mat-icon class='icon' *ngIf="i!== 0" (click)='removeFormControl(i)'>delete</mat-icon>
			</div>
		</div>
	</div>
	<span class="add-variant-label" *ngIf="variantsControls.length < 20" (click)='addFormControl()'>+ Add variant</span>
	<div class="required-checkbox-container" *ngIf='isRequiredCheckbox'>
		<cactussoft-required-checkbox [formGroup]="formGroup"></cactussoft-required-checkbox>
	</div>
</div>
