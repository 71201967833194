import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { queryBuilder } from '@shared/tools/query-builder';
import { FilterValuesInterface } from '@shared/components/table/interfaces/filter-values.interface';
import { Observable } from 'rxjs';
import { ManagersPageDtoInterface } from '@cactussoft/managers/interfaces/managers-page-dto.interface';
import { CreateManagerDtoInterface } from '@cactussoft/managers/interfaces/create-manager-dto.interface';
import { ManagerDtoInterface } from '@cactussoft/managers/interfaces/manager-dto.interface';
import { environment } from '../../../../../apps/savviva-board/src/environments/environment';

const baseUrl: string = `${JSON.parse(localStorage.getItem('env'))?.identity}/admins`;

@Injectable({
	providedIn: 'root'
})
export class ManagersService {
	constructor(private http: HttpClient) {}

	public getManagers(filterValues?: FilterValuesInterface): Observable<ManagersPageDtoInterface> {
		return this.http.get<ManagersPageDtoInterface>(`${baseUrl}${queryBuilder(filterValues)}`);
	}

	public createManager(manager: CreateManagerDtoInterface): Observable<any> {
		return this.http.post(`${baseUrl}`, manager);
	}

	public getManagerById(id: string): Observable<ManagerDtoInterface> {
		return this.http.get<ManagerDtoInterface>(`${baseUrl}/${id}`);
	}

	public editManager(manager: CreateManagerDtoInterface, id: string): Observable<any> {
		return this.http.put(`${baseUrl}/${id}`, manager);
	}

	public changeManagerState(id: number, state: boolean): Observable<any> {
		return this.http.patch<any>(`${baseUrl}/${id}`, { isInactive: state });
	}
}
