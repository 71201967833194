<div class='uploader-container'>
	<div class='uploader-container__image'>
		<img [src]='uploaderData.image' height='160' />
	</div>
	<div class='uploader-container__description'>
		<span class='title'>
			{{ uploaderData.title }}
		</span>
		<span class='sub-title' *ngFor='let string of (customTitle ? customTitle : uploaderTitle)'>
			{{string}}
		</span>
		<div class='uploader-container__actions'>
			<label for='{{uploaderId}}' class='file-upload-button'>
				{{selectionState}}
			</label>
			<mat-icon *ngIf='uploaderData.image && !isDefaultImage' aria-hidden='false' class='delete-button'
					  (click)='deleteImage()'>delete
			</mat-icon>
			<input  id='{{uploaderId}}'
					accept=".jpeg,.jpg,.png"
					type='file'
					#fileInput
					(change)='uploadFile(fileInput.files[0])'
			/>
		</div>
	</div>
	<div></div>
</div>
