<div style='display: flex;'>
	<div class='create-integration-container'>
		<span class='create-integration-container__header-label'>{{getHeaderLabel()}}</span>
		<form [formGroup]='newIntegrationForm' *ngIf='newIntegrationForm'>
			<div class='about-container form'>
				<span class='form__label'>General info</span>
				<div  class="uploader-wrapper">
					<cactussoft-uploader
						[uploaderType]='uploaderType'
						[initialImage]='initialImage'
						(uploadSource)='uploadIntegrationPicture($event)'
					/>
				</div>
				<div class='form__row auto-fit'>
					<div class='form__row-block' [formGroup]='namesFormGroup'>
						<mat-form-field class='form__row-input' appearance='fill'>
							<mat-label>Name</mat-label>
							<input matInput formControlName='English' required>
							<mat-error
								*ngIf='namesFormGroup.controls.English.touched && namesFormGroup.controls.English.invalid'>
								<span *ngIf='namesFormGroup.controls.English.errors.required'>Required field</span>
								<span *ngIf='namesFormGroup.controls.English.errors.minlength'>
									Min length - 3 char
								</span>
								<span *ngIf='namesFormGroup.controls.English.errors.maxlength'>
									Max length - 60 char
								</span>
							</mat-error>
						</mat-form-field>
						<mat-icon
							class='form__language-icon'
							(click)='openMultiLanguageDialog(namesFormGroup,"input","Name")'
						>
							language
						</mat-icon>
					</div>
				</div>
				<div class='form__row auto-fit' [formGroup]='newIntegrationForm'>
					<mat-form-field appearance='fill'>
						<mat-label>URL</mat-label>
						<input matInput formControlName='url' required>
						<mat-error *ngIf='newIntegrationForm.controls.url.touched && newIntegrationForm.controls.url.invalid'>
							<span *ngIf='newIntegrationForm.controls.url.errors.required'>Required field</span>
							<span *ngIf='newIntegrationForm.controls.url.errors.invalidUrl'>URL must start with 'http' or 'https'</span>
						</mat-error>
					</mat-form-field>
				</div>
			</div>
			<div class='form__buttons'>
				<div style='display: flex;'>
					<div class='button-item secondary' (click)='cancel()'>
						Cancel
					</div>
					<div class='button-item primary'
						[ngClass]='{disabled: !newIntegrationForm.valid || disabledCreateButton}'
						(click)='!(!newIntegrationForm.valid || disabledCreateButton) && (instanceId ? editIntegration() : createIntegration())'
					>
						{{instanceId ? 'Save' : 'Create'}}
					</div>
					<button *ngIf='instanceId' (click)="removeIntegration()" mat-button>
						<img src="/assets/icons/delete-icon.svg" />
						<span class="delete-integration-label">Delete</span>
					</button>
				</div>
			</div>
		</form>
	</div>
</div>
