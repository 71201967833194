import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'invoicingState'
})
export class InvoicingStatePipe implements PipeTransform {
	public transform(state: boolean): string {
		return state ? 'On' : 'Off';
	}
}
