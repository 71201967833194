/* eslint-disable no-magic-numbers */
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	OnInit,
	Output,
	ViewEncapsulation
} from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialog as MatDialog,
	MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { isNullOrUndefined } from '@shared/tools/is-undefined-null';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UploaderData, UploaderTypes } from '@shared/components/uploader/uploader-types';
import { SpaceAttachment, SpacePageInterface } from '@cactussoft/buildings/interfaces/building-dto.interface';
import { MultiLanguageDialogComponent } from '@shared/components/multi-language-dialog/multi-language-dialog.component';
import { FormValidationService } from '@shared/services/update-validity/update-validity.service';
import { SnackBarService } from '@shared/services/snack-bar/snack-bar.service';

@Component({
	selector: 'cactussoft-add-space-page-dialog',
	templateUrl: './add-space-page-dialog.component.html',
	styleUrls: ['./add-space-page-dialog.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddSpacePageDialogComponent implements OnInit {
	@Output() public dialogSource: EventEmitter<any> = new EventEmitter<any>();

	public editorEnglishValue: string;
	public spaceForm: UntypedFormGroup;
	public initialImage: UploaderData;
	public pictureInitialImage: UploaderData;
	public uploaderType: string = UploaderTypes.space;
	public pictureIconUploaderType: string = UploaderTypes.pageIcon;
	public uploadedFiles: SpaceAttachment[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: SpacePageInterface,
		private formBuilder: UntypedFormBuilder,
		private matDialog: MatDialog,
		private cdr: ChangeDetectorRef,
		private updateValidationService: FormValidationService,
		private snackBarService: SnackBarService
	) {}

	public ngOnInit(): void {
		isNullOrUndefined(this.data) ? this.createDefaultForm() : this.createFormWithData(this.data);
		this.updateValidationService.checkValidation$.subscribe(
			() => {
				this.updateValidationService.validateAllFormFields(this.spaceForm);
				this.cdr.detectChanges();
			},
			() => this.snackBarService.showSnackBar('Unknown error')
		);
	}

	public cancel(): void {
		this.dialogSource.emit(null);
	}

	public emitPageValue(): void {
		this.updateValidationService.triggerFormValidation();
		if (!(this.namesFormGroup.controls.English.invalid || this.editorFormGroup.controls.English.invalid)) {
			this.dialogSource.emit(this.spaceForm.value);
		}
	}

	public getText(content: { richText: string; text: string }): void {
		this.editorFormGroup.controls.English.setValue(content.richText);
	}

	public uploadPicture(pictureUrl: string): void {
		this.spaceForm.controls['pictureUrl'].setValue(pictureUrl);
	}

	public uploadBuildingPicture(pictureUrl: string): void {
		this.spaceForm.controls['pageBannerUrl'].setValue(pictureUrl);
	}

	public setAttachedFiles(files: SpaceAttachment[]): void {
		this.spaceForm.controls['attachments'].setValue(files);
	}

	public openMultiLanguageDialog(inputFormGroup: any, type: string, placeholder?: string): void {
		const dialogRef: MatDialogRef<MultiLanguageDialogComponent> = this.matDialog.open(MultiLanguageDialogComponent, {
			data: { value: inputFormGroup.value, type: type, placeholder: placeholder },
			width: '800px',
			height: 'auto'
		});
		dialogRef.componentInstance.dialogSource.subscribe(
			(data: any) => {
				inputFormGroup.setValue(data);
				inputFormGroup.markAllAsTouched();
				this.editorEnglishValue = this.editorFormGroup.controls.English.value;
				this.cdr.detectChanges();
				dialogRef.close();
			},
			() => this.snackBarService.showSnackBar('Unknown error')
		);
	}

	private createDefaultForm(): void {
		this.initialImage = undefined;
		this.pictureInitialImage = undefined;
		this.spaceForm = this.formBuilder.group({
			id: new UntypedFormControl(null),
			pictureUrl: new UntypedFormControl(null),
			pageBannerUrl: new UntypedFormControl(null),
			names: this.formBuilder.group({
				English: new UntypedFormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(61)]),
				French: new UntypedFormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(61)]),
				Dutch: new UntypedFormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(61)]),
				German: new UntypedFormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(61)])
			}),
			descriptionHtmls: this.formBuilder.group({
				English: new UntypedFormControl(null, [Validators.required]),
				French: new UntypedFormControl(null),
				Dutch: new UntypedFormControl(null),
				German: new UntypedFormControl(null)
			}),
			attachments: new UntypedFormControl(null)
		});
	}

	private createFormWithData(page: any): void {
		this.initialImage = {
			title: 'Page image',
			image: page.pictureUrl
		};
		this.pictureInitialImage = {
			title: 'Page icon',
			image: page.pageBannerUrl
		};
		this.spaceForm = this.formBuilder.group({
			id: new UntypedFormControl(page.id),
			pictureUrl: new UntypedFormControl(page.pictureUrl),
			pageBannerUrl: new UntypedFormControl(page.pageBannerUrl),
			names: this.formBuilder.group({
				English: new UntypedFormControl(page.names.English, [
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(61)
				]),
				French: new UntypedFormControl(page.names.French, [Validators.required, Validators.minLength(3), Validators.maxLength(61)]),
				Dutch: new UntypedFormControl(page.names.Dutch, [Validators.required, Validators.minLength(3), Validators.maxLength(61)]),
				German: new UntypedFormControl(page.names.German, [Validators.required, Validators.minLength(3), Validators.maxLength(61)])
			}),
			descriptionHtmls: this.formBuilder.group({
				English: new UntypedFormControl(page.descriptionHtmls.English, [Validators.required]),
				French: new UntypedFormControl(page.descriptionHtmls.French),
				Dutch: new UntypedFormControl(page.descriptionHtmls.Dutch),
				German: new UntypedFormControl(page.descriptionHtmls.German)
			}),
			attachments: new UntypedFormControl(page.attachments)
		});
		page.attachments?.forEach((attachment: SpaceAttachment) => {
			this.uploadedFiles.push({
				name: attachment.name,
				mimeType: attachment.mimeType,
				url: attachment.url,
				previewUrl: attachment.mimeType.includes('image/') ? attachment.url : 'assets/file_default.svg',
				bytesCount: attachment.bytesCount
			});
		});
	}

	get namesFormGroup(): any {
		return this.spaceForm.controls.names as UntypedFormGroup;
	}

	get editorFormGroup(): any {
		return this.spaceForm.controls.descriptionHtmls as UntypedFormGroup;
	}
}
