<div class='add-space-page-dialog-container'>
	<div class='add-space-page-dialog-container__header'>
		<span>Add page</span>
		<mat-icon mat-dialog-close class='cancel-button'>clear</mat-icon>
	</div>
	<form [formGroup]='spaceForm' *ngIf='spaceForm'>
		<div class='about-container space-form'>
			<cactussoft-uploader [uploaderType]='pictureIconUploaderType'
								 [initialImage]='pictureInitialImage'
								 (uploadSource)='uploadBuildingPicture($event)'>
			</cactussoft-uploader>
			<cactussoft-uploader [uploaderType]='uploaderType'
								 [initialImage]='initialImage'
								 (uploadSource)='uploadPicture($event)'></cactussoft-uploader>
			<div class='space-form__row auto-fit'>
				<div class='space-form__row-block' [formGroup]='namesFormGroup'>
					<mat-form-field class='space-form__row-input' appearance='fill'>
						<mat-label> Name
						</mat-label>
						<input matInput formControlName='English' required>
						<mat-error
							*ngIf='namesFormGroup.controls.English.touched && namesFormGroup.controls.English.invalid'>
							<span *ngIf='namesFormGroup.controls.English.errors.required'>Required field</span>
						</mat-error>
					</mat-form-field>
					<mat-icon class='space-form__language-icon'
							  (click)='openMultiLanguageDialog(namesFormGroup,"input","Name")'>language
					</mat-icon>
				</div>
			</div>
			<div class='space-form__coloumn'>
				<div class='space-form__row-block'>
					<cactussoft-text-editor style='width: 100%;'
											[initValue]='editorFormGroup.controls.English.value'
											[valueFromMultiLang]='editorEnglishValue'
											scrollingContainer='.add-space-page-dialog-container'
											(changedSource)='getText($event)'></cactussoft-text-editor>
					<mat-icon class='space-form__language-icon'
							  (click)='openMultiLanguageDialog(editorFormGroup,"editor")'>language
					</mat-icon>
				</div>
				<div class='editor-validation-message'
					 *ngIf='editorFormGroup.controls.English.touched && editorFormGroup.controls.English.invalid'>
					<span *ngIf='editorFormGroup.controls.English.errors.required'>Required field</span>
				</div>
				<span class='required-label'>* Required fields</span>
			</div>

			<cactussoft-multi-attach-files
				[uploadedFiles]='uploadedFiles'
				(uploadedFilesSource)='setAttachedFiles($event)'></cactussoft-multi-attach-files>
		</div>

		<div class='space-form__buttons'>
			<div class='button-item secondary' (click)='cancel()'>
				Cancel
			</div>
			<div class='button-item primary' (click)='emitPageValue()'>
				Add
			</div>
		</div>
	</form>

</div>
