import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { FilterValuesInterface } from '@shared/components/table/interfaces/filter-values.interface';
import { queryBuilder } from '@shared/tools/query-builder';

import { TemplatesPageInterface } from '../interfaces/templates-page.interface';
import { TemplateInterface } from '../interfaces/template.interface';
import { environment } from '../../../../../apps/savviva-board/src/environments/environment';

const baseUrl: string = `${JSON.parse(localStorage.getItem('env'))?.service}/administration`;

@Injectable({
	providedIn: 'root'
})
export class TemplatesService {
	constructor(private http: HttpClient) {}

	public createTemplate(template: TemplateInterface): Observable<any> {
		return this.http.post<any>(`${baseUrl}/templates`, template);
	}

	public editTemplate(template: TemplateInterface, id: string): Observable<any> {
		return this.http.put<any>(`${baseUrl}/templates/${id}`, template);
	}

	public getTemplates(filterValues?: FilterValuesInterface): Observable<TemplatesPageInterface> {
		return this.http.get<TemplatesPageInterface>(`${baseUrl}/templates${queryBuilder(filterValues)}`);
	}

	public getAllTemplates(): Observable<TemplateInterface[]> {
		return this.http.get<TemplateInterface[]>(`${baseUrl}/templates/all`);
	}

	public getTemplateById(id: string): Observable<TemplateInterface> {
		return this.http.get<TemplateInterface>(`${baseUrl}/templates/${id}`);
	}

	public deleteTemplate(id: string): Observable<any> {
		return this.http.delete<any>(`${baseUrl}/templates/${id}`);
	}
}
