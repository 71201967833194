<div class='gallery-builder-container' [formGroup]='formGroup'>
	<div class='component-container'>
		<div class='component'>
			<div class='draggable-icon-wrapper' cdkDrag>
				<mat-icon class='component-icon'>drag_indicator</mat-icon>
			</div>
			<div class='component-icon-wrapper'>
				<mat-icon class='component-icon'>{{iconName}}</mat-icon>
			</div>
			<div class='component-description'>
				<h3>Gallery</h3>
				<span>Upload a JPG, JPEG or PNG file.<br>
						The maximum file size is 5 MB.</span>
			</div>
		</div>
		<div class='remove-container'>
			<mat-icon class='icon' (click)='removeComponent()'>delete</mat-icon>
		</div>
	</div>
	<div class='component-images-container'>
<!--		 TODO: potential bug with drag images between each other-->
<!--		<div class='image-wrapper' *ngFor='let optionFormGroup of variantsControls;let controlIndex = index' cdkDrag>-->
		<div class='image-wrapper' *ngFor='let optionFormGroup of variantsControls;let controlIndex = index'>
			<div [formGroup]="optionFormGroup" class='image-space' (click)='removeFormControl(controlIndex)'>
				<img src='{{optionFormGroup.value.pictureUrl}}'>
			</div>
		</div>
		<div *ngIf='variantsControls.length !== 5'
			 class='image-wrapper-default'>
			<div class='image-space'>
				<div class="gallery-uploader">
					<label for="{{uploaderId}}" class="gallery-uploader-label">
						<mat-icon>add_photo_alternate</mat-icon>
						Add image
					</label>
					<input id="{{uploaderId}}"
						   accept=".jpeg,.jpg,.png"
						   type="file"
						   #fileInput
						   (change)="uploadFile(fileInput.files[0])"
					/>
				</div>
			</div>
		</div>
	</div>
	<div class='picture-validation-message' *ngIf='variantsControls.length === 0 && formGroup.controls.variants.touched'
		 [ngStyle]="{'border-bottom':variantsControls.length === 0 && formGroup.controls.variants.touched ? '2px solid #fc8276' : 'inherit'}">
		<span>Picture required</span>
	</div>
</div>
