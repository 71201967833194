import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	OnInit,
	Output,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { ColumnDataInterface } from '@shared/components/table/interfaces/column-data.interface';
import { PaginatorInterface } from '@shared/components/table/interfaces/paginator.interface';
import { isNullOrUndefined } from '@shared/tools/is-undefined-null';

import {
	AssignedClientDto,
	AttachedServiceDtoInterface,
	AttachedServicesDtoInterface
} from '@cactussoft/services/interfaces/attached-service-dto.interface';
import { ServiceDtoInterface } from '@cactussoft/services/interfaces/service-dto.interface';
import { ClientServicesService } from '@cactussoft/services/services/client-services.service';
import { SnackBarService } from '@shared/services/snack-bar/snack-bar.service';

@Component({
	selector: 'cactussoft-assign-dialog',
	templateUrl: './basic-assign-dialog.component.html',
	styleUrls: ['./basic-assign-dialog.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasicAssignDialogComponent implements OnInit {
	@ViewChild('searchInput', { static: false }) public searchInput: ElementRef;
	@Output() public dialogSource: EventEmitter<any> = new EventEmitter<any>();

	public updatedServices: AttachedServicesDtoInterface;
	public searchText: string = '';
	public dialogSearch: string;
	public selectBuilding: string;

	public toggleSearch: boolean = false;
	public isSelectionUpdate: number;
	public selection: any = [];
	public tableData: any = [];
	public listServices: ServiceDtoInterface[] = [];
	public cols: ColumnDataInterface[] = [
		{
			key: 'select',
			display: '',
			flexPriority: 1,
			config: { isSelectable: true }
		},
		{
			key: 'serviceImage',
			display: '',
			flexPriority: 1,
			config: { isLogo: true, fields: ['pictureUrl'] }
		},
		{ key: 'name', display: '', flexPriority: 10 },
		{
			key: 'isInactive',
			display: 'Status',
			flexPriority: 10,
			config: { isStatus: true, values: { false: 'Active', true: 'Disabled' } }
		}
	];

	public constructor(
		@Inject(MAT_DIALOG_DATA) public data: { id: string; name: string; buildings: any; servicesCount: number },
		private cdr: ChangeDetectorRef,
		private clientServices: ClientServicesService,
		private snackBarService: SnackBarService
	) {}

	public ngOnInit(): void {
		this.clientServices.getAllServices().subscribe(
			(result: ServiceDtoInterface[]) => {
				this.listServices = result;
				this.buildTableData(result);
			},
			() => this.snackBarService.showSnackBar('Unknown error')
		);
	}

	public triggerSearch(value?: boolean): void {
		if (value) {
			this.buildTableData(this.listServices);
			this.searchText = '';
		} else {
			const filteredServices: ServiceDtoInterface[] = this.listServices.filter((item: ServiceDtoInterface) => {
				return item.name.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase());
			});
			this.buildTableData(filteredServices);
		}
	}

	public openSearch(): void {
		this.toggleSearch = true;
		this.searchInput.nativeElement.focus();
	}

	// public onBlur(): void {
	// 	// this.searchText = '';
	// 	// this.toggleSearch = false;
	// 	// this.buildTableData(this.listServices);
	// 	// this.triggerSearch();
	// }

	public closeDialog(): void {
		this.dialogSource.emit({ action: 'actionDialog' });
	}

	public sendData(): void {
		this.clientServices.attachServices(this.updatedServices).subscribe(
			() => {
				this.dialogSource.emit({ action: 'actionDialog' });
			},
			() => this.snackBarService.showSnackBar('Unknown error')
		);
	}

	public getSelection(selection: any): void {
		this.selection = selection;
		this.updatedServices = this.buildAssignedServices();
	}

	public selectionBuildingChanged(): void {
		this.selection = [];
		this.updatedServices = null;
		// TODO: create trigger to update selection!!!
		this.isSelectionUpdate = Math.random();
		this.searchText = '';
		this.toggleSearch = false;
		this.buildTableData(this.listServices);
	}

	private buildTableData(items: ServiceDtoInterface[]): void {
		const tempServices: any = items.map((service: ServiceDtoInterface) => {
			const serviceItem: any = {
				id: service.id,
				name: service.name,
				serviceImage: {
					pictureUrl: Boolean(service.pictureUrl) ? service.pictureUrl : 'assets/service_default.png'
				},
				clients: service.clients,
				clientCount: service.clientCount,
				createdByUser: service.createdByUser,
				isInactive: service.isInactive,
				creationDate: service.created,
				isSelectedDefault: service.clients.some((client: AssignedClientDto) => {
					return (
						client.id === this.data.id &&
						client.buildingIds.some((buildingId: string) => {
							return buildingId === this.selectBuilding;
						})
					);
				})
			};

			return serviceItem;
		});
		this.tableData = [...tempServices];
		this.cdr.detectChanges();
	}

	private buildAssignedServices(): any {
		let assignedServices: AttachedServicesDtoInterface = {
			services: []
		};
		let newService: AttachedServiceDtoInterface;
		this.listServices.forEach((selectItem: ServiceDtoInterface) => {
			this.selection.selection.forEach((serviceId: any) => {
				if (serviceId === selectItem.id) {
					newService = {
						id: selectItem.id,
						clients: []
					};
					newService.clients = this.isClientsExist([...selectItem.clients], this.data.id);
					newService.clients = newService.clients
						.map((client: AssignedClientDto) => {
							client.buildingIds =
								client.id === this.data.id
									? this.isBuildingAdding(client.buildingIds, this.selectBuilding)
									: client.buildingIds;
							return client;
						})
						.filter((client: AssignedClientDto) => client.buildingIds.length !== 0);
					assignedServices.services.push(newService);
				}
			});
		});
		assignedServices = this.checkUnassignedServices([...this.tableData], assignedServices, this.selectBuilding);
		return assignedServices;
	}

	private isClientsExist(clients: AssignedClientDto[], newClientId: string): AssignedClientDto[] {
		if (clients.length === 0 || !this.isClientAssigned(clients, newClientId)) {
			clients.push({
				id: newClientId,
				suggestionType: 0,
				buildingIds: []
			});
		}
		return clients;
	}

	private isClientAssigned(clients: AssignedClientDto[], id: string): boolean {
		return clients.some((client: AssignedClientDto) => {
			return client.id === id;
		});
	}

	private isServiceAssigned(services: AttachedServiceDtoInterface[], id: string): boolean {
		return services.some((service: AttachedServiceDtoInterface) => {
			return service.id === id;
		});
	}

	private checkUnassignedServices(
		tableRows: any,
		assignedServices: AttachedServicesDtoInterface,
		selectBuilding: string
	): AttachedServicesDtoInterface {
		const unassignedServices: any = tableRows.filter((row: any) => {
			return (
				row.isSelectedDefault &&
				!assignedServices.services.some((service: AttachedServiceDtoInterface) => {
					return service.id === row.id;
				})
			);
		});

		unassignedServices.forEach((row: any) => {
			row.clients = row.clients.map((client: AssignedClientDto) => {
				if (client.id === this.data.id) {
					client.buildingIds = client.buildingIds.filter((buildingId: string) => {
						return !(buildingId === selectBuilding);
					});
				}
				return client;
			});
			row.clients = row.clients.filter((client: AssignedClientDto) => {
				return client.buildingIds.length !== 0;
			});
			if (!this.isServiceAssigned(assignedServices.services, row.id)) {
				assignedServices.services.push({ id: row.id, clients: row.clients });
			}
		});

		return assignedServices;
	}

	private isBuildingAdding(buildingIds: string[], newBuildingId: string): string[] {
		buildingIds.length === 0 && !isNullOrUndefined(newBuildingId)
			? buildingIds.push(newBuildingId)
			: buildingIds.forEach((id: string) => {
					if (id !== newBuildingId && !isNullOrUndefined(newBuildingId)) {
						buildingIds.push(newBuildingId);
					}
			  });
		return [...new Set(buildingIds)];
	}
}
