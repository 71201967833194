import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'cactussoft-empty-content',
	templateUrl: './empty-content.component.html',
	styleUrls: ['./empty-content.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class EmptyContentComponent {
	@Input() public link: string;

	public messages: any = {
		withLink: {
			title: 'Start creating the content',
			subTitle: `Click <span class='link' [routerLink]='link'>Create New</span> and and set up your first client, building or service`
		},
		withoutLink: {
			title: 'No content',
			subTitle: 'We will display the data after it is created'
		}
	};

	constructor() {}
}
