/* eslint-disable no-magic-numbers */
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileService } from '@shared/services/file/file.service';
import { SpaceAttachment } from '@cactussoft/buildings/interfaces/building-dto.interface';
import { forkJoin, Observable } from 'rxjs';
import { isNullOrUndefined } from '@shared/tools/is-undefined-null';
import { SnackBarService } from '@shared/services/snack-bar/snack-bar.service';

@Component({
	selector: 'cactussoft-multi-attach-files',
	templateUrl: './multi-attach-files.component.html',
	styleUrls: ['./multi-attach-files.component.scss']
})
export class MultiAttachFilesComponent {
	@Input() public uploadedFiles: SpaceAttachment[] = [];
	@Output() public uploadedFilesSource: EventEmitter<any> = new EventEmitter<any>();

	public limitUploadFiles: number = 3;

	constructor(private fileService: FileService, private cdr: ChangeDetectorRef, private snackBarService: SnackBarService) {}

	public onFileChange(files: FileList): void {
		this.uploadedFiles = [];
		let filesArray: File[] = [];
		for (let i: number = 0; i < files.length; i++) {
			filesArray.push(files[i]);
		}
		filesArray = filesArray.slice(0, this.limitUploadFiles);
		const onloadAllFilesPromise: Array<Promise<any>> = filesArray.map((file: File) => {
			return this.uploadFile(file);
		});

		const uploadFilesToServer: Array<Observable<{ fileExtension: string; fileUrl: string }>> = [];
		Promise.all(onloadAllFilesPromise)
			.then((uploadedFiles: Array<{ fileInfo: File; readerResult: string }>) => {
				uploadedFiles.forEach((loadedFile: { fileInfo: File; previewUrl: string; readerResult: string }) => {
					const fd: FormData = new FormData();
					this.uploadedFiles.push({
						name: loadedFile.fileInfo.name,
						mimeType: !isNullOrUndefined(loadedFile.fileInfo.type) ? loadedFile.fileInfo.type : 'application/octet-stream',
						url: loadedFile.readerResult,
						previewUrl: loadedFile.previewUrl,
						bytesCount: loadedFile.fileInfo.size
					});
					fd.append('file', loadedFile.fileInfo, loadedFile.fileInfo.name);
					uploadFilesToServer.push(this.uploadFileToServer(fd));
				});
				forkJoin(uploadFilesToServer).subscribe(
					(result: Array<{ fileExtension: string; fileUrl: string }>) => {
						result.forEach((file: { fileExtension: string; fileUrl: string }, index: number) => {
							this.uploadedFiles[index].url = file.fileUrl;
						});
						this.uploadedFilesSource.emit(this.uploadedFiles);
					},
					() => this.snackBarService.showSnackBar('Unknown error')
				);
				this.cdr.detectChanges();
			})
			.catch(() => this.snackBarService.showSnackBar('Unknown error'));
	}

	public uploadFile(file: File): Promise<any> {
		// eslint-disable-next-line
		return new Promise((resolve, reject) => {
			if (file.size > Math.pow(2, 20) * 5) {
				this.snackBarService.showSnackBar(
					'The image size is too big. Please reduce the image height, width, resolution, scaling or output type.'
				);
				reject();
			}

			if (file.type.includes('image/')) {
				const reader: FileReader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					resolve({ fileInfo: file, previewUrl: reader.result, readerResult: reader.result });
				};
				reader.onerror = (error: ProgressEvent<FileReader>) => {
					reject(error);
				};
			} else {
				resolve({ fileInfo: file, previewUrl: 'assets/file_default.svg' });
			}
		});
	}

	public removeFile(index: number): void {
		this.uploadedFiles.splice(index, 1);
		this.uploadedFilesSource.emit(this.uploadedFiles);
	}

	private uploadFileToServer(fd: FormData): Observable<{ fileExtension: string; fileUrl: string }> {
		return this.fileService.uploadFile(fd);
	}
}
