/* eslint-disable no-magic-numbers */
import { MultiLanguageValueInterface } from '@shared/interfaces/multi-language.interface';
import { DeliveryPriceService } from '@shared/components/delivery-price/delivery-price.service';

export class ComposeService {
	private object: any = {};
	constructor(
		private data: {
			newServiceForm: any;
			serviceComponentsList: any;
			deliveryPriceService: DeliveryPriceService;
		}
	) {
		this.initServiceObject();
		this.checkDeliveryPriceValuesIsNeeded();
		this.setDeliveryPriceValues();
	}

	public get serviceObject(): any {
		return this.object;
	}

	private getFormControlValues(controlName: string): any {
		return this.data.newServiceForm.controls[controlName].value;
	}

	private checkDeliveryPriceValuesIsNeeded(): void {
		this.data.deliveryPriceService.isDeliveryPriceValuesNeed.next(this.data.newServiceForm.controls['hasDelivery'].value);
	}

	private setDeliveryPriceValues(): void {
		this.object.deliveryCosts = this.data.deliveryPriceService.isDeliveryValid
			? this.data.deliveryPriceService.deliveryPriceValues
			: null;
	}

	private initServiceObject(): void {
		this.object.names = this.data.newServiceForm.controls['names'].value;
		this.object.serviceType = 1;
		this.object.currencyType = this.data.newServiceForm.controls['currency'].value;
		this.object.pictureUrl = this.data.newServiceForm.controls['picture'].value;
		this.object.contents = [];
		this.object.hasDelivery = this.data.newServiceForm.controls['hasDelivery'].value;
		this.object.hasInvoicing = this.data.newServiceForm.controls['hasInvoicing'].value;
		this.object.hasSubmit = this.data.newServiceForm.controls['hasSubmit'].value;
		this.data.serviceComponentsList.forEach((item: any, index: number) => {
			switch (item.type) {
				case 1:
				case 2: {
					this.object.contents.push({
						id: this.getFormControlValues(item.controlName).id,
						type: item.type,
						order: index,
						titles: this.getFormControlValues(item.controlName).titles,
						variants: this.getFormControlValues(item.controlName).variants.map(
							(variant: { names: MultiLanguageValueInterface; id: string }, optionIndex: number) => {
								return {
									...variant,
									order: optionIndex
								};
							}
						),
						isMandatory: this.getFormControlValues(item.controlName).isMandatory
					});
					break;
				}
				case 15: {
					this.object.contents.push({
						id: this.getFormControlValues(item.controlName).id,
						type: item.type,
						order: index,
						inputType: this.getFormControlValues(item.controlName).inputType,
						titles: this.getFormControlValues(item.controlName).titles,
						infos: this.getFormControlValues(item.controlName).infos,
						isMandatory: this.getFormControlValues(item.controlName).isMandatory
					});
					break;
				}
				case 6:
				case 7:
				case 8:
				case 9: {
					this.object.contents.push({
						id: this.getFormControlValues(item.controlName).id,
						type: item.type,
						order: index,
						inputType: this.getFormControlValues(item.controlName).inputType,
						previews: this.getFormControlValues(item.controlName).previews,
						color: this.getFormControlValues(item.controlName).color,
						titles: this.getFormControlValues(item.controlName).titles,
						infos: this.getFormControlValues(item.controlName).infos,
						isMandatory: this.getFormControlValues(item.controlName).isMandatory
					});
					break;
				}
				case 13: {
					this.object.contents.push({
						id: this.getFormControlValues(item.controlName).id,
						type: item.type,
						order: index,
						inputType: this.getFormControlValues(item.controlName).inputType,
						previews: this.getFormControlValues(item.controlName).previews,
						color: this.getFormControlValues(item.controlName).color,
						titles: this.getFormControlValues(item.controlName).titles,
						infos: this.getFormControlValues(item.controlName).infos,
						isMandatory: this.getFormControlValues(item.controlName).isMandatory,
						price: this.getFormControlValues(item.controlName).price
					});
					break;
				}
				case 4:
				case 11: {
					this.object.contents.push({
						id: this.getFormControlValues(item.controlName).id,
						titles: this.getFormControlValues(item.controlName).titles,
						type: item.type,
						order: index,
						isMandatory: this.getFormControlValues(item.controlName).isMandatory
					});
					break;
				}
				case 10: {
					this.object.contents.push({
						id: this.getFormControlValues(item.controlName).id,
						type: item.type,
						order: index,
						titles: this.getFormControlValues(item.controlName).titles,
						variants: this.getFormControlValues(item.controlName).variants.map(
							(
								variant: {
									pictureUrl: string;
									value: string;
									names: MultiLanguageValueInterface;
									infos: MultiLanguageValueInterface;
									id: string;
								},
								optionIndex: number
							) => {
								return {
									...variant,
									order: optionIndex
								};
							}
						),
						isMandatory: this.getFormControlValues(item.controlName).isMandatory
					});
					break;
				}
				case 5: {
					this.object.contents.push({
						id: this.getFormControlValues(item.controlName).id,
						type: item.type,
						order: index,
						titles: this.getFormControlValues(item.controlName).titles,
						variants: this.getFormControlValues(item.controlName).variants.map(
							(variant: { names: MultiLanguageValueInterface; id: string }, optionIndex: number) => {
								return {
									...variant,
									order: optionIndex
								};
							}
						),
						isMandatory: this.getFormControlValues(item.controlName).isMandatory
					});
					break;
				}
				case 14: {
					this.object.contents.push({
						id: this.getFormControlValues(item.controlName).id,
						type: item.type,
						order: index,
						titles: this.getFormControlValues(item.controlName).titles,
						variants: this.getFormControlValues(item.controlName).variants.map(
							(
								variant: { names: MultiLanguageValueInterface; values: MultiLanguageValueInterface; id: string },
								optionIndex: number
							) => {
								return {
									...variant,
									order: optionIndex
								};
							}
						),
						isMandatory: this.getFormControlValues(item.controlName).isMandatory
					});
					break;
				}
				case 3: {
					this.object.contents.push({
						id: this.getFormControlValues(item.controlName).id,
						type: item.type,
						order: index,
						titles: this.getFormControlValues(item.controlName).titles,
						variants: this.getFormControlValues(item.controlName).variants.map(
							(variant: { names: MultiLanguageValueInterface; value: string; id: string }, optionIndex: number) => {
								return {
									...variant,
									order: optionIndex
								};
							}
						),
						isMandatory: this.getFormControlValues(item.controlName).isMandatory
					});
					break;
				}
				case 12: {
					this.object.contents.push({
						id: this.getFormControlValues(item.controlName).id,
						type: item.type,
						order: index,
						variants: this.getFormControlValues(item.controlName).variants.map(
							(variant: { pictureUrl: string; id: string }, optionIndex: number) => {
								return {
									...variant,
									order: optionIndex
								};
							}
						)
					});
					break;
				}
			}
		});
	}
}
