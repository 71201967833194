<div class='auth-container' [ngClass]="{client:!isAdmin}">
	<div class='auth-form' [formGroup]='formGroup'>
		<div class='auth-form__logo'>
			<img [src]=' isAdmin ? "assets/login-logo.svg" : "assets/login-logo_black.svg"' [width]='160'>
		</div>
		<span class='auth-form__description'>Enter you new password for your account</span>
		<div class='auth-form__input'>
			<mat-form-field appearance='fill'>
				<mat-label> Password
				</mat-label>
				<input matInput formControlName='password' [type]="isHiddenPassword ? 'password' : 'text'">
				<mat-icon matSuffix (click)="isHiddenPassword = !isHiddenPassword">{{isHiddenPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
			</mat-form-field>
		</div>
		<div class='auth-form__input'>
			<mat-form-field appearance='fill'>
				<mat-label> Confirm password
				</mat-label>
				<input matInput formControlName='confirmPassword' [type]="isHiddenPassword ? 'password' : 'text'">
				<mat-icon matSuffix (click)="isHiddenPassword = !isHiddenPassword">{{isHiddenPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
				<mat-error *ngIf='formGroup.controls.confirmPassword.touched && formGroup.controls.confirmPassword.invalid'>
					<span *ngIf='formGroup.controls.confirmPassword.errors.passwordMismatch'>Confirm password doesn't match password</span>
				</mat-error>
			</mat-form-field>
		</div>
		<div class='auth-form__button' (click)='resetPassword()' [ngClass]='{error: formGroup.invalid}'>
			Reset password
		</div>
	</div>
</div>
