import { DraggableListInterface, SubcategoriesInterface } from './draggable-components.interface';

export const DRAGGABLE_COMPONENTS: DraggableListInterface = {
	textComponents: [
		{
			id: 'description',
			icon: 'insert_drive_file',
			name: 'Description',
			description: 'Title or text information',
			placeholder: { title: 'Description' },
			type: 6
		},
		{
			id: 'alert',
			icon: 'error',
			name: 'Alert',
			description: 'Warning, Attention, Info',
			placeholder: { title: 'Alert' },
			type: 7
		},
		{
			id: 'hidden_text',
			icon: 'format_line_spacing',
			name: 'Info hidden text',
			description: 'Hidden by default, shown after icon click',
			placeholder: { title: 'Title hidden text', option: 'Hidden text' },
			type: 8
		},
		{
			id: 'input',
			icon: 'speaker_notes',
			name: 'Input',
			description: 'Allow users to enter text',
			placeholder: { title: 'Example: Comments' },
			type: 9
		},
		{
			id: 'budget_input',
			icon: 'budget_icon.svg',
			name: 'Input (price)',
			description: 'Allow users to enter price or budget',
			placeholder: { title: 'Title' },
			type: 15
		}
	],
	listComponents: [
		{
			id: 'one_selection',
			icon: 'radio_button_checked',
			name: 'Dropdown list with one select',
			description: 'Radio options to select one item',
			placeholder: { title: 'Title', option: 'Variant' },
			type: 1
		},
		{
			id: 'multi_selection',
			icon: 'check_box',
			name: 'Dropdown list with multi select',
			description: 'Checkboxes to select one or more items',
			placeholder: { title: 'Title', option: 'Variant' },
			type: 2
		},
		{
			id: 'product_list',
			icon: 'format_list_bulleted',
			name: 'Product list',
			description: 'Product name, price and counter',
			placeholder: { title: 'Product name', option: 'Price' },
			type: 3
		},
		{
			id: 'labels_picker',
			icon: 'label',
			name: 'Labels picker',
			description: 'Chose one or more items labels',
			placeholder: { title: 'Title', option: 'Label' },
			type: 5
		},
		{
			id: 'picture_product_list',
			icon: 'receipt_long',
			name: 'Picture product list',
			description: 'Product name, info, picture and counter',
			placeholder: { title: 'Title', option: 'Label' },
			type: 10
		},
		{
			id: 'two_column_product_list',
			icon: 'view_column',
			name: '2 column product list',
			description: 'Product name and value',
			placeholder: { title: 'Product name', option: 'Value' },
			type: 14
		}
	],
	imageComponents: [
		{
			id: 'gallery',
			icon: 'collections',
			name: 'Gallery',
			description: 'One- and multi-select photo upload',
			type: 12
		}
	],
	timeAndDateComponents: [
		{
			id: 'date_picker',
			icon: 'event',
			name: 'Date picker',
			description: 'Select a single date from calendar',
			placeholder: { title: 'Date picker' },
			type: 4
		},
		{
			id: 'time_picker',
			icon: 'schedule',
			name: 'Time picker',
			description: 'Select time',
			placeholder: { title: 'Time picker' },
			type: 11
		}
	],
	otherComponents: [
		{
			id: 'toggle',
			icon: 'toggle_on',
			name: 'Toggle',
			description: `Toggle \'On\' provides an optional input field`,
			placeholder: { title: 'Title', option: 'Example: Enter message your card' },
			type: 13
		}
	]
};

export const SUBCATEGORIES_NAMES: SubcategoriesInterface = {
	textComponents: 'Text',
	listComponents: 'List',
	imageComponents: 'Image',
	timeAndDateComponents: 'Time and Date',
	otherComponents: 'Others'
};
