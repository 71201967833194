/* eslint-disable no-magic-numbers */
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewEncapsulation
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import { DraggableComponentPlaceholderInterface } from '@cactussoft/services/services-board/create-service/draggable-components.interface';
import { isNullOrUndefined } from '@shared/tools/is-undefined-null';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MultiLanguageDialogComponent } from '@shared/components/multi-language-dialog/multi-language-dialog.component';
import { FormValidationService } from '@shared/services/update-validity/update-validity.service';
import { SnackBarService } from '@shared/services/snack-bar/snack-bar.service';

@Component({
	selector: 'cactussoft-input-with-variants',
	templateUrl: './input-with-variants.component.html',
	styleUrls: ['./input-with-variants.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputWithVariantsComponent implements OnInit {
	@Input() public iconName: string;
	@Input() public componentName: string;
	@Input() public placeholder: DraggableComponentPlaceholderInterface;
	@Input() public initialValues: any;
	@Input() public isRequiredCheckbox: boolean = true;

	@Output() public removeComponentSource: EventEmitter<object> = new EventEmitter<object>();
	@Output() public changeValuesSource: EventEmitter<any> = new EventEmitter<any>();

	public formGroup: UntypedFormGroup;

	public variantIcon: string = '';
	private formSubscription: Subscription;

	constructor(
		private cdr: ChangeDetectorRef,
		private formBuilder: UntypedFormBuilder,
		private dialog: MatDialog,
		private updateValidationService: FormValidationService,
		private snackBarService: SnackBarService
	) {}

	public ngOnInit(): void {
		this.getIcons();
		isNullOrUndefined(this.initialValues) ? this.createDefaultForm() : this.createFormWithValues();
		this.formSubscription = this.formGroup.valueChanges.subscribe(
			(value: any) => {
				this.changeValuesSource.emit(value);
			},
			() => this.snackBarService.showSnackBar('Unknown error')
		);
		this.updateValidationService.checkValidation$.subscribe(
			() => {
				this.updateValidationService.validateAllFormFields(this.formGroup);
				this.cdr.detectChanges();
			},
			() => this.snackBarService.showSnackBar('Unknown error')
		);
	}

	public removeComponent(): void {
		this.removeComponentSource.emit({});
	}

	public openMultiLanguageDialog(inputFormGroup: any, type: string, placeholder: string): void {
		const dialogRef: MatDialogRef<MultiLanguageDialogComponent> = this.dialog.open(MultiLanguageDialogComponent, {
			data: { value: inputFormGroup.value, type: type, placeholder: placeholder },
			width: '800px',
			height: 'auto'
		});
		dialogRef.componentInstance.dialogSource.subscribe(
			(data: any) => {
				inputFormGroup.setValue(data);
				inputFormGroup.markAllAsTouched();
				dialogRef.close();
			},
			() => this.snackBarService.showSnackBar('Unknown error')
		);
	}

	public addFormControl(variant?: any): void {
		const productsArray: UntypedFormArray = this.formGroup.controls.variants as UntypedFormArray;

		const newProductGroup: UntypedFormGroup = isNullOrUndefined(variant?.names)
			? this.formBuilder.group({
					names: this.formBuilder.group({
						English: new UntypedFormControl('', [Validators.required, Validators.maxLength(61)]),
						French: new UntypedFormControl(''),
						Dutch: new UntypedFormControl(''),
						German: new UntypedFormControl('')
					}),
					id: new UntypedFormControl(null)
			  })
			: this.formBuilder.group({
					names: this.formBuilder.group({
						English: new UntypedFormControl(variant.names.English, [Validators.required, Validators.maxLength(61)]),
						French: new UntypedFormControl(variant.names.French),
						Dutch: new UntypedFormControl(variant.names.Dutch),
						German: new UntypedFormControl(variant.names.German)
					}),
					id: new UntypedFormControl(variant.id)
			  });
		productsArray.insert(productsArray.length, newProductGroup);
	}

	public removeFormControl(i: number): void {
		const productsArray: UntypedFormArray = this.formGroup.controls.variants as UntypedFormArray;
		productsArray.removeAt(i);
	}

	private createDefaultForm(): void {
		this.formGroup = this.formBuilder.group({
			id: new UntypedFormControl(null),
			titles: this.formBuilder.group({
				English: new UntypedFormControl('', [Validators.required, Validators.maxLength(401)]),
				French: new UntypedFormControl(''),
				Dutch: new UntypedFormControl(''),
				German: new UntypedFormControl('')
			}),
			variants: this.formBuilder.array([
				this.formBuilder.group({
					names: this.formBuilder.group({
						English: new UntypedFormControl('', [Validators.required, Validators.maxLength(61)]),
						French: new UntypedFormControl(''),
						Dutch: new UntypedFormControl(''),
						German: new UntypedFormControl('')
					}),
					id: new UntypedFormControl(null)
				})
			]),
			isMandatory: new UntypedFormControl(false)
		});
		// TODO: Set empty arrays for editing template (compose template object)
		this.changeValuesSource.emit(this.formGroup.value);
	}

	private createFormWithValues(): void {
		this.formGroup = this.formBuilder.group({
			id: new UntypedFormControl(this.initialValues.id),
			titles: this.formBuilder.group({
				English: new UntypedFormControl(this.initialValues.titles.English, [Validators.required, Validators.maxLength(401)]),
				French: new UntypedFormControl(this.initialValues.titles.French),
				Dutch: new UntypedFormControl(this.initialValues.titles.Dutch),
				German: new UntypedFormControl(this.initialValues.titles.German)
			}),
			variants: this.formBuilder.array([]),
			isMandatory: new UntypedFormControl(this.initialValues.isMandatory)
		});
		this.initialValues.variants.forEach((variant: string) => {
			this.addFormControl(variant);
		});
	}

	get variantsControls(): any {
		return (this.formGroup.controls.variants as UntypedFormArray).controls;
	}

	get titlesFormGroup(): any {
		return this.formGroup.controls.titles as UntypedFormGroup;
	}

	private getIcons(): void {
		switch (this.iconName) {
			case 'radio_button_checked':
				this.variantIcon = 'radio_button_unchecked';
				break;
			case 'check_box':
				this.variantIcon = 'check_box_outline_blank';
		}
	}
}
