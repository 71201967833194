import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewEncapsulation
} from '@angular/core';
import ImageResize from 'quill-image-resize-module';
import { QuillModules } from 'ngx-quill';

import * as QuillNamespace from 'quill';
import { isNullOrUndefined } from '@shared/tools/is-undefined-null';

const ImageFormatAttributesList: string[] = ['alt', 'height', 'width', 'style'];
const Quill: any = QuillNamespace;

const BaseImageFormat: any = Quill.import('formats/image');
class ImageFormat extends BaseImageFormat {
	public static formats(domNode: any): any {
		return ImageFormatAttributesList.reduce(function (formats: any, attribute: string): any {
			if (domNode.hasAttribute(attribute)) {
				formats[attribute] = domNode.getAttribute(attribute);
			}
			return formats;
		}, {});
	}

	public format(name: any, value: any): any {
		if (ImageFormatAttributesList.indexOf(name) > -1) {
			if (value) {
				this.domNode.setAttribute(name, value);
			} else {
				this.domNode.removeAttribute(name);
			}
		} else {
			super.format(name, value);
		}
	}
}

Quill.register(ImageFormat, true);
Quill.register('modules/imageResize', ImageResize);
const Block: any = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);

@Component({
	selector: 'cactussoft-text-editor',
	templateUrl: './text-editor.component.html',
	styleUrls: ['./text-editor.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextEditorComponent implements OnInit, OnChanges {
	@Input() public initValue: string;
	@Input() public valueFromMultiLang: string;
	@Input() public toolbarOptions: QuillModules;
	@Input() public placeholder: string;
	@Input() public scrollingContainer: string;
	@Output() public changedSource: EventEmitter<any> = new EventEmitter<any>();

	public modules: QuillModules = {
		toolbar: [
			['bold', 'italic', 'underline', 'strike'],
			[{ list: 'ordered' }, { list: 'bullet' }],
			[{ header: [1, 2, 3, 4, 5, 6, false] }],
			[{ color: [] }, { background: [] }],
			['link', 'image']
		],
		imageResize: true
	};
	public text: string = '';

	constructor() {}

	public ngOnInit(): void {
		if (!isNullOrUndefined(this.toolbarOptions)) {
			this.modules = this.toolbarOptions;
		}
		this.modules.clipboard = { matchVisual: false };
		this.placeholder = !isNullOrUndefined(this.placeholder) ? this.placeholder : '*Body';
		this.text = this.initValue;
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.valueFromMultiLang && !changes.valueFromMultiLang.firstChange) {
			this.text = changes.valueFromMultiLang.currentValue;
		}
	}

	public changedEditor(value: any): void {
		if (this.text === '<div><br></div>') {
			this.text = null;
		}
		this.changedSource.emit({ richText: `<body>${this.text}</body>`, text: value.text });
	}
}
