import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	OnInit,
	Output,
	signal,
	SimpleChanges
} from '@angular/core';
import { AssignmentClientsDtoInterface } from '@cactussoft/clients/interfaces/assignment-client-dto.interface';
import { TreeDialogInitialValuesInterface } from '@shared/components/tree/tree-dialog-initial-values.interface';
import { forkJoin, ReplaySubject } from 'rxjs';
import { ClientDtoInterface } from '@cactussoft/clients/interfaces/client-dto.interface';
import { ClientsService } from '@cactussoft/clients/services/clients.service';
import { takeUntil } from 'rxjs/operators';
import { CLIENT_TYPES } from '@cactussoft/clients/clients-board/constants/client-types.constants';
import { ClientServicesService } from '@cactussoft/services/services/client-services.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SnackBarService } from '@shared/services/snack-bar/snack-bar.service';
import { ClientIntoChecklist, FlatChecklistInterface } from '@shared/components/flat-checklist/flat-checklist.interface';

@Component({
	selector: 'cactussoft-flat-checklist',
	templateUrl: './flat-checklist.component.html',
	styleUrls: ['./flat-checklist.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlatChecklistComponent implements OnInit, OnChanges {
	@Input() public dialogData: TreeDialogInitialValuesInterface;
	@Input() public filterValue: string;
	@Output() public closeDialogSource: EventEmitter<object> = new EventEmitter<object>();
	@Output() public selectedClientsSource: EventEmitter<AssignmentClientsDtoInterface> = new EventEmitter<AssignmentClientsDtoInterface>();
	public clientList: ClientIntoChecklist[] = [];
	public CLIENT_TYPES = CLIENT_TYPES;
	public checklist = signal<FlatChecklistInterface>({
		name: 'Select All',
		allSelected: false,
		isIntermediate: false,
		clientList: []
	});
	private destroy$: ReplaySubject<void> = new ReplaySubject<void>();

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: TreeDialogInitialValuesInterface,
		private clientsService: ClientsService,
		private clientsServiceService: ClientServicesService,
		private clientListUpdated: ChangeDetectorRef,
		private snackBarService: SnackBarService
	) {}

	public closeDialog(): void {
		this.closeDialogSource.emit({});
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes['filterValue']) {
			const filterValue = changes['filterValue'].currentValue;
			if (filterValue?.length) {
				this.checklist().clientList = this.clientList?.filter((client) => {
					return client.name.toLowerCase().includes(filterValue.toLowerCase());
				});
			} else {
				this.checklist().clientList = this.clientList;
			}
		}
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public ngOnInit(): void {
		forkJoin([this.clientsService.getAllClients(), this.clientsServiceService.getClientsByServiceId(this.data?.id)])
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				([allClients, clientsAssignedToCurrentService]: [ClientDtoInterface[], string[]]) => {
					const allSelected = clientsAssignedToCurrentService.length === allClients.length;
					this.checklist().allSelected = allSelected;
					this.checklist().isIntermediate = allClients.length > 0 && clientsAssignedToCurrentService.length > 0 && !allSelected;
					const updatedClientsList = allClients?.map((client) => {
						return {
							selected: clientsAssignedToCurrentService.includes(client.id),
							...client
						};
					});
					this.checklist().clientList = updatedClientsList;
					this.clientList = updatedClientsList;
					this.clientListUpdated.markForCheck();
				},
				() => this.snackBarService.showSnackBar('Something going wrong during getting clients info')
			);
	}

	public sendData(): void {
		const selectedClients: any = [];
		this.checklist().clientList.forEach((client: any) => {
			if (client.selected) {
				selectedClients.push(client.id);
			}
		});
		this.selectedClientsSource.emit(selectedClients);
	}

	public update(selected: boolean, index?: number) {
		this.checklist.update((task) => {
			if (index === undefined) {
				task.allSelected = selected;
				task.clientList?.forEach((t: ClientIntoChecklist) => (t.selected = selected));
			} else {
				task.clientList![index].selected = selected;
				task.allSelected = task.clientList?.every((t: ClientIntoChecklist) => t.selected) ?? true;
				task.isIntermediate =
					this.clientList.some((t) => t.selected as boolean) && !this.clientList.every((t) => t.selected as boolean);
			}
			return { ...task };
		});
	}
}
