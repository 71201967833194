import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { FilterValuesInterface } from '@shared/components/table/interfaces/filter-values.interface';
import { queryBuilder } from '@shared/tools/query-builder';

import { SuggestNewsPageInterface } from '../interfaces/suggest-news-page.interface';
import { SuggestNewsInterface } from '../interfaces/suggest-news.interface';
import { environment } from '../../../../../apps/savviva-board/src/environments/environment';

const baseUrl: string = `${JSON.parse(localStorage.getItem('env'))?.newsfeed}/suggests`;

@Injectable({
	providedIn: 'root'
})
export class SuggestNewsService {
	constructor(private http: HttpClient) {}

	public getSuggestNews(filterValues?: FilterValuesInterface): Observable<SuggestNewsPageInterface> {
		return this.http.get<SuggestNewsPageInterface>(`${baseUrl}${queryBuilder(filterValues)}`);
	}

	public getSuggestNewsById(id: string): Observable<SuggestNewsInterface> {
		return this.http.get<SuggestNewsInterface>(`${baseUrl}/${id}`);
	}

	public changeSuggestNewsState(id: string, status: number): Observable<any> {
		return this.http.patch<any>(`${baseUrl}/${id}?status=${status}`, {});
	}
}
