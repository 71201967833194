<div class="savviva-container">
	<mat-sidenav-container class="sidenav-container" #container>
		<mat-sidenav [opened]="isLogin" [position]="'start'" [mode]="'side'" [disableClose]='true' class="sidenav-container__menu">
			<div class='sidenav-container__logo'>
				<img src='assets/savviva_logo.svg'>
			</div>
			<cactussoft-accordion-menu
				[menuLinks]="slideMenuLinks"
				(loginSource)="logout()">
			</cactussoft-accordion-menu>
		</mat-sidenav>
		<mat-sidenav-content>
			<cactussoft-breadcrumb></cactussoft-breadcrumb>
			<router-outlet></router-outlet>
		</mat-sidenav-content>
	</mat-sidenav-container>
	<ng-progress [color]="'#256487'"></ng-progress>
</div>
