import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router, RouterEvent, UrlSegment } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { filter } from 'rxjs/operators';
import { isNullOrUndefined } from '@shared/tools/is-undefined-null';
import { SnackBarService } from '@shared/services/snack-bar/snack-bar.service';

@Component({
	selector: 'cactussoft-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent implements OnInit {
	public menuItems: MenuItem[];
	private ROUTE_DATA_BREADCRUMB: string = 'breadcrumb';

	constructor(private router: Router, private activatedRoute: ActivatedRoute, private snackBarService: SnackBarService) {}

	public ngOnInit(): void {
		this.router.events.pipe(filter((event: Event) => event instanceof NavigationEnd)).subscribe(
			() => {
				this.menuItems = this.createBreadcrumbs(this.activatedRoute.root);
				if (this.menuItems.length === 1) {
					this.menuItems = [];
				}
			},
			() => this.snackBarService.showSnackBar('Unknown error')
		);
	}

	private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: MenuItem[] = []): MenuItem[] {
		const children: ActivatedRoute[] = route.children;
		if (children.length === 0) {
			return breadcrumbs;
		}

		for (const child of children) {
			const routeURL: string = child.snapshot.url.map((segment: UrlSegment) => segment.path).join('/');
			if (routeURL !== '') {
				url += `/${routeURL}`;
			}

			const label: string = child.snapshot.data[this.ROUTE_DATA_BREADCRUMB];
			if (!isNullOrUndefined(label)) {
				const routerUrl: any = { label, routerLink: [url] };
				breadcrumbs.push(routerUrl);
			}

			return this.createBreadcrumbs(child, url, breadcrumbs);
		}
	}
}
