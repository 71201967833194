/* eslint-disable no-magic-numbers */
import { FilterValuesInterface, SORT_DIRECTION } from '../components/table/interfaces/filter-values.interface';
import { isNullOrUndefined } from './is-undefined-null';

import { FilterSelectInterface } from '@shared/interfaces/filter-select.interface';

export function queryBuilder(
	filterValues: FilterValuesInterface = {
		nextPageIndex: 0,
		sortField: undefined,
		sortDirection: undefined,
		search: undefined,
		filters: undefined
	}
): string {
	const obj: any = {
		search: `Search=${filterValues.search}`,
		pageNumber: `PageNumber=${Boolean(filterValues.nextPageIndex) ? filterValues.nextPageIndex : 0}`,
		pageSize: `PageSize=${10}`,
		sortField: `SortField.Name=${filterValues.sortField}&SortField.Order=${SORT_DIRECTION[filterValues.sortDirection]}`
	};

	const sortQuery: string =
		!isNullOrUndefined(filterValues.sortField) && !isNullOrUndefined(filterValues.sortDirection)
			? `${obj.pageNumber}&${obj.pageSize}&${obj.sortField}`
			: `${obj.pageNumber}&${obj.pageSize}`;
	let selectionFiltersQuery: string = '';
	if (filterValues.filters) {
		filterValues.filters.forEach((filterValue: FilterSelectInterface) => {
			if (Array.isArray(filterValue.selectedValue)) {
				filterValue.selectedValue.forEach((value: string[]) => {
					selectionFiltersQuery = `${selectionFiltersQuery}&${filterValue.uriPart}=${value}`;
				});
			} else if (filterValue.selectedValue && !isNullOrUndefined(filterValue.selectedValue)) {
				selectionFiltersQuery = `${selectionFiltersQuery}&${filterValue.uriPart}=${filterValue.selectedValue}`;
			}
		});
	}
	return !isNullOrUndefined(filterValues.search)
		? `?${obj.search}&${sortQuery}&${selectionFiltersQuery}`
		: `?${sortQuery}&${selectionFiltersQuery}`;
}
