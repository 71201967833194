<div class='empty-content-container'>
	<img class='image' src='{{"/assets/empty-content.svg"}}'>
	<div class='message-container'>
		<h2 class='message-container__title'>{{link ? messages.withLink.title : messages.withoutLink.title}}</h2>
		<div *ngIf='link else withoutLink'>
			<h4 class='message-container__subtitle'>Click <span class='link' [routerLink]='link'>Create New</span> and and
				set up your first client, building or service</h4>
		</div>
	</div>
	<ng-template #withoutLink>
		<h4 class='message-container__subtitle'>{{messages.withoutLink.subTitle}}</h4>
	</ng-template>
</div>
