<form [formGroup]='formGroup'>
	<div class='input-with-icon-builder-container' [ngSwitch]='componentName'>
		<ng-container *ngIf="['Alert'].includes(componentName)">
			<div class='component'>
				<div class='draggable-icon-wrapper' cdkDrag>
					<mat-icon class='component-icon'>drag_indicator</mat-icon>
				</div>
				<div class='component-icon-wrapper'>
					<mat-icon class='component-icon'
							  [ngClass]='selectedAlertType.type'>{{selectedAlertType.icon}}</mat-icon>
					<div class='component-radio-group'>
						<mat-radio-group (change)='alertTypeChanged($event)'>
							<mat-radio-button *ngFor='let alertOption of alertOptions'
											  [checked]='alertOption.id === selectedAlertType.id'
											  [ngClass]='selectedAlertType.type'
											  [value]='alertOption.id'></mat-radio-button>
						</mat-radio-group>
					</div>
				</div>
				<div class='component-input' [formGroup]='titlesFormGroup'>
					<div class='form__row-block'>
						<cactussoft-text-editor style='width: 100%;'
												[initValue]='titlesFormGroup.controls.English.value'
												[valueFromMultiLang]='editorEnglishValue'
												[placeholder]='placeholder.title'
												[toolbarOptions]='toolbarOptions'
												[scrollingContainer]='scrollingContainer'
												(changedSource)='getText($event,titlesFormGroup)'></cactussoft-text-editor>
					</div>
					<div class='editor-validation-message'
						 *ngIf='titlesFormGroup.controls.English.touched && titlesFormGroup.controls.English.invalid'>
						<span *ngIf='titlesFormGroup.controls.English.errors.required'>Required field</span>
						<span *ngIf='titlesFormGroup.controls.English.errors.maxlength'>Max length - 2000 char</span>
					</div>
				</div>
				<div class='option-actions'>
					<mat-icon class='icon'
							  (click)='openMultiLanguageDialog(titlesFormGroup,"editor",placeholder.title)'>language
					</mat-icon>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="['Description'].includes(componentName)">
			<div class='component'>
				<div class='draggable-icon-wrapper' cdkDrag>
					<mat-icon class='component-icon'>drag_indicator</mat-icon>
				</div>
				<div class='component-icon-wrapper'>
					<mat-icon class='component-icon'>{{iconName}}</mat-icon>
				</div>
				<div class='component-input' [formGroup]='titlesFormGroup'>
					<div class='form__row-block'>
						<cactussoft-text-editor style='width: 100%;'
												[initValue]='titlesFormGroup.controls.English.value'
												[valueFromMultiLang]='editorEnglishValue'
												[placeholder]='placeholder.title'
												[toolbarOptions]='toolbarOptions'
												[scrollingContainer]='scrollingContainer'
												(changedSource)='getText($event,titlesFormGroup)'></cactussoft-text-editor>
					</div>
					<div class='editor-validation-message'
						 *ngIf='titlesFormGroup.controls.English.touched && titlesFormGroup.controls.English.invalid'>
						<span *ngIf='titlesFormGroup.controls.English.errors.required'>Required field</span>
						<span *ngIf='titlesFormGroup.controls.English.errors.maxlength'>Max length - 2000 char</span>
					</div>
				</div>
				<div class='option-actions'>
					<mat-icon class='icon'
							  (click)='openMultiLanguageDialog(titlesFormGroup,"editor",placeholder.title)'>language
					</mat-icon>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="['Input'].includes(componentName)">
			<div class='component'>
				<div class='draggable-icon-wrapper' cdkDrag>
					<mat-icon class='component-icon'>drag_indicator</mat-icon>
				</div>
				<div class='component-icon-wrapper'>
					<mat-icon class='component-icon'>{{iconName}}</mat-icon>
				</div>
				<div class='component-input' [formGroup]='titlesFormGroup'>
					<span class='component-input-description' *ngIf="componentName === 'Input'">Input placeholder</span>
					<mat-form-field appearance='fill'>
						<mat-label> {{placeholder.title}}
						</mat-label>
						<textarea cdkTextareaAutosize
								  cdkAutosizeMinRows='3'
								  cdkAutosizeMaxRows='3' matInput formControlName='English' required></textarea>
						<mat-error
							*ngIf='titlesFormGroup.controls.English.touched && titlesFormGroup.controls.English.invalid'>
							<span *ngIf='titlesFormGroup.controls.English.errors.required'>Required field</span>
							<span
								*ngIf='titlesFormGroup.controls.English.errors.maxlength'> Max length - 400 char</span>
						</mat-error>
					</mat-form-field>
					<div [formGroup]='formGroup'>
						<mat-radio-group formControlName='inputType'>
							<mat-radio-button class='status-container__radio-button' [value]='0'>One line
							</mat-radio-button>
							<mat-radio-button class='status-container__radio-button' [value]='1'>Text area
							</mat-radio-button>
						</mat-radio-group>
					</div>
					<div class="required-checkbox-container">
						<cactussoft-required-checkbox [formGroup]="formGroup"></cactussoft-required-checkbox>
					</div>
				</div>
				<div class='option-actions' [ngStyle]="{'padding-top':componentName === 'Input' ? '41px' : '17px'}">
					<mat-icon class='icon' (click)='openMultiLanguageDialog(titlesFormGroup,"input",placeholder.title)'>
						language
					</mat-icon>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="['Toggle'].includes(componentName)">
			<div style='width: 100%;'>
				<div class='component'>
					<div class='draggable-icon-wrapper' cdkDrag>
						<mat-icon class='component-icon'>drag_indicator</mat-icon>
					</div>
					<div class='component-icon-wrapper'>
						<mat-icon class='component-icon'>{{iconName}}</mat-icon>
					</div>
					<div class='component-input' [formGroup]='titlesFormGroup'>
						<mat-form-field appearance='fill'>
							<mat-label> {{placeholder.title}}
							</mat-label>
							<input matInput formControlName='English' required>
							<mat-error
								*ngIf='titlesFormGroup.controls.English.touched && titlesFormGroup.controls.English.invalid'>
								<span *ngIf='titlesFormGroup.controls.English.errors.required'>Required field</span>
								<span
									*ngIf='titlesFormGroup.controls.English.errors.maxlength'> Max length - 60 char</span>
							</mat-error>
						</mat-form-field>
					</div>
					<div class='option-actions'>
						<mat-icon class='icon'
								  (click)='openMultiLanguageDialog(titlesFormGroup,"input",placeholder.title)'>language
						</mat-icon>
					</div>
				</div>
				<div class='component'>
					<div class='draggable-icon-wrapper'></div>
					<div class='component-icon-wrapper empty'></div>
					<div class='component-input' [formGroup]='infosFormGroup'>
						<span class='component-input-description'>Toggle placeholder</span>
						<mat-form-field appearance='fill'>
							<mat-label> Example: Enter message your card
							</mat-label>
							<input matInput formControlName='English' required>
							<mat-error
								*ngIf='infosFormGroup.controls.English.touched && infosFormGroup.controls.English.invalid'>
								<span *ngIf='infosFormGroup.controls.English.errors.required'>Required field</span>
								<span
									*ngIf='infosFormGroup.controls.English.errors.maxlength'> Max length - 400 char</span>
							</mat-error>
						</mat-form-field>
						<mat-form-field [formGroup]="formGroup" appearance='fill'>
							<mat-label> Price
							</mat-label>
							<input type="text" formControlName="price" matInput [imask]="maskConfig" [unmask]="'typed'">
						</mat-form-field>
					</div>
					<div class='option-actions' [ngStyle]="{'padding-top':componentName === 'Toggle' ? '41px' : '0px'}">
						<mat-icon class='icon'
								  (click)='openMultiLanguageDialog(infosFormGroup,"input","Example: Enter message your card")'>
							language
						</mat-icon>
					</div>
				</div>
				<div class="component">
					<div class='draggable-icon-wrapper'></div>
					<div class='component-icon-wrapper empty'></div>
					<div class="required-checkbox-container">
						<cactussoft-required-checkbox [formGroup]="formGroup"></cactussoft-required-checkbox>
					</div>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="['Info hidden text',].includes(componentName)">
			<div style='width: 100%;'>
				<div class='component'>
					<div class='draggable-icon-wrapper' cdkDrag>
						<mat-icon class='component-icon'>drag_indicator</mat-icon>
					</div>
					<div class='component-icon-wrapper'>
						<mat-icon class='component-icon'>{{iconName}}</mat-icon>
					</div>
					<div class='component-input' [formGroup]='titlesFormGroup'>
						<mat-form-field appearance='fill'>
							<mat-label> {{placeholder.title}}
							</mat-label>
							<input matInput formControlName='English' required>
							<mat-error
								*ngIf='titlesFormGroup.controls.English.touched && titlesFormGroup.controls.English.invalid'>
								<span *ngIf='titlesFormGroup.controls.English.errors.required'>Required field</span>
								<span
									*ngIf='titlesFormGroup.controls.English.errors.maxlength'> Max length - 400 char</span>
							</mat-error>
						</mat-form-field>
					</div>
					<div class='option-actions'>
						<mat-icon class='icon'
								  (click)='openMultiLanguageDialog(titlesFormGroup,"input",placeholder.title)'>language
						</mat-icon>
					</div>
				</div>
				<div class='component'>
					<div class='draggable-icon-wrapper'></div>
					<div class='component-icon-wrapper empty'></div>
					<div class='component-input' [formGroup]='previewsFormGroup'>
						<cactussoft-text-editor style='width: 100%;'
												[initValue]='previewsFormGroup.controls.English.value'
												[valueFromMultiLang]='editorEnglishValue'
												[placeholder]='placeholder.option'
												[toolbarOptions]='toolbarOptions'
												[scrollingContainer]='scrollingContainer'
												(changedSource)='getText($event,previewsFormGroup)'></cactussoft-text-editor>
						<div class='editor-validation-message'
							 *ngIf='previewsFormGroup.controls.English.touched && previewsFormGroup.controls.English.invalid'>
							<span
								*ngIf='previewsFormGroup.controls.English.errors.maxlength'>Max length - 2000 char</span>
						</div>
					</div>
					<div class='option-actions'>
						<mat-icon class='icon'
								  (click)='openMultiLanguageDialog(previewsFormGroup,"editor",placeholder.option)'>
							language
						</mat-icon>
					</div>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="['Input (price)'].includes(componentName)">
			<div style='width: 100%;'>
				<div class='component'>
					<div class='draggable-icon-wrapper' cdkDrag>
						<mat-icon class='component-icon'>drag_indicator</mat-icon>
					</div>
					<div class='component-icon-wrapper'>
						<img class='component-icon' src='{{"assets/icons/" + iconName}}' />
					</div>
					<div class='component-input' [formGroup]='titlesFormGroup'>
						<mat-form-field appearance='fill'>
							<mat-label> {{placeholder.title}}
							</mat-label>
							<input matInput formControlName='English' required>
							<mat-error
								*ngIf='titlesFormGroup.controls.English.touched && titlesFormGroup.controls.English.invalid'>
								<span *ngIf='titlesFormGroup.controls.English.errors.required'>Required field</span>
								<span
									*ngIf='titlesFormGroup.controls.English.errors.maxlength'> Max length - 30 char</span>
							</mat-error>
						</mat-form-field>
					</div>
					<div class='option-actions'>
						<mat-icon class='icon'
								  (click)='openMultiLanguageDialog(titlesFormGroup,"input",placeholder.title)'>language
						</mat-icon>
					</div>
				</div>
				<div class='component'>
					<div class='draggable-icon-wrapper'></div>
					<div class='component-icon-wrapper empty'></div>
					<div class='component-input' [formGroup]='infosFormGroup'>
						<mat-form-field appearance='fill'>
							<mat-label> Input placeholder
							</mat-label>
							<input matInput formControlName='English' required>
							<mat-error
								*ngIf='infosFormGroup.controls.English.touched && infosFormGroup.controls.English.invalid'>
								<span *ngIf='infosFormGroup.controls.English.errors.required'>Required field</span>
								<span
									*ngIf='infosFormGroup.controls.English.errors.maxlength'> Max length - 15 char</span>
							</mat-error>
						</mat-form-field>
						<div class="required-checkbox-container">
							<cactussoft-required-checkbox [formGroup]="formGroup"></cactussoft-required-checkbox>
						</div>
					</div>
					<div class='option-actions' [ngStyle]="{'padding-top':componentName === 'Toggle' ? '41px' : '0px'}">
						<mat-icon class='icon'
								  (click)='openMultiLanguageDialog(infosFormGroup,"input","Placeholder")'>
							language
						</mat-icon>
					</div>
				</div>
			</div>
		</ng-container>
		<div class='remove-container' [ngStyle]="{'top':componentName === 'Input' ? '71px' : '47px'}">
			<mat-icon class='icon' (click)='removeComponent()'>delete</mat-icon>
		</div>
	</div>
</form>
