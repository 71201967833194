import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatTreeModule } from '@angular/material/tree';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { QRCodeModule } from 'angularx-qrcode';
import { QuillModule } from 'ngx-quill';
import { TableComponent } from '@shared/components/table/table.component';
import { StatusCellComponent } from '@shared/components/status-cell/status-cell.component';
import { BadgeCellComponent } from '@shared/components/badge-cell/badge-cell.component';
import { UploaderComponent } from '@shared/components/uploader/uploader.component';
import { ChipsComponent } from '@shared/components/chips/chips.component';
import { TableActionsComponent } from '@shared/components/table/table-actions/table-actions.component';
import { InputWithIconBuilderComponent } from '@shared/components/component-builder/input-with-icon/input-with-icon-builder.component';
import { InputWithVariantsComponent } from '@shared/components/component-builder/input-with-variants/input-with-variants.component';
import { PickerBuilderComponent } from '@shared/components/component-builder/picker-builder/picker-builder.component';
import { ImageLoaderBuilderComponent } from '@shared/components/component-builder/image-loader-builder/image-loader-builder.component';
import { TwoColumnProductListComponent } from '@shared/components/component-builder/two-column-product-list/two-column-product-list.component';
import { BasicAssignDialogComponent } from '@shared/components/basic-assign-dialog/basic-assign-dialog.component';
import { LabelsBuilderComponent } from '@shared/components/component-builder/labels-builder/labels-builder.component';
import { BadgeComponent } from '@shared/components/badge/badge.component';
import { TextEditorComponent } from '@shared/components/text-editor/text-editor.component';
import { DetailViewUserComponent } from '@shared/components/detail-view-user/detail-view-user.component';
import { ExpandableAssignDialogComponent } from '@shared/components/expandable-assign-dialog/expandable-assign-dialog.component';
import { TreeChecklistComponent } from '@shared/components/tree/tree.component';
import { AccordionMenuComponent } from '@shared/components/accordion-menu/accordion-menu.component';
import { GalleryBuilderComponent } from '@shared/components/component-builder/gallery-builder/gallery-builder.component';
import { DetailViewSuggestComponent } from '@shared/components/detail-view-suggest/detail-view-suggest.component';
import { AddSpacePageDialogComponent } from '@shared/components/add-space-page-dialog/add-space-page-dialog.component';
import { SaveAsTamplateDialogComponent } from '@shared/components/save-as-tamplate-dialog/save-as-tamplate-dialog.component';
import { CreateServiceComponent } from '@cactussoft/services/services-board/create-service/create-service.component';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { MultiAttachFilesComponent } from '@shared/components/multi-attach-files/multi-attach-files.component';
import { BoardFilterComponent } from '@shared/components/board-filter/board-filter.component';
import { ForgotPasswordDialogComponent } from '@shared/components/forgot-password-dialog/forgot-password-dialog.component';
import { ResetPasswordComponent } from '@shared/components/reset-password/reset-password.component';
import { RegisterAdminComponent } from '@shared/components/register-admin/register-admin.component';
import { MultiLanguageDialogComponent } from '@shared/components/multi-language-dialog/multi-language-dialog.component';
import { EmptyContentComponent } from '@shared/components/empty-content/empty-content.component';
import { ProductListBuilderComponent } from '@shared/components/component-builder/product-list-builder/product-list-builder.component';
import { EnumToArrayPipe } from '@shared/pipes/enum-to-array.pipe';
import { HighLightPipe } from '@shared/pipes/high-light.pipe';
import { RequiredCheckboxComponent } from '@shared/components/required-checkbox/required-checkbox.component';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { DeliveryPriceComponent } from './components/delivery-price/delivery-price.component';
import { InvoicingStatePipe } from '@shared/pipes/invoicing-state.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { IMaskModule } from 'angular-imask';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgProgressModule } from 'ngx-progressbar';
import { MenuComponent } from '@shared/components/menu/menu.component';
import { CreateIntegrationComponent } from '@cactussoft/services/services-board/create-integration/create-integration.component';
import { FlatChecklistComponent } from '@shared/components/flat-checklist/flat-checklist.component';

@NgModule({
	declarations: [
		TableComponent,
		TableActionsComponent,
		StatusCellComponent,
		BadgeCellComponent,
		UploaderComponent,
		ChipsComponent,
		InputWithIconBuilderComponent,
		InputWithVariantsComponent,
		PickerBuilderComponent,
		ImageLoaderBuilderComponent,
		BadgeComponent,
		BasicAssignDialogComponent,
		ExpandableAssignDialogComponent,
		ConfirmationDialogComponent,
		DetailViewUserComponent,
		AddSpacePageDialogComponent,
		SaveAsTamplateDialogComponent,
		ForgotPasswordDialogComponent,
		MultiLanguageDialogComponent,
		ResetPasswordComponent,
		DetailViewSuggestComponent,
		TwoColumnProductListComponent,
		ProductListBuilderComponent,
		LabelsBuilderComponent,
		RegisterAdminComponent,
		TextEditorComponent,
		GalleryBuilderComponent,
		TreeChecklistComponent,
		AccordionMenuComponent,
		CreateServiceComponent,
		MultiAttachFilesComponent,
		BoardFilterComponent,
		EmptyContentComponent,
		EnumToArrayPipe,
		HighLightPipe,
		InvoicingStatePipe,
		RequiredCheckboxComponent,
		DeliveryPriceComponent,
		MenuComponent,
		CreateIntegrationComponent,
		FlatChecklistComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatSidenavModule,
		RouterModule,
		NgProgressHttpModule,
		NgProgressModule,
		MatSidenavModule,
		MatToolbarModule,
		MatIconModule,
		MatButtonModule,
		MatTableModule,
		MatMenuModule,
		MatInputModule,
		MatListModule,
		MatSelectModule,
		MatRadioModule,
		MatChipsModule,
		MatButtonToggleModule,
		MatDialogModule,
		MatPaginatorModule,
		MatSortModule,
		MatProgressSpinnerModule,
		MatCheckboxModule,
		MatTabsModule,
		MatSlideToggleModule,
		DragDropModule,
		MatSnackBarModule,
		BreadcrumbModule,
		MatExpansionModule,
		RouterModule,
		MatTreeModule,
		QRCodeModule,
		IMaskModule,
		QuillModule.forRoot()
	],
	exports: [
		TableComponent,
		TableActionsComponent,
		StatusCellComponent,
		BadgeCellComponent,
		UploaderComponent,
		ChipsComponent,
		BadgeComponent,
		InputWithIconBuilderComponent,
		InputWithVariantsComponent,
		PickerBuilderComponent,
		ImageLoaderBuilderComponent,
		TwoColumnProductListComponent,
		ProductListBuilderComponent,
		LabelsBuilderComponent,
		TextEditorComponent,
		GalleryBuilderComponent,
		TreeChecklistComponent,
		AccordionMenuComponent,
		CreateServiceComponent,
		MultiAttachFilesComponent,
		BoardFilterComponent,
		ResetPasswordComponent,
		RegisterAdminComponent,
		EmptyContentComponent,
		DeliveryPriceComponent,
		EnumToArrayPipe,
		HighLightPipe,
		InvoicingStatePipe,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatSidenavModule,
		RouterModule,
		NgProgressHttpModule,
		NgProgressModule,
		MatSidenavModule,
		MatToolbarModule,
		MatIconModule,
		MatButtonModule,
		MatTableModule,
		MatMenuModule,
		MatInputModule,
		MatListModule,
		MatSelectModule,
		MatRadioModule,
		MatChipsModule,
		MatButtonToggleModule,
		MatDialogModule,
		MatProgressSpinnerModule,
		MatCheckboxModule,
		MatTabsModule,
		MatSnackBarModule,
		MatSlideToggleModule,
		DragDropModule,
		BreadcrumbModule,
		MatPaginatorModule,
		MatExpansionModule,
		MatSortModule,
		RouterModule,
		MatTreeModule,
		QRCodeModule,
		QuillModule,
		IMaskModule,
		MenuComponent,
		CreateIntegrationComponent,
		FlatChecklistComponent
	]
})
export class SharedModule {}
