import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { SnackBarService } from '@shared/services/snack-bar/snack-bar.service';

import { SuggestNewsInterface } from '../../../../../../libs/suggest-news/src/lib/interfaces/suggest-news.interface';
import { SuggestNewsService } from '../../../../../../libs/suggest-news/src/lib/services/suggest-news.service';

@Component({
	selector: 'cactussoft-detail-view-suggest',
	templateUrl: './detail-view-suggest.component.html',
	styleUrls: ['./detail-view-suggest.component.scss']
})
export class DetailViewSuggestComponent implements OnInit {
	@Output() public dialogSource: EventEmitter<any> = new EventEmitter<any>();

	public news: SuggestNewsInterface;
	public newsState: number;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { id: string },
		private cdr: ChangeDetectorRef,
		private suggestNewsService: SuggestNewsService,
		private snackBarService: SnackBarService
	) {}

	public ngOnInit(): void {
		this.suggestNewsService.getSuggestNewsById(this.data.id).subscribe(
			(_news: SuggestNewsInterface) => {
				this.news = _news;
				this.cdr.detectChanges();
			},
			() => this.snackBarService.showSnackBar('Unknown error')
		);
	}

	public changeSuggestNewsState(event: number): void {
		this.suggestNewsService.changeSuggestNewsState(this.data.id, event).subscribe(
			() => {
				this.dialogSource.emit({});
				this.snackBarService.showSnackBar('News state was changed');
			},
			() => this.snackBarService.showSnackBar('Unknown error')
		);
	}
}
