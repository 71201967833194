import { FactoryArg } from 'imask';

export function priceMaskConfig(): FactoryArg {
	return {
		mask: Number,
		scale: 2,
		min: 0,
		padFractionalZeros: true,
		radix: ',',
		mapToRadix: ['.']
	};
}
