import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, mapTo, switchMap, tap } from 'rxjs/operators';
import { Token } from '@shared/interfaces/token.interface';
import { isNullOrUndefined } from '@shared/tools/is-undefined-null';
import { Router } from '@angular/router';
import { EmailEncoder } from '@shared/services/auth/email-encoder';
import jwt_decode from 'jwt-decode';
import { Roles } from '@shared/enums/roles.enum';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	public updateContainerSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	private readonly JWT_TOKEN: string = 'JWT_TOKEN';
	private readonly REFRESH_TOKEN: string = 'REFRESH_TOKEN';
	private loggedUser: string;

	constructor(private http: HttpClient, private router: Router) {}

	public login(username: string, password: string): Observable<boolean> {
		const params: HttpParams = new HttpParams({ encoder: new EmailEncoder() })
			.set('grant_type', 'password')
			.set('scope', JSON.parse(localStorage.getItem('env'))?.scope)
			.set('client_id', JSON.parse(localStorage.getItem('env'))?.client_id)
			.set('client_secret', JSON.parse(localStorage.getItem('env'))?.client_secret)
			.set('password', password)
			.set('username', username);
		return this.http.post<any>(`${JSON.parse(localStorage.getItem('env'))?.identity}/connect/token`, params).pipe(
			switchMap((token: Token) => {
				return Roles.USER === (jwt_decode(token.access_token) as any).role ? throwError('Your account not exist') : of(token);
			}),
			tap((token: Token) => this.doLoginUser(username, token)),
			mapTo(true),
			catchError(() => {
				return throwError(false);
			})
		);
	}

	public isLoggedIn(): Observable<boolean> {
		return of(!isNullOrUndefined(this.getJwtToken())).pipe(distinctUntilChanged());
	}

	public refreshToken(): Observable<Token> {
		const params: HttpParams = new HttpParams({ encoder: new EmailEncoder() })
			.set('grant_type', 'refresh_token')
			.set('scope', 'public-api offline_access openid fileserver.api')
			.set('client_id', JSON.parse(localStorage.getItem('env'))?.client_id)
			.set('client_secret', JSON.parse(localStorage.getItem('env'))?.client_secret)
			.set('refresh_token', this.getRefreshToken());

		return this.http.post<any>(`${JSON.parse(localStorage.getItem('env'))?.identity}/connect/token`, params).pipe(
			tap((token: Token) => {
				this.storeTokens(token);
			})
		);
	}

	public forgotPassword(email: string): Observable<any> {
		return this.http.post<any>(`${JSON.parse(localStorage.getItem('env'))?.identity}/users/forgotpassword`, { email: email });
	}

	public confirmPassword(email: string, code: string, password: string): Observable<any> {
		return this.http.post<any>(`${JSON.parse(localStorage.getItem('env'))?.identity}/users/acceptEmailConfirmation`, {
			email: email,
			code: code,
			password: password
		});
	}

	public resetPassword(email: string, code: string, password: string): Observable<any> {
		return this.http.post<any>(`${JSON.parse(localStorage.getItem('env'))?.identity}/users/forgotpassword/confirm`, {
			email: email,
			code: code,
			password: password
		});
	}

	public getJwtToken(): string {
		return localStorage.getItem(this.JWT_TOKEN);
	}

	public isRoleHasAccess(allowedRoles: string[]): boolean {
		if (allowedRoles == null || allowedRoles.length === 0) {
			return true;
		}
		return allowedRoles.includes(this.getUserRole());
	}

	public doLogoutUser(): void {
		this.loggedUser = null;
		this.removeTokens();
		this.updateContainerSubject.next(true);
		this.router.navigate(['auth']);
	}

	public getUserRole(): string {
		const token: string = localStorage.getItem(this.JWT_TOKEN);
		return isNullOrUndefined(token) ? '' : (jwt_decode(token) as any).role;
	}

	private doLoginUser(username: string, token: Token): void {
		this.loggedUser = username;
		this.storeTokens(token);
		this.updateContainerSubject.next(true);
		this.router.navigate(['/news']);
	}

	private getRefreshToken(): string {
		return localStorage.getItem(this.REFRESH_TOKEN);
	}

	private storeJwtToken(jwt: string): void {
		localStorage.setItem(this.JWT_TOKEN, jwt);
	}

	private storeTokens(token: Token): void {
		localStorage.setItem(this.JWT_TOKEN, token.access_token);
		localStorage.setItem(this.REFRESH_TOKEN, token.refresh_token);
	}

	private removeTokens(): void {
		localStorage.removeItem(this.JWT_TOKEN);
		localStorage.removeItem(this.REFRESH_TOKEN);
	}
}
