/* eslint-disable no-magic-numbers */
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewEncapsulation
} from '@angular/core';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import { ALERT_OPTIONS, AlertOption } from '@shared/components/component-builder/input-with-icon/alert.constants';
import { DraggableComponentPlaceholderInterface } from '@cactussoft/services/services-board/create-service/draggable-components.interface';
import { isNullOrUndefined } from '@shared/tools/is-undefined-null';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MultiLanguageDialogComponent } from '@shared/components/multi-language-dialog/multi-language-dialog.component';
import { FormValidationService } from '@shared/services/update-validity/update-validity.service';
import { QuillModules } from 'ngx-quill';
import { SnackBarService } from '@shared/services/snack-bar/snack-bar.service';
import { priceMaskConfig } from '@shared/tools/price-mask';
import { FactoryArg } from 'imask';

@Component({
	selector: 'cactussoft-input-with-icon-builder',
	templateUrl: './input-with-icon-builder.component.html',
	styleUrls: ['./input-with-icon-builder.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputWithIconBuilderComponent implements OnInit {
	@Input() public iconName: string;
	@Input() public componentName: string;
	@Input() public placeholder: DraggableComponentPlaceholderInterface;
	@Input() public initialValues: any;
	@Input() public scrollingContainer: string;

	@Output() public removeComponentSource: EventEmitter<any> = new EventEmitter<any>();
	@Output() public changeValuesSource: EventEmitter<any> = new EventEmitter<any>();

	public formGroup: UntypedFormGroup;
	public maskConfig: FactoryArg = priceMaskConfig();

	public alertOptions: AlertOption[] = ALERT_OPTIONS;
	public selectedAlertType: AlertOption = ALERT_OPTIONS[0];
	public editorEnglishValue: string;
	public toolbarOptions: QuillModules = {
		toolbar: [['bold', 'italic'], [{ list: 'bullet' }], ['link']]
	};
	private formSubscription: Subscription;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private matDialog: MatDialog,
		private cdr: ChangeDetectorRef,
		private updateValidationService: FormValidationService,
		private snackBarService: SnackBarService
	) {}

	public ngOnInit(): void {
		isNullOrUndefined(this.initialValues) ? this.createDefaultForm() : this.createFormWithValues();
		this.formSubscription = this.formGroup.valueChanges.subscribe(
			(value: any) => {
				this.changeValuesSource.emit(value);
			},
			() => this.snackBarService.showSnackBar('Unknown error')
		);
		this.updateValidationService.checkValidation$.subscribe(
			() => {
				this.updateValidationService.validateAllFormFields(this.formGroup);
				this.cdr.detectChanges();
			},
			() => this.snackBarService.showSnackBar('Unknown error')
		);
	}

	public alertTypeChanged(event: MatRadioChange): void {
		this.selectedAlertType = this.alertOptions[event.value - 1];
		this.formGroup.controls['color'].setValue(this.selectedAlertType.id);
	}

	public openMultiLanguageDialog(inputFormGroup: any, type: string, placeholder?: string): void {
		const dialogRef: MatDialogRef<MultiLanguageDialogComponent> = this.matDialog.open(MultiLanguageDialogComponent, {
			data: {
				value: inputFormGroup.value,
				type: type,
				placeholder: placeholder,
				toolbarOptions: this.toolbarOptions
			},
			width: '800px',
			height: 'auto'
		});
		dialogRef.componentInstance.dialogSource.subscribe((data: any) => {
			inputFormGroup.setValue(data);
			inputFormGroup.markAllAsTouched();
			if (type === 'editor') {
				this.editorEnglishValue = inputFormGroup.controls.English.value;
			}
			dialogRef.close();
		});
	}

	public getText(content: { richText: string; text: string }, formGroup: any): void {
		formGroup.controls.English.setValue(content.richText);
		formGroup.markAllAsTouched();
	}

	public removeComponent(): void {
		this.removeComponentSource.emit({});
	}

	public setRequiredState(checked: boolean): void {
		if (checked) {
		}
	}

	private createDefaultForm(): void {
		this.formGroup = this.formBuilder.group({
			id: null,
			titles: this.formBuilder.group({
				English: new UntypedFormControl(null, [Validators.required, this.getTitleValidator()]),
				French: new UntypedFormControl(null, this.getTitleValidator()),
				Dutch: new UntypedFormControl(null, this.getTitleValidator()),
				German: new UntypedFormControl(null, this.getTitleValidator())
			}),
			infos: this.formBuilder.group({
				English: new UntypedFormControl(null, Validators.maxLength(401)),
				French: new UntypedFormControl(null, Validators.maxLength(401)),
				Dutch: new UntypedFormControl(null, Validators.maxLength(401)),
				German: new UntypedFormControl(null, Validators.maxLength(401))
			}),
			color: new UntypedFormControl(this.selectedAlertType.id),
			previews: this.formBuilder.group({
				English: new UntypedFormControl(null, Validators.maxLength(2001)),
				French: new UntypedFormControl(null, Validators.maxLength(2001)),
				Dutch: new UntypedFormControl(null, Validators.maxLength(2001)),
				German: new UntypedFormControl(null, Validators.maxLength(2001))
			}),
			inputType: new UntypedFormControl(0),
			isMandatory: new UntypedFormControl(false),
			price: new UntypedFormControl('')
		});
		// TODO: Set empty arrays for editing template (compose template object)
		this.changeValuesSource.emit(this.formGroup.value);
	}

	private createFormWithValues(): void {
		this.selectedAlertType = this.initialValues.color === 0 ? this.alertOptions[0] : this.alertOptions[this.initialValues.color - 1];
		this.formGroup = this.formBuilder.group({
			id: this.initialValues.id,
			titles: this.formBuilder.group({
				English: new UntypedFormControl(this.initialValues.titles.English, [Validators.required, this.getTitleValidator()]),
				French: new UntypedFormControl(this.initialValues.titles.French, this.getTitleValidator()),
				Dutch: new UntypedFormControl(this.initialValues.titles.Dutch, this.getTitleValidator()),
				German: new UntypedFormControl(this.initialValues.titles.German, this.getTitleValidator())
			}),
			infos: this.formBuilder.group({
				English: new UntypedFormControl(
					this.initialValues.infos ? this.initialValues.infos.English : null,
					this.getPlaceholderValidator()
				),
				French: new UntypedFormControl(
					this.initialValues.infos ? this.initialValues.infos.French : null,
					this.getPlaceholderValidator()
				),
				Dutch: new UntypedFormControl(
					this.initialValues.infos ? this.initialValues.infos.Dutch : null,
					this.getPlaceholderValidator()
				),
				German: new UntypedFormControl(
					this.initialValues.infos ? this.initialValues.infos.German : null,
					this.getPlaceholderValidator()
				)
			}),
			color: new UntypedFormControl(this.selectedAlertType?.id),
			previews: this.formBuilder.group({
				English: new UntypedFormControl(this.initialValues.previews?.English, Validators.maxLength(2001)),
				French: new UntypedFormControl(this.initialValues.previews?.French, Validators.maxLength(2001)),
				Dutch: new UntypedFormControl(this.initialValues.previews?.Dutch, Validators.maxLength(2001)),
				German: new UntypedFormControl(this.initialValues.previews?.German, Validators.maxLength(2001))
			}),
			inputType: new UntypedFormControl(this.initialValues.inputType),
			isMandatory: new UntypedFormControl(this.initialValues.isMandatory),
			price: new UntypedFormControl(this.initialValues.price ? this.initialValues.price : '')
		});
	}

	private getTitleValidator(): ValidatorFn {
		if (['Toggle'].includes(this.componentName)) {
			return Validators.maxLength(61);
		} else if (['Input (price)'].includes(this.componentName)) {
			return Validators.maxLength(30);
		} else {
			return Validators.maxLength(2001);
		}
	}

	private getPlaceholderValidator(): ValidatorFn {
		if (['Input (price)'].includes(this.componentName)) {
			return Validators.maxLength(15);
		} else {
			return Validators.maxLength(401);
		}
	}

	get titlesFormGroup(): any {
		return this.formGroup.controls.titles as UntypedFormGroup;
	}

	get infosFormGroup(): any {
		return this.formGroup.controls.infos as UntypedFormGroup;
	}

	get previewsFormGroup(): any {
		return this.formGroup.controls.previews as UntypedFormGroup;
	}
}
