/* eslint-disable no-magic-numbers */
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { UploaderData, UploaderStates, UploaderTypes } from '@shared/components/uploader/uploader-types';
import { FileService } from '@shared/services/file/file.service';
import { SnackBarService } from '@shared/services/snack-bar/snack-bar.service';
import { isNullOrUndefined } from '@shared/tools/is-undefined-null';

@Component({
	selector: 'cactussoft-uploader',
	templateUrl: './uploader.component.html',
	styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnChanges {
	@Input() public uploaderType: string;
	@Input() public customTitle: string;
	@Input() public initialImage: UploaderData;
	@Output() public uploadState: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() public uploadSource: EventEmitter<string> = new EventEmitter<string>();

	@ViewChild('fileInput', { static: false }) public fileInput: ElementRef;

	public selectionState: string = UploaderStates.beforeLoad;
	public file: File;
	public uploaderData: UploaderData;
	public isDefaultImage: boolean = false;
	public uploaderTitle: string[] = ['Upload a JPG, JPEG or PNG file.', 'The maximum file size is 5 MB.'];
	public uploaderId: string = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

	constructor(private cdr: ChangeDetectorRef, private fileService: FileService, private snackBarService: SnackBarService) {}

	public ngOnChanges(): void {
		this.uploaderData = !isNullOrUndefined(this.initialImage?.image) ? this.initialImage : this.selectDefaultData();
	}

	public uploadFile(file: File): void {
		this.uploadState.emit(true);
		if (file) {
			if (file.size > Math.pow(2, 20) * 5) {
				this.snackBarService.showSnackBar(
					'The image size is too big. Please reduce the image height, width, resolution, scaling or output type.'
				);
				return;
			}

			const fd: FormData = new FormData();
			this.file = file;

			const reader: FileReader = new FileReader();
			reader.readAsDataURL(file);

			reader.onload = (event: ProgressEvent<FileReader>) => {
				this.isDefaultImage = false;
				this.selectionState = UploaderStates.reload;
				this.uploaderData.image = reader.result;
				fd.append('file', this.file, this.file.name);
				this.uploadFileToServer(fd);
				this.cdr.detectChanges();
			};
		}
	}

	public deleteImage(): void {
		this.isDefaultImage = true;
		this.fileInput.nativeElement.value = '';
		this.uploaderData = this.selectDefaultData();
		this.selectionState = UploaderStates.beforeLoad;
		this.uploadSource.next(null);
	}

	private uploadFileToServer(fd: FormData): void {
		this.fileService.uploadFile(fd).subscribe({
			next: (res) => {
				this.uploadSource.next(res.fileUrl);
				this.uploadState.emit(false);
			},
			error: () => this.uploadState.emit(false)
		});
	}

	private selectDefaultData(): UploaderData {
		this.isDefaultImage = true;
		switch (this.uploaderType) {
			case UploaderTypes.clients: {
				return {
					title: 'Client logo',
					image: '/assets/clients_default.png'
				};
			}
			case UploaderTypes.buildings: {
				return {
					title: 'Building photo',
					image: '/assets/building_default.svg'
				};
			}
			case UploaderTypes.managers: {
				return {
					title: 'Profile photo',
					image: '/assets/manager_default.png'
				};
			}
			case UploaderTypes.services: {
				return {
					title: 'Service image',
					image: '/assets/clients_default.png'
				};
			}
			case UploaderTypes.integration: {
				return {
					title: 'Integration image',
					image: '/assets/clients_default.png'
				};
			}
			case UploaderTypes.news: {
				return {
					title: 'Main image',
					image: '/assets/news_default.png'
				};
			}
			case UploaderTypes.category: {
				return {
					title: 'General image',
					image: '/assets/news_default.png'
				};
			}
			case UploaderTypes.space: {
				return {
					title: 'Page image',
					image: '/assets/space_default.svg'
				};
			}
			case UploaderTypes.pageIcon: {
				return {
					title: 'Page icon',
					image: '/assets/page-icon.svg'
				};
			}
		}
	}
}
