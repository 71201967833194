import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { MenuLink } from '@shared/components/accordion-menu/slide-menu-links.constants';

@Component({
	selector: 'cactussoft-accordion-menu',
	templateUrl: './accordion-menu.component.html',
	styleUrls: ['./accordion-menu.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionMenuComponent {
	@Input() public menuLinks: MenuLink[];
	@Output() public loginSource: EventEmitter<any> = new EventEmitter<any>();

	public logout(): void {
		this.loginSource.emit({});
	}
}
