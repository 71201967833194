export const ALERT_OPTIONS: AlertOption[] = [
	{
		id: 1,
		type: 'default',
		icon: 'info'
	},
	{
		id: 2,
		type: 'warning',
		icon: 'warning'
	},
	{
		id: 3,
		type: 'success',
		icon: 'assignment_turned_in'
	}
];

// export interface AlertOptions {
// 	warning: AlertOption;
// 	default: AlertOption;
// 	success: AlertOption;
// }

export interface AlertOption {
	id: number;
	type: string;
	icon: string;
}
