import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialog as MatDialog,
	MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { SpacePageInterface } from '@cactussoft/buildings/interfaces/building-dto.interface';
import { MultiLanguageDialogComponent } from '@shared/components/multi-language-dialog/multi-language-dialog.component';

@Component({
	selector: 'cactussoft-save-as-tamplate-dialog',
	templateUrl: './save-as-tamplate-dialog.component.html',
	styleUrls: ['./save-as-tamplate-dialog.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaveAsTamplateDialogComponent implements OnInit {
	@Output() public dialogSource: EventEmitter<any> = new EventEmitter<any>();

	public saveAsTemplateForm: UntypedFormGroup;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: SpacePageInterface,
		private formBuilder: UntypedFormBuilder,
		private matDialog: MatDialog
	) {}

	public ngOnInit(): void {
		this.createDefaultForm();
	}

	public emitPageValue(): void {
		this.dialogSource.emit(this.saveAsTemplateForm.value);
	}

	public openMultiLanguageDialog(inputFormGroup: any, type: string, placeholder: string): void {
		const dialogRef: MatDialogRef<MultiLanguageDialogComponent> = this.matDialog.open(MultiLanguageDialogComponent, {
			data: { value: inputFormGroup.value, type: type, placeholder: placeholder },
			width: '800px',
			height: 'auto'
		});
		dialogRef.componentInstance.dialogSource.subscribe((data: any) => {
			inputFormGroup.setValue(data);
			inputFormGroup.markAllAsTouched();
			dialogRef.close();
		});
	}

	private createDefaultForm(): void {
		this.saveAsTemplateForm = this.formBuilder.group({
			names: this.formBuilder.group({
				English: new UntypedFormControl(null, Validators.required),
				French: new UntypedFormControl(null),
				Dutch: new UntypedFormControl(null),
				German: new UntypedFormControl(null)
			})
		});
	}

	get namesFormGroup(): any {
		return this.saveAsTemplateForm.controls.names as UntypedFormGroup;
	}
}
