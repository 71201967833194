<div class="dialog-container">
	<div class="dialog-container__header">
		<div class="title-container">
			<h2 class="title-container__title">New services for <span class="label-primary">{{data.name}}</span></h2>
		</div>
		<div class="actions-container">
			<div class="actions-container__search">
				<button mat-icon-button class="actions-container__button" (click)="openSearch()">
					<mat-icon>search</mat-icon>
				</button>
				<div class="actions-container__search_toggle" [class.active]="toggleSearch">
					<mat-form-field style="width: 100%;">
						<mat-icon matPrefix>search</mat-icon>
						<input matInput #searchInput class="search-control"
							   [(ngModel)]="searchText" (keydown.enter)="triggerSearch()">
						<button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="triggerSearch(true)">
							<mat-icon>close</mat-icon>
						</button>
					</mat-form-field>
				</div>
			</div>
		</div>
	</div>
	<div class="dialog-container__selection">
		<mat-form-field class="building-select" appearance="fill">
			<mat-label>For building</mat-label>
			<mat-select required [(value)]="selectBuilding" (selectionChange)="selectionBuildingChanged()">
				<mat-option *ngFor="let item of data.buildings" [value]="item.id">{{item.name}}</mat-option>
			</mat-select>
		</mat-form-field>
		<div>Selected: <span class="dialog-container__selection_services-count">{{data.servicesCount}} services</span></div>
	</div>

	<div *ngIf="tableData?.length > 0" class="dialog-container__table" [ngClass]="{blur:!(!!selectBuilding)}">
		<cactussoft-table
			[tableData]="tableData"
			[tableColsDescription]="cols"
			[paginatorNotExist]='true'
			[updateSelection]='isSelectionUpdate'
			(selectionSource)="getSelection($event)">
		</cactussoft-table>
	</div>
	<div class="dialog-container__buttons" style='padding: 50px 24px 32px;'>
		<div class="button-item secondary" (click)="closeDialog()">
			Cancel
		</div>
		<div class="button-item primary" (click)="sendData()">
			Save
		</div>
	</div>
</div>
