<div class='filter-container' [formGroup]="formGroup">
	<div class='filter-container__item' *ngFor="let filterFormGroup of filtersControls;">
		<mat-form-field appearance='fill' [formGroup]="filterFormGroup">
			<mat-label>{{filterFormGroup.controls.placeholder.value}}</mat-label>
			<mat-select formControlName="selectedValue" [multiple]='filterFormGroup.controls.type.value === 1'>
				<mat-option *ngIf='filterFormGroup.controls.type.value !== 1'>None</mat-option>
				<mat-option *ngFor='let item of (filterFormGroup.controls.values.value | enumToArray)' [value]='filterFormGroup.controls.values.value[item]'>{{item}}</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
</div>
