<div class='confirmation-dialog-container'>
	<div class='confirmation-dialog-container__header'>
		<h2>{{dialogData.title}}</h2>
		<mat-icon mat-dialog-close class='cancel-button'>clear</mat-icon>
	</div>
	<div class='confirmation-dialog-container__content'>
		{{dialogData.message}}
	</div>
	<div class='confirmation-dialog-container__buttons'>
		<div class='button-item secondary' (click)='onChange(false)' >
			{{!!dialogData?.cancelButton ? dialogData?.cancelButton : 'No'}}
		</div>
		<div class='button-item primary' (click)='onChange(true)'>
			{{!!dialogData?.acceptButton ? dialogData?.acceptButton : 'Yes'}}
		</div>
	</div>
</div>
