<div class="user-dialog-container" *ngIf="!!user">
	<mat-icon mat-dialog-close class="cancel-button">clear</mat-icon>
	<span class="user-dialog-container__header">User details</span>
	<div class="user-details">
		<div class="user-image">
			<img src="{{user.pictureUrl}}">
		</div>
		<div class="user-short-info">
			<div class="user-short-info__date">{{user.created | date: 'dd.MM.YYYY'}}</div>
			<div class="user-short-info__name">{{user.firstName}} {{user.lastName}}</div>
			<div class="user-short-info__client">{{user.clientName}}</div>
			<div class="user-short-info__building">{{user.buildingName}}</div>
		</div>
	</div>
	<div class="user-info">
		<div class="user-info__row">
			<div class="user-field-container"  *ngIf='user.email'>
				<span class='user-field-container__header'>Email</span>
				<span class='user-field-container__description'>{{user.email}}</span>
			</div>
			<div class="user-field-container"  *ngIf='user.phone'>
				<span class='user-field-container__header'>Phone</span>
				<span class='user-field-container__description'>{{user.phone}}</span>
			</div>
		</div>
		<div class="user-info__row">
			<div class="user-field-container">
				<span class='user-field-container__header'>Home address</span>
				<span class='user-field-container__description' [innerHTML]='getAddressView(addressTypes.home)'></span>
			</div>
			<div class="user-field-container">
				<span class='user-field-container__header'>Work address</span>
				<span class='user-field-container__description' [innerHTML]='getAddressView(addressTypes.work)'></span>
			</div>
		</div>
		<div class='invoicing-container'>
			<div class='invoicing-container__toggle'>
				<span class="invoicing-container__toggle--span">Professional Invoicing</span>
				<mat-slide-toggle [(ngModel)]="isChecked" (change)='changeInvoiceState($event)'></mat-slide-toggle>
			</div>
			<span class='invoicing-container__label'>
				Turn on and off professional invoicing for the user
			</span>
			<div class="user-info__row">
				<div class="user-field-container">
					<span class='user-field-container__header'>Invoice details</span>
					<span class='user-field-container__description' [innerHTML]='invoiceView'></span>
				</div>
				<div class="user-field-container">
				</div>
			</div>
		</div>
	</div>
	<div class="user-dialog-container__actions">
		<div class="user-dialog-container__actions__button" [ngClass]="(userState)?'active':'disable'"
			 (click)="changeUserState()">
			{{userState ? 'Active' : 'Disable'}}
		</div>

		<div class='user-dialog-container__actions__delete'
			 (click)="deleteUser()">
			<img src='assets/trash.svg'>
			Delete
		</div>
	</div>
</div>
