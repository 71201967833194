import { Component, EventEmitter, Inject, Output, ViewEncapsulation } from '@angular/core';
import { ClientServicesService } from '@cactussoft/services/services/client-services.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SnackBarService } from '@shared/services/snack-bar/snack-bar.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: 'cactussoft-delete-service',
	templateUrl: 'delete.service.component.html',
	styleUrls: ['delete.service.component.scss']
})
export class DeleteServiceComponent {
	@Output() public dialogSource: EventEmitter<boolean> = new EventEmitter<boolean>();
	private destroy$: Subject<void> = new Subject();
	public canDelete: boolean = true;

	constructor(
		private clientServicesService: ClientServicesService,
		private snackBarService: SnackBarService,
		@Inject(MAT_DIALOG_DATA) public data: { id: string; serviceType: number }
	) {}

	public onDeleteClick(): void {
		this.clientServicesService
			.removeService(this.data.id)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.dialogSource.emit(true);
			});

		// previously there were a following logic
		// if service was assigned to any building - it wasn't possible to delete this service
		// now there is no such acceptance criteria.
		// but we are leaving this part of code in case of customer would like to remain an old logic

		/*if (this.data?.serviceType !== 4) {
			this.clientServicesService.getRemovableServiceState(this.data.id).subscribe((clientsCount: number) => {
				this.canDelete = clientsCount === 0;
				if (this.canDelete) {
					this.clientServicesService.removeService(this.data.id).subscribe();
					this.dialogSource.emit(true);
				} else {
					this.snackBarService.showSnackBar('This service cannot be deleted');
				}
			});
		} else {
			this.clientServicesService.removeService(this.data.id).subscribe();
			this.dialogSource.emit(true);
		}*/
	}

	public onCloseClick(): void {
		this.dialogSource.emit(false);
	}
}
