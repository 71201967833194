import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SpacePageInterface } from '@cactussoft/buildings/interfaces/building-dto.interface';

@Component({
	selector: 'cactussoft-forgot-password-dialog',
	templateUrl: './forgot-password-dialog.component.html',
	styleUrls: ['./forgot-password-dialog.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordDialogComponent {
	@Output() public dialogSource: EventEmitter<any> = new EventEmitter<any>();
	public email: string = '';

	constructor(@Inject(MAT_DIALOG_DATA) public data: SpacePageInterface) {}

	public onChange(state: boolean): void {
		state ? this.dialogSource.emit(this.email) : this.dialogSource.emit(null);
	}
}
