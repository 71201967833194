/* eslint-disable no-magic-numbers */
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewEncapsulation
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UploaderData, UploaderTypes } from '@shared/components/uploader/uploader-types';
import { isNullOrUndefined } from '@shared/tools/is-undefined-null';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MultiLanguageDialogComponent } from '@shared/components/multi-language-dialog/multi-language-dialog.component';
import { FormValidationService } from '@shared/services/update-validity/update-validity.service';
import { SnackBarService } from '@shared/services/snack-bar/snack-bar.service';
import { FactoryArg } from 'imask';
import { priceMaskConfig } from '@shared/tools/price-mask';

@Component({
	selector: 'cactussoft-image-loader-builder',
	templateUrl: './image-loader-builder.component.html',
	styleUrls: ['./image-loader-builder.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageLoaderBuilderComponent implements OnInit {
	@Input() public iconName: string;
	@Input() public initialValues: any;

	@Output() public removeComponentSource: EventEmitter<object> = new EventEmitter<object>();
	@Output() public changeValuesSource: EventEmitter<any> = new EventEmitter<any>();

	public initialImage: UploaderData;
	public uploaderType: string = UploaderTypes.services;
	public formGroup: UntypedFormGroup;
	public maskConfig: FactoryArg = priceMaskConfig();

	private formSubscription: Subscription;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private dialog: MatDialog,
		private cdr: ChangeDetectorRef,
		private updateValidationService: FormValidationService,
		private snackBarService: SnackBarService
	) {}

	public ngOnInit(): void {
		isNullOrUndefined(this.initialValues) ? this.createDefaultForm() : this.createFormWithValues();
		this.formSubscription = this.formGroup.valueChanges.subscribe(
			(value: string) => {
				this.changeValuesSource.emit(value);
			},
			() => this.snackBarService.showSnackBar('Unknown error')
		);
		this.updateValidationService.checkValidation$.subscribe(
			() => {
				this.updateValidationService.validateAllFormFields(this.formGroup);
				this.cdr.detectChanges();
			},
			() => this.snackBarService.showSnackBar('Unknown error')
		);
	}

	public openMultiLanguageDialog(inputFormGroup: any, type: string, placeholder: string): void {
		const dialogRef: MatDialogRef<MultiLanguageDialogComponent> = this.dialog.open(MultiLanguageDialogComponent, {
			data: { value: inputFormGroup.value, type: type, placeholder: placeholder },
			width: '800px',
			height: 'auto'
		});
		dialogRef.componentInstance.dialogSource.subscribe(
			(data: any) => {
				inputFormGroup.setValue(data);
				inputFormGroup.markAllAsTouched();
				dialogRef.close();
			},
			() => this.snackBarService.showSnackBar('Unknown error')
		);
	}

	public uploadPicture(pictureUrl: string, userFormGroup: any): void {
		userFormGroup.controls['pictureUrl'].setValue(pictureUrl);
	}

	public addFormControl(option?: any): void {
		const productsArray: UntypedFormArray = this.formGroup.controls.variants as UntypedFormArray;

		const newProductGroup: UntypedFormGroup = isNullOrUndefined(option?.names)
			? this.formBuilder.group({
					pictureUrl: new UntypedFormControl('', Validators.required),
					names: this.formBuilder.group({
						English: new UntypedFormControl('', [Validators.required, Validators.maxLength(401)]),
						French: new UntypedFormControl('', [Validators.maxLength(401)]),
						Dutch: new UntypedFormControl('', [Validators.maxLength(401)]),
						German: new UntypedFormControl('', [Validators.maxLength(401)])
					}),
					infos: this.formBuilder.group({
						English: new UntypedFormControl('', [Validators.maxLength(201)]),
						French: new UntypedFormControl('', [Validators.maxLength(201)]),
						Dutch: new UntypedFormControl('', [Validators.maxLength(201)]),
						German: new UntypedFormControl('', [Validators.maxLength(201)])
					}),
					value: new UntypedFormControl('', [Validators.required, Validators.maxLength(401)]),
					id: new UntypedFormControl(null)
			  })
			: this.formBuilder.group({
					pictureUrl: new UntypedFormControl(option.pictureUrl, Validators.required),
					name: new UntypedFormControl(option.name, [Validators.required, Validators.maxLength(401)]),
					info: new UntypedFormControl(option.info, [Validators.maxLength(201)]),
					names: this.formBuilder.group({
						English: new UntypedFormControl(option.names.English, [Validators.required, Validators.maxLength(401)]),
						French: new UntypedFormControl(option.names.French, [Validators.maxLength(401)]),
						Dutch: new UntypedFormControl(option.names.Dutch, [Validators.maxLength(401)]),
						German: new UntypedFormControl(option.names.German, [Validators.maxLength(401)])
					}),
					infos: this.formBuilder.group({
						English: new UntypedFormControl(option.infos.English, [Validators.maxLength(201)]),
						French: new UntypedFormControl(option.infos.French, [Validators.maxLength(201)]),
						Dutch: new UntypedFormControl(option.infos.Dutch, [Validators.maxLength(201)]),
						German: new UntypedFormControl(option.infos.German, [Validators.maxLength(201)])
					}),
					value: new UntypedFormControl(option.value, [Validators.required, Validators.maxLength(401)]),
					id: new UntypedFormControl(option.id)
			  });
		productsArray.insert(productsArray.length, newProductGroup);
	}

	public remove(index: number): void {
		index === 0 ? this.removeComponent() : this.removeFormControl(index);
	}

	private removeComponent(): void {
		this.removeComponentSource.emit({});
	}

	private removeFormControl(i: number): void {
		const productsArray: UntypedFormArray = this.formGroup.controls.variants as UntypedFormArray;
		productsArray.removeAt(i);
	}

	private createDefaultForm(): void {
		this.formGroup = this.formBuilder.group({
			id: new UntypedFormControl(null),
			titles: this.formBuilder.group({
				English: new UntypedFormControl(null, [Validators.required, Validators.maxLength(401)]),
				French: new UntypedFormControl(null, Validators.maxLength(401)),
				Dutch: new UntypedFormControl(null, Validators.maxLength(401)),
				German: new UntypedFormControl(null, Validators.maxLength(401))
			}),
			variants: this.formBuilder.array([
				this.formBuilder.group({
					pictureUrl: new UntypedFormControl('', Validators.required),
					names: this.formBuilder.group({
						English: new UntypedFormControl('', [Validators.required, Validators.maxLength(401)]),
						French: new UntypedFormControl('', [Validators.maxLength(401)]),
						Dutch: new UntypedFormControl('', [Validators.maxLength(401)]),
						German: new UntypedFormControl('', [Validators.maxLength(401)])
					}),
					infos: this.formBuilder.group({
						English: new UntypedFormControl('', [Validators.maxLength(201)]),
						French: new UntypedFormControl('', [Validators.maxLength(201)]),
						Dutch: new UntypedFormControl('', [Validators.maxLength(201)]),
						German: new UntypedFormControl('', [Validators.maxLength(201)])
					}),
					value: new UntypedFormControl('', [Validators.required, Validators.maxLength(401)]),
					id: new UntypedFormControl(null)
				})
			]),
			isMandatory: new UntypedFormControl(false)
		});
		// TODO: Set empty arrays for editing template (compose template object)
		this.changeValuesSource.emit(this.formGroup.value);
	}

	private createFormWithValues(): void {
		this.formGroup = this.formBuilder.group({
			id: new UntypedFormControl(this.initialValues.id),
			titles: this.formBuilder.group({
				English: new UntypedFormControl(this.initialValues.titles.English, [Validators.required, Validators.maxLength(401)]),
				French: new UntypedFormControl(this.initialValues.titles.French),
				Dutch: new UntypedFormControl(this.initialValues.titles.Dutch),
				German: new UntypedFormControl(this.initialValues.titles.German)
			}),
			variants: this.formBuilder.array([]),
			isMandatory: new UntypedFormControl(this.initialValues.isMandatory)
		});
		this.initialValues.variants.forEach((option: any) => {
			this.addFormControl(option);
		});
	}

	get variantsControls(): any {
		return (this.formGroup.controls.variants as UntypedFormArray).controls;
	}

	get titlesFormGroup(): any {
		return this.formGroup.controls.titles as UntypedFormGroup;
	}

	get namesFormGroup(): any {
		return this.formGroup.controls.names as UntypedFormGroup;
	}

	get infosFormGroup(): any {
		return this.formGroup.controls.infos as UntypedFormGroup;
	}
}
