<mat-checkbox
	[(ngModel)]="selectAllState"
	[indeterminate]="isSelectAll()"
	(click)='selectAll(!selectAllState)'>
</mat-checkbox>
<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
	<mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding matTreeNodePaddingIndent="51%"
				   [ngStyle]="{'background':' #F8F8F8'}">
		<!--		if we want more than 2 level tree uncommented this place!!! -->
		<!--		<button mat-icon-button disabled></button>-->
		<mat-checkbox class="checklist-leaf-node"
					  [checked]="checklistSelection.isSelected(node)"
					  (change)="todoLeafItemSelectionToggle(node)">{{node.item.name}}</mat-checkbox>
	</mat-tree-node>

	<mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
		<mat-checkbox class="checkbox-container"
					  [checked]="descendantsAllSelected(node)"
					  [indeterminate]="descendantsPartiallySelected(node)"
					  (change)="todoItemSelectionToggle(node)">
			<div class="row-title">{{node.item.name}}</div>
			<div class="row-sub-title">{{node.item.type}}</div>
		</mat-checkbox>

		<div class="badge-container">
			<button mat-icon-button matTreeNodeToggle
					[attr.aria-label]="'Toggle ' + node.item">
				<mat-icon class="mat-icon-rtl-mirror">
					{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
				</mat-icon>
			</button>
			<cactussoft-badge [badgeData]="node.item.buildingsCount"></cactussoft-badge>
		</div>
	</mat-tree-node>
</mat-tree>
<div class="dialog-container__actions">
	<div class="dialog-container__buttons" style='padding: 24px 0 32px;'>
		<div class="button-item secondary" (click)="closeDialog()">
			Cancel
		</div>
		<div class="button-item primary" (click)="sendData()">
			Save
		</div>
	</div>
</div>

