<button
	class="menu-title"
	mat-button
	[matMenuTriggerFor]="menu"
	(menuOpened)="changeState()"
	(menuClosed)="changeState()"
>
	<span class="menu-wrapper">
		<mat-icon *ngIf="menuIconName" aria-hidden='false'>{{ menuIconName }}</mat-icon>
		<span>{{ menuLabel }}</span>
		<mat-icon class="menu-arrow-icon" *ngIf="isMenuOpened" aria-hidden='false'>arrow_drop_down</mat-icon>
		<mat-icon class="menu-arrow-icon" *ngIf="!isMenuOpened" aria-hidden='false'>arrow_drop_up</mat-icon>
	</span>
</button>
<mat-menu #menu="matMenu">
	<button
		*ngFor="let menuItem of menuItemsList"
		[routerLink]="menuItem.link"
		mat-menu-item
		class="menu-item"
	>
		{{menuItem.label}}
	</button>
</mat-menu>
