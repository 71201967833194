<mat-checkbox
	class="parent-checkbox"
	[checked]="checklist().allSelected"
	[indeterminate]="checklist().isIntermediate"
	(change)="update($event.checked)"
>
	{{checklist().name}}
</mat-checkbox>

<div class="list-container">
	<mat-checkbox
		class="child-checkbox"
		*ngFor="let client of checklist().clientList; let i = index"
		[checked]="client.selected"
		(change)="update($event.checked, i)"
	>
		<p class="checkbox-header">{{client.name}}</p>
		<p class="checkbox-description">{{CLIENT_TYPES[client.clientType]}}</p>
	</mat-checkbox>
</div>

<div class="dialog-container__actions">
	<div class="dialog-container__buttons">
		<div class="button-item secondary" (click)="closeDialog()">
			Cancel
		</div>
		<div class="button-item primary" (click)="sendData()">
			Save
		</div>
	</div>
</div>

