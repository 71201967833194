import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Injectable({
	providedIn: 'root'
})
export class FormValidationService {
	public formValidationSource: Subject<string> = new Subject<string>();
	public checkValidation$: Observable<string> = this.formValidationSource.asObservable();

	constructor() {}

	public triggerFormValidation(): void {
		this.formValidationSource.next(undefined);
	}

	public validateAllFormFields(formGroup: UntypedFormGroup | UntypedFormArray): void {
		Object.keys(formGroup.controls).forEach((field: string) => {
			const control: any = formGroup.get(field);
			if (control instanceof UntypedFormControl) {
				control.markAsTouched();
			} else if (control instanceof UntypedFormGroup) {
				this.validateAllFormFields(control);
			} else if (control instanceof UntypedFormArray) {
				control.markAsTouched();
				this.validateAllFormFields(control);
			}
		});
	}
}
