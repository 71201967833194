import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'cactussoft-chips',
	templateUrl: './chips.component.html',
	styleUrls: ['./chips.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipsComponent {
	// TODO: unused component !!!
	@Input() public items: string[];

	constructor() {}
}
