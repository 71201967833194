<div class="product-list-builder-container" [formGroup]="formGroup">
	<div class="component-container">
		<div class='component'>
			<div class='draggable-icon-wrapper' cdkDrag>
				<mat-icon class='component-icon'>drag_indicator</mat-icon>
			</div>
			<div class='component-icon-wrapper'>
				<mat-icon class='component-icon'>{{iconName}}</mat-icon>
			</div>
			<div class='component-input' [formGroup]="titlesFormGroup">
				<mat-form-field class='form__row-input' appearance='fill'>
					<mat-label> Title
					</mat-label>
					<input matInput formControlName='English' required>
					<mat-error
						*ngIf='titlesFormGroup.controls.English.touched && titlesFormGroup.controls.English.invalid'>
						<span *ngIf='titlesFormGroup.controls.English.errors.required'>Required field</span>
						<span *ngIf='titlesFormGroup.controls.English.errors.maxlength'>Max length - 400 char</span>
					</mat-error>
				</mat-form-field>
			</div>
			<div class='option-actions'>
				<mat-icon class='icon' (click)='openMultiLanguageDialog(titlesFormGroup,"input","Title")'>language</mat-icon>
			</div>
		</div>
		<div class='remove-container'>
			<mat-icon class='icon' (click)="remove(0)">delete</mat-icon>
		</div>
	</div>
	<ng-container *ngFor="let productFormGroup of variantsControls; let i = index">
		<div class="component-container" [formGroup]="productFormGroup">
			<div style="width: 100%;">
				<div class='component'>
					<div class='draggable-icon-wrapper'></div>
					<div class='component-icon-wrapper empty'></div>
					<div class='component-input' [formGroup]="productFormGroup.controls.names">
						<mat-form-field appearance='fill'>
							<mat-label> {{placeholder.title}} {{i + 1}}
							</mat-label>
							<input matInput formControlName='English' required>
							<mat-error
								*ngIf='productFormGroup.controls.names.controls.English.touched && productFormGroup.controls.names.controls.English.invalid'>
								<span *ngIf='productFormGroup.controls.names.controls.English.errors.required'>Required field</span>
								<span *ngIf='productFormGroup.controls.names.controls.English.errors.maxlength'> Max length - 60 char</span>
							</mat-error>
						</mat-form-field>
					</div>
					<div class='option-actions'>
						<mat-icon class='icon' (click)='openMultiLanguageDialog(productFormGroup.controls.names,"input",placeholder.title)'>language</mat-icon>
						<mat-icon class='icon' *ngIf="i!== 0" (click)='remove(i)'>delete</mat-icon>
					</div>
				</div>
				<div class='component'>
					<div class='draggable-icon-wrapper'></div>
					<div class='component-icon-wrapper empty'></div>
					<div class='component-input'>
						<mat-form-field appearance='fill'>
							<mat-label> {{placeholder.option}}
							</mat-label>
							<input matInput [unmask]="'typed'" [imask]="maskConfig" formControlName="value" required>
							<mat-error
								*ngIf='productFormGroup.controls.value.touched && productFormGroup.controls.value.invalid'>
								<span *ngIf='productFormGroup.controls.value.errors.maxlength'> Max length - 60 char</span>
							</mat-error>
						</mat-form-field>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
	<span class="add-variant-label" (click)='addFormControl()'>+ Add Product</span>
	<div class="required-checkbox-container">
		<cactussoft-required-checkbox [formGroup]="formGroup" ></cactussoft-required-checkbox>
	</div>
</div>
