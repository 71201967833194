/* eslint-disable */

import { Routes } from '@angular/router';
import { AuthGuard } from '@shared/guards/auth.guard';
import { ActiveUserGuard } from '@shared/guards/active-user.guard';

export const appRoutes: Routes = [
	{
		path: 'news',
		loadChildren: () => import('../../../../libs/news/src/lib/news.module').then((m) => m.NewsModule),
		canActivate: [AuthGuard],
		data: {
			allowedRoles: ['Admin', 'ClientManager']
		}
	},
	{
		path: 'clients',
		loadChildren: () => import('../../../../libs/clients/src/lib/clients.module').then((m) => m.ClientsModule),
		canActivate: [AuthGuard],
		data: {
			allowedRoles: ['Admin']
		}
	},
	{
		path: 'buildings',
		loadChildren: () => import('../../../../libs/buildings/src/lib/buildings.module').then((m) => m.BuildingsModule),
		canActivate: [AuthGuard],
		data: {
			allowedRoles: ['Admin']
		}
	},
	{
		path: 'managers',
		loadChildren: () => import('../../../../libs/managers/src/lib/managers.module').then((m) => m.ManagersModule),
		canActivate: [AuthGuard],
		data: {
			allowedRoles: ['Admin']
		}
	},
	{
		path: 'services',
		loadChildren: () => import('../../../../libs/services/src/lib/services.module').then((m) => m.ServicesModule),
		canActivate: [AuthGuard],
		data: {
			allowedRoles: ['Admin']
		}
	},
	{
		path: 'mobile-users',
		loadChildren: () => import('../../../../libs/mobile-users/src/lib/mobile-users.module').then((m) => m.MobileUsersModule),
		canActivate: [AuthGuard],
		data: {
			allowedRoles: ['Admin', 'ClientManager']
		}
	},
	{
		path: 'suggest-news',
		loadChildren: () => import('../../../../libs/suggest-news/src/lib/suggest-news.module').then((m) => m.SuggestNewsModule),
		canActivate: [AuthGuard],
		data: {
			allowedRoles: ['Admin', 'ClientManager']
		}
	},
	{
		path: 'templates',
		loadChildren: () => import('../../../../libs/templates/src/lib/templates.module').then((m) => m.TemplatesModule),
		canActivate: [AuthGuard],
		data: {
			allowedRoles: ['Admin']
		}
	},
	{
		path: 'auth',
		loadChildren: () => import('../../src/shared/modules/auth/auth.module').then((m) => m.AuthModule),
		canActivate: [ActiveUserGuard]
	}
];
