<div>
	<p>Delivery Price</p>
	<p class='delivery-price__description'>Set the delivery price depending on total amount of order</p>
</div>
<form [formGroup]='deliveryPriceForm'>
	<div formArrayName='options'>
		<div *ngFor="let option of deliveryPriceForm.get('options')?.controls; let i = index" [formGroupName]='i'>
			<div style='display: flex;'>
				<div style='display: flex; flex-direction: column;'>
					<div>
						<mat-form-field style='width: 100%;' appearance='fill'>
							<mat-label>Title</mat-label>
							<input required matInput [formControl]='getDeliveryPriceOptionTitleControl(option)' />
						</mat-form-field>
					</div>
					<mat-error [ngStyle]="{'padding-bottom': '20px'}"
							   *ngIf='getDeliveryPriceOptionTitleControl(option).touched && getDeliveryPriceOptionTitleControl(option).invalid'>
						These field is required
					</mat-error>
					<mat-checkbox class='delivery-price__checkbox' [formControl]='option.get("showAddressField")'>Show address field</mat-checkbox>
					<div formArrayName='prices'>
						<div [formGroupName]='j' *ngFor='let price of getPrices(option); let j = index'>
							<div class='delivery-price__prices-content'>
								<mat-form-field style='width: 100%;' appearance='fill'>
									<mat-label>From</mat-label>
									<input
										type="text"
										matInput
										[imask]="maskConfig"
										[unmask]="'typed'"
										formControlName='above'>
								</mat-form-field>
								<mat-form-field style='width: 100%;' appearance='fill'>
									<mat-label>Price</mat-label>
									<input
										matInput
										type='text'
										[imask]="maskConfig"
										[unmask]="'typed'"
										formControlName='price'>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
				<div class='delivery-price__actions'>
					<div class='delivery-price__actions__content'>
						<mat-icon (click)="openDeliveryPriceMultiLanguageDialog(option, 'input', 'Title')"
								  class='delivery-price__icon'>language
						</mat-icon>
						<mat-icon (click)='deleteDeliveryPriceOption(i)' *ngIf='i !== 0' class='delivery-price__icon'>
							delete
						</mat-icon>
					</div>
					<div class='delivery-price__delete-price' *ngFor='let price of getPrices(option); let j = index'>
						<mat-icon (click)='deleteDeliveryPrice(i, j)' *ngIf='j !== 0' class='delivery-price__icon'>
							delete
						</mat-icon>
					</div>
				</div>
			</div>
			<mat-error *ngIf='option.get("hasDuplicatePrices").value'>
				<span>There are duplicate prices</span>
			</mat-error>
			<div>
				<button [disabled]='option.get("hasDuplicatePrices").value' class='delivery-price__add' (click)='addPrice(i)' mat-button>+
					Add price
				</button>
			</div>
		</div>
	</div>
</form>
<div>
	<button style='padding: 0px' class='delivery-price__add'
			(click)='addPriceFormGroup()' mat-button>+ Add delivery option
	</button>
</div>
