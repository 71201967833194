import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SnackBarService {
	public snackBarSource: Subject<string> = new Subject<string>();
	public snackBarChanged$: Observable<string> = this.snackBarSource.asObservable();

	constructor() {}

	public showSnackBar(text: string): void {
		this.snackBarSource.next(text);
	}
}
