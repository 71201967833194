import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@shared/services/auth/auth.service';
import { take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard {
	constructor(private authService: AuthService, private router: Router) {}

	public canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const isRoleHasAccess: boolean = this.authService.isRoleHasAccess(route.data.allowedRoles);
		let isLoggedIn: boolean | Promise<boolean> = false;
		this.authService
			.isLoggedIn()
			.pipe(take(1))
			.subscribe(
				(result: boolean) => {
					if (result && isRoleHasAccess) {
						isLoggedIn = true;
					} else if (result && !isRoleHasAccess) {
						this.router.navigate(['/news']);
						isLoggedIn = false;
					} else if (!result) {
						this.router.navigate(['/auth']);
						isLoggedIn = false;
					}
					// debugger;
					// isLoggedIn = result ? true : this.router.navigate(['/auth']);
					// if (isLoggedIn) {
					// 	debugger;
					// 	return isRoleHasAccess ? isRoleHasAccess :;
					// } else {
					// 	debugger;
					// 	return isLoggedIn;
					// }
				},
				() => {
					isLoggedIn = false;
				}
			);
		return isLoggedIn;
	}
}
