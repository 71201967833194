import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';

import { TableActionsInterface } from '@shared/components/table/interfaces/table-actions.interface';
import { isNullOrUndefined } from '@shared/tools/is-undefined-null';

@Component({
	selector: 'cactussoft-table-actions',
	templateUrl: './table-actions.component.html',
	styleUrls: ['./table-actions.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableActionsComponent implements OnInit {
	@Output() public clickEmitter: EventEmitter<any> = new EventEmitter<any>();
	@Input() public actions: TableActionsInterface[];
	@Input() public element: any;

	constructor() {}

	public ngOnInit(): void {
		this.initAdditionalActions();
	}

	public clickOnMenuItem(itemName: string): void {
		this.clickEmitter.emit(itemName);
	}

	private initAdditionalActions(): void {
		const additionalActions: TableActionsInterface[] = [];
		if (!isNullOrUndefined(this.element?.isInactive)) {
			additionalActions.push(
				this.element.isInactive
					? {
							label: 'Activate',
							icon: 'published_with_changes'
					  }
					: {
							label: 'Disable',
							icon: 'unpublished',
							disabledDelete: this.element.disabledDelete
					  }
			);
		}
		if (!isNullOrUndefined(this.element?.invoicePaymentEnabled)) {
			additionalActions.push(
				this.element.invoicePaymentEnabled
					? {
							label: 'Disable invoice payment',
							icon: 'local_atm'
					  }
					: {
							label: 'Enable invoice payment',
							icon: 'local_atm'
					  }
			);
		}

		const deletionIndex: number = this.actions.findIndex((item: TableActionsInterface) => item.label === 'Delete');
		if (deletionIndex !== -1) {
			const actionsCopy: TableActionsInterface[] = [...this.actions];
			this.actions = [...actionsCopy.splice(0, deletionIndex), ...additionalActions, ...actionsCopy];
		} else {
			this.actions = [...this.actions, ...additionalActions];
		}
	}
}
