import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { FilterValuesInterface } from '@shared/components/table/interfaces/filter-values.interface';
import { queryBuilder } from '@shared/tools/query-builder';

import { ServicePageDtoInterface } from '../interfaces/service-page-dto.interface';
import { AttachedServicesDtoInterface } from '../interfaces/attached-service-dto.interface';
import { CreateServiceDtoInterface } from '@cactussoft/services/interfaces/create-service-dto.interface';
import { ServiceDtoInterface } from '@cactussoft/services/interfaces/service-dto.interface';
import { AssignmentClientsDtoInterface } from '@cactussoft/clients/interfaces/assignment-client-dto.interface';
import { SuggestionInterface } from '@cactussoft/services/interfaces/suggestion.interface';
import {
	CreateIntegrationDtoInterface,
	IntegrationDtoInterface
} from '@cactussoft/services/services-board/create-integration/integration-dto.interface';

const baseUrl: string = `${JSON.parse(localStorage.getItem('env'))?.service}/administration`;

@Injectable({
	providedIn: 'root'
})
export class ClientServicesService {
	constructor(private http: HttpClient) {}

	public getServices(filterValues?: FilterValuesInterface): Observable<ServicePageDtoInterface> {
		return this.http.get<ServicePageDtoInterface>(`${baseUrl}/services${queryBuilder(filterValues)}`);
	}

	public getAllServices(): Observable<any> {
		return this.http.get<any>(`${baseUrl}/services/all`);
	}

	public editService(service: CreateServiceDtoInterface | CreateIntegrationDtoInterface, serviceId: string): Observable<any> {
		return this.http.put<any>(`${baseUrl}/services/${serviceId}`, service);
	}

	public suggestServices(suggestionData: SuggestionInterface): Observable<any> {
		return this.http.patch(`${baseUrl}/services/suggestion`, suggestionData);
	}

	public attachServices(services: AttachedServicesDtoInterface): Observable<any> {
		return this.http.patch(`${baseUrl}/services/attachment`, services);
	}

	public attachClients(clients: AssignmentClientsDtoInterface, serviceId: number): Observable<any> {
		return this.http.put(`${baseUrl}/services/${serviceId}/clients`, clients);
	}

	public getClientsByServiceId(serviceId: number): Observable<any> {
		return this.http.get(`${baseUrl}/services/${serviceId}/clients`);
	}

	public createService(service: CreateServiceDtoInterface | CreateIntegrationDtoInterface): Observable<any> {
		return this.http.post<any>(`${baseUrl}/services`, service);
	}

	public getServiceById(id: string): Observable<ServiceDtoInterface | IntegrationDtoInterface> {
		return this.http.get<ServiceDtoInterface>(`${baseUrl}/services/${id}`);
	}

	public changeServiceState(id: number, state: boolean): Observable<any> {
		return this.http.patch<any>(`${baseUrl}/services/${id}`, { isInactive: state });
	}

	public getRemovableServiceState(serviceId: string): Observable<number> {
		return this.http.get<number>(`${baseUrl}/services/${serviceId}/clients/count`);
	}

	public removeService(serviceId: string): Observable<any> {
		return this.http.delete(`${baseUrl}/services/${serviceId}`);
	}
}
