/* eslint-disable */
import { NgModule } from '@angular/core';

import { environment } from '../environments/environment.dev';
import { RouterModule } from '@angular/router';
import { appRoutes } from '../environments/app-routes';

@NgModule({
	imports: [
		RouterModule.forRoot([
			...appRoutes,
			{
				path: '',
				redirectTo: '/auth',
				pathMatch: 'full'
			}
		])
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
