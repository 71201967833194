export enum UploaderTypes {
	clients = 'clients',
	buildings = 'buildings',
	managers = 'managers',
	services = 'services',
	news = 'news',
	space = 'space',
	category = 'category',
	pageIcon = 'pageIcon',
	integration = 'integration'
}

export enum UploaderStates {
	beforeLoad = 'Select file',
	reload = 'Change photo'
}

export interface UploaderData {
	image: string | ArrayBuffer;
	title: string;
}
