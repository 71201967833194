<div class='multi-lang-dialog-container' [ngClass]="{'test': data.type === 'input'}">
	<div class='multi-lang-dialog-container__header'>
		<h2>Translations</h2>
		<mat-icon mat-dialog-close class='cancel-button'>clear</mat-icon>
	</div>

	<form [formGroup]='multiLangForm' *ngIf='multiLangForm'>
		<mat-tab-group *ngIf='data.type === "input"' mat-stretch-tabs class='example-stretched-tabs'>
			<mat-tab label='English'>
				<div class='multi-lang-dialog-form__row'>
					<mat-form-field class='' appearance='fill'>
						<mat-label> {{inputPlaceholder}}
						</mat-label>
						<input matInput formControlName='English'>
					</mat-form-field>
					<div></div>
				</div>
			</mat-tab>
			<mat-tab label='French'>
				<div class='multi-lang-dialog-form__row'>
					<mat-form-field class='' appearance='fill'>
						<mat-label> {{inputPlaceholder}}
						</mat-label>
						<input matInput formControlName='French'>
					</mat-form-field>
					<div></div>
				</div>
			</mat-tab>
			<mat-tab label='Dutch'>
				<div class='multi-lang-dialog-form__row'>
					<mat-form-field class='' appearance='fill'>
						<mat-label> {{inputPlaceholder}}
						</mat-label>
						<input matInput formControlName='Dutch'>
					</mat-form-field>
					<div></div>
				</div>
			</mat-tab>
			<mat-tab label='German'>
				<div class='multi-lang-dialog-form__row'>
					<mat-form-field class='' appearance='fill'>
						<mat-label> {{inputPlaceholder}}
						</mat-label>
						<input matInput formControlName='German'>
					</mat-form-field>
					<div></div>
				</div>
			</mat-tab>
		</mat-tab-group>
		<mat-tab-group *ngIf='data.type === "editor"' mat-stretch-tabs class='example-stretched-tabs'>
			<mat-tab label='English'>
				<ng-template matTabContent>
					<cactussoft-text-editor [initValue]='multiLangForm.controls.English.value'
											[toolbarOptions]='data.toolbarOptions'
											[placeholder]='inputPlaceholder'
											scrollingContainer='.multi-lang-dialog-container'
											(changedSource)='getText($event,"English")'></cactussoft-text-editor>
				</ng-template>
			</mat-tab>
			<mat-tab label='French'>
				<ng-template matTabContent>
				<cactussoft-text-editor [initValue]='multiLangForm.controls.French.value'
										[toolbarOptions]='data.toolbarOptions'
										[placeholder]='inputPlaceholder'
										scrollingContainer='.multi-lang-dialog-container'
										(changedSource)='getText($event,"French")'></cactussoft-text-editor>
				</ng-template>
			</mat-tab>
			<mat-tab label='Dutch'>
				<ng-template matTabContent>
				<cactussoft-text-editor [initValue]='multiLangForm.controls.Dutch.value'
										[toolbarOptions]='data.toolbarOptions'
										[placeholder]='inputPlaceholder'
										scrollingContainer='.multi-lang-dialog-container'
										(changedSource)='getText($event,"Dutch")'></cactussoft-text-editor>
				</ng-template>
			</mat-tab>
			<mat-tab label='German'>
				<ng-template matTabContent>
				<cactussoft-text-editor [initValue]='multiLangForm.controls.German.value'
										[toolbarOptions]='data.toolbarOptions'
										[placeholder]='inputPlaceholder'
										scrollingContainer='.multi-lang-dialog-container'
										(changedSource)='getText($event,"German")'></cactussoft-text-editor>
				</ng-template>
			</mat-tab>
		</mat-tab-group>
		<div class='multi-lang-dialog-form__buttons'>
			<div class='button-item secondary' mat-dialog-close>
				Cancel
			</div>
			<div class='button-item primary' (click)='emitPageValue()'>
				Save
			</div>
		</div>
	</form>
</div>
