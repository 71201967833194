import { Component, OnInit } from '@angular/core';
import { AuthService } from '@shared/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'cactussoft-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	public title: string = 'savviva-board';

	constructor(public authService: AuthService, private http: HttpClient) {}

	public ngOnInit(): void {
		this.http.get('/env.json').subscribe((r: any) => {
			localStorage.setItem('env', JSON.stringify(r));
		});
	}
}

// localStorage.setItem('env', JSON.stringify({
// 	production: 'false',
// 	identity: 'https://identity.savviva.inside.cactussoft.biz',
// 	client: 'https://client-management.savviva.inside.cactussoft.biz',
// 	newsfeed: 'https://newsfeed.savviva.inside.cactussoft.biz',
// 	service: 'https://service-management.savviva.inside.cactussoft.biz',
// 	building: 'https://building-management.savviva.inside.cactussoft.biz',
// 	fileServer: 'https://fileserver.savviva.inside.cactussoft.biz',
// 	client_secret: 'ssMcQUjJYXDsg6yfTRH5yQbGhrc7CQHsX5nFA65q',
// 	client_id: 'admin',
// 	scope: 'public-api offline_access openid admin-api'
// }));
