import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'cactussoft-status-cell',
	templateUrl: './status-cell.component.html',
	styleUrls: ['./status-cell.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusCellComponent implements OnInit {
	@Input() public value: string;
	@Input() public flag: any;

	public isNumberFlag: boolean;

	constructor() {}

	public ngOnInit(): void {
		this.isNumberFlag = Number(this.flag) === this.flag;
	}
}
