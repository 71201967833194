import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { FilterValuesInterface } from '@shared/components/table/interfaces/filter-values.interface';
import { queryBuilder } from '@shared/tools/query-builder';

import { UsersPageInterface } from '../interfaces/users-page.interface';
import { UserInterface } from '../interfaces/user.interface';
import { environment } from '../../../../../apps/savviva-board/src/environments/environment';

const baseUrl: string = `${JSON.parse(localStorage.getItem('env'))?.identity}/common-users`;

@Injectable({
	providedIn: 'root'
})
export class UsersService {
	constructor(private http: HttpClient) {}

	public getUsers(filterValues?: FilterValuesInterface): Observable<UsersPageInterface> {
		return this.http.get<UsersPageInterface>(`${baseUrl}${queryBuilder(filterValues)}`);
	}

	public getUserById(id: number): Observable<UserInterface> {
		return this.http.get<UserInterface>(`${baseUrl}/${id}`);
	}

	public changeUserState(id: number, state: boolean): Observable<any> {
		return this.http.patch<any>(`${baseUrl}/${id}`, { isInactive: state });
	}

	public deleteUser(userId: number): Observable<any> {
		return this.http.delete<any>(`${JSON.parse(<string>localStorage.getItem('env'))?.identity}/users/${userId}`);
	}

	public exportToExcel(): Observable<any> {
		return this.http.get<Blob>(`${baseUrl}/export`, { responseType: 'blob' as 'json' });
	}

	public changeStateInvoicePayment(id: number, state: boolean): Observable<never> {
		return this.http.patch<never>(`${baseUrl}/${id}/enableInvoice`, { isEnabled: state });
	}
}
