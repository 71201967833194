import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { FilterValuesInterface } from '@shared/components/table/interfaces/filter-values.interface';
import { queryBuilder } from '@shared/tools/query-builder';

import { CreateNewsDtoInterface } from '@cactussoft/news/interfaces/create-news-dto.interface';
import { NewsPageDtoInterface } from '@cactussoft/news/interfaces/news-page-dto.interface';
import { NewsDtoInterface } from '@cactussoft/news/interfaces/news-dto.interface';
import { AssignmentClientsDtoInterface } from '@cactussoft/clients/interfaces/assignment-client-dto.interface';

const baseUrl: string = `${JSON.parse(localStorage.getItem('env'))?.newsfeed}/administration/newsfeeds`;

@Injectable({
	providedIn: 'root'
})
export class NewsService {
	public baseUrl: string = '';
	constructor(private http: HttpClient) {}

	public getNews(filterValues?: FilterValuesInterface): Observable<NewsPageDtoInterface> {
		return this.http.get<NewsPageDtoInterface>(
			`${JSON.parse(localStorage.getItem('env'))?.newsfeed}/administration/newsfeeds/${queryBuilder(filterValues)}`
		);
	}

	public createNews(news: CreateNewsDtoInterface): Observable<any> {
		return this.http.post(`${baseUrl}`, news);
	}

	public editNews(news: CreateNewsDtoInterface, id: string): Observable<any> {
		return this.http.put<any>(`${baseUrl}/${id}`, news);
	}

	public getNewsById(id: string): Observable<NewsDtoInterface> {
		return this.http.get<NewsDtoInterface>(`${baseUrl}/${id}`);
	}

	public changeNewsState(id: number, state: boolean): Observable<any> {
		return this.http.patch<any>(`${baseUrl}/${id}`, { isInactive: state });
	}

	public attachClients(clients: AssignmentClientsDtoInterface, id: number): Observable<any> {
		return this.http.put(`${baseUrl}/${id}/clients`, clients);
	}

	public deleteNews(id: string): Observable<number> {
		return this.http.delete<number>(`${baseUrl}/${id}`);
	}
}
