import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Injectable,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

import { BehaviorSubject, forkJoin } from 'rxjs';

import { isNullOrUndefined } from '@shared/tools/is-undefined-null';
import { TreeDialogInitialValuesInterface } from '@shared/components/tree/tree-dialog-initial-values.interface';

import { ClientsService } from '@cactussoft/clients/services/clients.service';
import { ClientServicesService } from '@cactussoft/services/services/client-services.service';
import { ManagersService } from '@cactussoft/managers/services/managers.service';
import { ClientBuildingsInterface, ClientDtoInterface } from '@cactussoft/clients/interfaces/client-dto.interface';
import { ManagerDtoInterface } from '@cactussoft/managers/interfaces/manager-dto.interface';
import {
	AssignmentClientDtoInterface,
	AssignmentClientsDtoInterface
} from '@cactussoft/clients/interfaces/assignment-client-dto.interface';
import { CLIENT_TYPES } from '@cactussoft/clients/clients-board/constants/client-types.constants';
import { ServiceDtoInterface } from '@cactussoft/services/interfaces/service-dto.interface';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { NewsService } from '@cactussoft/news/services/news.service';
import { NewsDtoInterface } from '@cactussoft/news/interfaces/news-dto.interface';
import { SnackBarService } from '@shared/services/snack-bar/snack-bar.service';

export class TreeItemNode {
	public children: TreeItemNode[];
	public item: {
		name: string;
		id: string;
		buildingsCount: number;
		type: string;
		parentId: string;
		isInactive: string;
	};
}

export class TreeItemFlatNode {
	public item: {
		name: string;
		id: string;
		parentId: string;
	};
	public level: number;
	public expandable: boolean;
}

@Injectable()
export class ChecklistDatabase {
	public dataChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	public dialogDataSource: BehaviorSubject<TreeDialogInitialValuesInterface> = new BehaviorSubject<TreeDialogInitialValuesInterface>(
		null
	);

	get data(): TreeItemNode[] {
		return this.dataChange.value;
	}

	constructor(
		private cdr: ChangeDetectorRef,
		private clientsService: ClientsService,
		private servicesService: ClientServicesService,
		private managersService: ManagersService,
		private newsService: NewsService,
		private snackBarService: SnackBarService
	) {
		this.dialogDataSource.subscribe((dialogData: TreeDialogInitialValuesInterface) => {
			if (!isNullOrUndefined(dialogData)) {
				switch (dialogData.type) {
					case 'manager-expandable': {
						this.initManagerAssignDialog(dialogData);
						break;
					}
					case 'service-expandable': {
						this.initServiceAssignDialog(dialogData);
						break;
					}
					case 'news-expandable': {
						this.initNewsAssignDialog(dialogData);
					}
				}
			}
		});
	}

	private initManagerAssignDialog(dialogData: TreeDialogInitialValuesInterface): void {
		forkJoin([this.clientsService.getAllClients(), this.managersService.getManagerById(String(dialogData.id))]).subscribe(
			([clients, currentManager]: [ClientDtoInterface[], ManagerDtoInterface]) => {
				this.initialize(this.modifyInitClients(clients), currentManager.clients);
			},
			() => this.snackBarService.showSnackBar('Unknown error')
		);
	}

	private initServiceAssignDialog(dialogData: TreeDialogInitialValuesInterface): void {
		forkJoin([this.clientsService.getAllClients(), this.servicesService.getServiceById(String(dialogData.id))]).subscribe(
			([clients, currentService]: [ClientDtoInterface[], ServiceDtoInterface]) => {
				this.initialize(this.modifyInitClients(clients), currentService.clients);
			},
			() => this.snackBarService.showSnackBar('Unknown error')
		);
	}

	private initNewsAssignDialog(dialogData: TreeDialogInitialValuesInterface): void {
		if (isNullOrUndefined(dialogData.id)) {
			this.clientsService.getAllClients().subscribe(
				(clients: ClientDtoInterface[]) => {
					this.initialize(this.modifyInitClients(clients), []);
				},
				() => this.snackBarService.showSnackBar('Unknown error')
			);
		} else {
			forkJoin([this.clientsService.getAllClients(), this.newsService.getNewsById(String(dialogData.id))]).subscribe(
				([clients, currentNews]: [ClientDtoInterface[], NewsDtoInterface]) => {
					this.initialize(this.modifyInitClients(clients), currentNews.clients);
				},
				() => this.snackBarService.showSnackBar('Unknown error')
			);
		}
	}

	private modifyInitClients(clients: ClientDtoInterface[]): any {
		return clients.map((client: ClientDtoInterface) => {
			return {
				id: client.id,
				name: client.name,
				type: CLIENT_TYPES[client.clientType],
				buildingsCount: client.buildings.length,
				isInactive: client.isInactive,
				buildings: client.buildings.map((building: ClientBuildingsInterface) => {
					return {
						id: building.id,
						name: building.name
					};
				})
			};
		});
	}

	private initialize(listOfClients: any, selectedClients: AssignmentClientDtoInterface[]): void {
		const data: TreeItemNode[] = this.buildFileTree(listOfClients, 0);
		this.dataChange.next({ data: data, selected: selectedClients });
	}

	private buildFileTree(obj: { [key: string]: any }, level: number, parentId?: string): TreeItemNode[] {
		return Object.keys(obj).reduce<TreeItemNode[]>((accumulator: TreeItemNode[], key: string) => {
			const value: any = obj[key];
			const node: TreeItemNode = new TreeItemNode();
			node.item = {
				id: value.id,
				name: value.name,
				parentId: parentId,
				type: value.type,
				buildingsCount: value.buildingsCount,
				isInactive: value.isInactive
			};
			if (value != null) {
				if (typeof value === 'object' && value.buildings !== undefined) {
					node.children = this.buildFileTree(value.buildings, level + 1, value.id);
				} else {
					node.item = {
						id: value.id,
						name: value.name,
						parentId: parentId,
						type: value.type,
						buildingsCount: value.buildingsCount,
						isInactive: value.isInactive
					};
				}
			}

			return accumulator.concat(node);
		}, []);
	}
}

@Component({
	selector: 'cactussoft-tree-checklist',
	templateUrl: 'tree.component.html',
	styleUrls: ['tree.component.scss'],
	providers: [ChecklistDatabase],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeChecklistComponent implements OnInit, OnChanges {
	@Input() public dialogData: TreeDialogInitialValuesInterface;
	@Input() public treeFilterValue: string;
	@Output() public selectedClientsSource: EventEmitter<AssignmentClientsDtoInterface> = new EventEmitter<AssignmentClientsDtoInterface>();
	@Output() public closeDialogSource: EventEmitter<object> = new EventEmitter<object>();
	@ViewChild(MatPaginator) public paginator: MatPaginator;

	public pageSize: number;
	public selectAllState: boolean = false;
	public treeControl: FlatTreeControl<TreeItemFlatNode>;
	public dataSource: MatTreeFlatDataSource<TreeItemNode, TreeItemFlatNode>;
	public checklistSelection: SelectionModel<TreeItemFlatNode> = new SelectionModel<TreeItemFlatNode>(true);
	public initialDataSource: any;

	private flatNodeMap: Map<TreeItemFlatNode, TreeItemNode> = new Map<TreeItemFlatNode, TreeItemNode>();
	private nestedNodeMap: Map<TreeItemNode, TreeItemFlatNode> = new Map<TreeItemNode, TreeItemFlatNode>();
	private treeFlattener: MatTreeFlattener<TreeItemNode, TreeItemFlatNode>;

	constructor(private cdr: ChangeDetectorRef, private _database: ChecklistDatabase) {
		this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
		this.treeControl = new FlatTreeControl<TreeItemFlatNode>(this.getLevel, this.isExpandable);
		this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
		_database.dataChange.subscribe((data: { data: TreeItemNode[]; selected: any }) => {
			this.dataSource.data = isNullOrUndefined(data) ? [] : data.data;
			this.initialDataSource = this.dataSource.data;
			const selectedNodes: TreeItemFlatNode[] = [];
			if (!isNullOrUndefined(data)) {
				[...this.nestedNodeMap.values()].forEach((treeNode: TreeItemFlatNode) => {
					data.selected.forEach((selectedClient: AssignmentClientDtoInterface) => {
						if (treeNode.item.parentId === selectedClient.id) {
							selectedClient.buildingIds.forEach((id: string) => {
								if (treeNode.item.id === id) {
									selectedNodes.push(treeNode);
								}
							});
						}
					});
				});
			}
			selectedNodes.forEach((node: TreeItemFlatNode) => {
				this.todoItemSelectionToggle(node);
			});
			this.isSelectAll();
			this.cdr.markForCheck();
		});
	}

	public selectAll(flag: boolean): void {
		[...this.nestedNodeMap.values()].forEach((treeNode: TreeItemFlatNode) => {
			flag ? this.todoLeafItemSelect(treeNode) : this.todoLeafItemDeselect(treeNode);
		});
	}

	public isSelectAll(): boolean {
		const nodesStates: boolean[] = [];
		const uniqueNodesStates: Set<boolean> = new Set();
		[...this.nestedNodeMap.values()].forEach((treeNode: TreeItemFlatNode, index: number) => {
			if (this.hasChild(index, treeNode)) {
				nodesStates.push(this.descendantsAllSelected(treeNode) || this.descendantsPartiallySelected(treeNode));
			}
		});
		nodesStates.forEach((state: boolean) => {
			uniqueNodesStates.add(state);
		});
		const isIndeterminate: boolean = uniqueNodesStates.size === 1 || uniqueNodesStates.size === 0;
		if (uniqueNodesStates.size === 1) {
			this.selectAllState = uniqueNodesStates.has(true);
		}
		return !isIndeterminate;
	}

	public ngOnInit(): void {
		this._database.dialogDataSource.next(this.dialogData);
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes['treeFilterValue']) {
			this.dataSource.data = Boolean(changes.treeFilterValue.currentValue)
				? [...this.initialDataSource].filter((node: any) => {
						return node.item.name.toLocaleLowerCase().includes(changes.treeFilterValue.currentValue.toLocaleLowerCase());
				  })
				: [...this.initialDataSource];
		}
	}

	public closeDialog(): void {
		this.closeDialogSource.emit({});
	}

	public sendData(): void {
		const selectedClients: AssignmentClientsDtoInterface = {
			clients: []
		};
		this.checklistSelection.selected.forEach((node: TreeItemFlatNode) => {
			if (node.level === 1) {
				const index: number = selectedClients.clients.findIndex((n: AssignmentClientDtoInterface) => n.id === node.item.parentId);
				index !== -1
					? selectedClients.clients[index].buildingIds.push(node.item.id)
					: selectedClients.clients.push({
							id: node.item.parentId,
							buildingIds: [node.item.id]
					  });
			}
		});
		this.selectedClientsSource.emit(selectedClients);
	}

	public descendantsAllSelected(node: TreeItemFlatNode): boolean {
		const descendants: TreeItemFlatNode[] = this.treeControl.getDescendants(node);
		const descAllSelected: boolean =
			descendants.length > 0 &&
			descendants.every((child: TreeItemFlatNode) => {
				return this.checklistSelection.isSelected(child);
			});
		return descAllSelected;
	}

	public descendantsPartiallySelected(node: TreeItemFlatNode): boolean {
		const descendants: TreeItemFlatNode[] = this.treeControl.getDescendants(node);
		const result: boolean = descendants.some((child: TreeItemFlatNode) => this.checklistSelection.isSelected(child));
		return result && !this.descendantsAllSelected(node);
	}

	public todoItemSelectionToggle(node: TreeItemFlatNode): void {
		this.checklistSelection.toggle(node);
		const descendants: TreeItemFlatNode[] = this.treeControl.getDescendants(node);
		this.checklistSelection.isSelected(node)
			? this.checklistSelection.select(...descendants)
			: this.checklistSelection.deselect(...descendants);
		// Force update for the parent
		descendants.forEach((child: TreeItemFlatNode) => this.checklistSelection.isSelected(child));
		this.checkAllParentsSelection(node);
	}

	public todoLeafItemSelect(node: TreeItemFlatNode): void {
		this.checklistSelection.select(node);
		this.checkAllParentsSelection(node);
	}

	public todoLeafItemDeselect(node: TreeItemFlatNode): void {
		this.checklistSelection.deselect(node);
		this.checkAllParentsSelection(node);
	}

	public todoLeafItemSelectionToggle(node: TreeItemFlatNode): void {
		this.checklistSelection.toggle(node);
		this.checkAllParentsSelection(node);
	}

	public hasChild = (_: number, _nodeData: TreeItemFlatNode) => _nodeData.expandable;

	private getLevel = (node: TreeItemFlatNode) => node.level;

	private isExpandable = (node: TreeItemFlatNode) => node.expandable;

	private getChildren = (node: TreeItemNode): TreeItemNode[] => node.children;

	private transformer = (node: TreeItemNode, level: number) => {
		const existingNode: TreeItemFlatNode = this.nestedNodeMap.get(node);
		const flatNode: TreeItemFlatNode = existingNode && existingNode.item === node.item ? existingNode : new TreeItemFlatNode();
		flatNode.item = node.item;
		flatNode.level = level;
		flatNode.expandable = !isNullOrUndefined(node.children?.length);
		this.flatNodeMap.set(flatNode, node);
		this.nestedNodeMap.set(node, flatNode);
		return flatNode;
	};

	private checkAllParentsSelection(node: TreeItemFlatNode): void {
		let parent: TreeItemFlatNode | null = this.getParentNode(node);
		while (parent) {
			this.checkRootNodeSelection(parent);
			parent = this.getParentNode(parent);
		}
	}

	private checkRootNodeSelection(node: TreeItemFlatNode): void {
		const nodeSelected: boolean = this.checklistSelection.isSelected(node);
		const descendants: TreeItemFlatNode[] = this.treeControl.getDescendants(node);
		const descAllSelected: boolean =
			descendants.length > 0 &&
			descendants.every((child: TreeItemFlatNode) => {
				return this.checklistSelection.isSelected(child);
			});
		if (nodeSelected && !descAllSelected) {
			this.checklistSelection.deselect(node);
		} else if (!nodeSelected && descAllSelected) {
			this.checklistSelection.select(node);
		}
	}

	private getParentNode(node: TreeItemFlatNode): TreeItemFlatNode | null {
		const currentLevel: number = this.getLevel(node);

		if (currentLevel < 1) {
			return null;
		}

		const startIndex: number = this.treeControl.dataNodes.indexOf(node) - 1;
		for (let i: number = startIndex; i >= 0; i--) {
			const currentNode: TreeItemFlatNode = this.treeControl.dataNodes[i];

			if (this.getLevel(currentNode) < currentLevel) {
				return currentNode;
			}
		}
		return null;
	}
}
