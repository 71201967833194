import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { isNullOrUndefined } from '@shared/tools/is-undefined-null';

@Component({
	selector: 'cactussoft-multi-language-dialog',
	templateUrl: './multi-language-dialog.component.html',
	styleUrls: ['./multi-language-dialog.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiLanguageDialogComponent {
	@Output() public dialogSource: EventEmitter<any> = new EventEmitter<any>();
	@Output() public dialogPreviewSource: EventEmitter<any> = new EventEmitter<any>();

	public multiLangForm: UntypedFormGroup;
	public multiLangPreviewForm: UntypedFormGroup;
	public inputPlaceholder: string;

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: UntypedFormBuilder) {
		this.inputPlaceholder = data.placeholder;
		!isNullOrUndefined(data) ? this.createFormWithValues(data) : this.createDefaultForm();
	}

	public emitPageValue(): void {
		this.dialogSource.emit(this.multiLangForm.value);
		if (this.data.isPreview) {
			this.dialogPreviewSource.emit(this.multiLangPreviewForm.value);
		}
	}

	public getText(content: { richText: string; text: string }, controlName: string): void {
		this.multiLangForm.controls[controlName].setValue(content.richText);
		if (this.data.isPreview) {
			this.multiLangPreviewForm.controls[controlName].setValue(content.text);
		}
	}

	private createDefaultForm(): void {
		this.multiLangForm = this.formBuilder.group({
			English: new UntypedFormControl(null, Validators.required),
			Dutch: new UntypedFormControl(null),
			French: new UntypedFormControl(null),
			German: new UntypedFormControl(null)
		});
		if (this.data.isPreview) {
			this.multiLangPreviewForm = this.formBuilder.group({
				English: new UntypedFormControl(null, Validators.required),
				Dutch: new UntypedFormControl(null),
				French: new UntypedFormControl(null),
				German: new UntypedFormControl(null)
			});
		}
	}

	private createFormWithValues(dialogData: any): void {
		this.multiLangForm = this.formBuilder.group({
			English: new UntypedFormControl(dialogData.value.English, Validators.required),
			Dutch: new UntypedFormControl(dialogData.value.Dutch),
			French: new UntypedFormControl(dialogData.value.French),
			German: new UntypedFormControl(dialogData.value.German)
		});
		if (this.data.isPreview) {
			this.multiLangPreviewForm = this.formBuilder.group({
				English: new UntypedFormControl(dialogData.previewValue.English),
				Dutch: new UntypedFormControl(dialogData.previewValue.Dutch),
				French: new UntypedFormControl(dialogData.previewValue.French),
				German: new UntypedFormControl(dialogData.previewValue.German)
			});
		}
	}
}
