import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FilterSelectInterface } from '@shared/interfaces/filter-select.interface';
import { SnackBarService } from '@shared/services/snack-bar/snack-bar.service';

@Component({
	selector: 'cactussoft-board-filter',
	templateUrl: './board-filter.component.html',
	styleUrls: ['./board-filter.component.scss']
})
export class BoardFilterComponent implements OnInit {
	@Input() public filters: any;
	@Output() public changeValuesSource: EventEmitter<FilterSelectInterface> = new EventEmitter<FilterSelectInterface>();

	public formGroup: UntypedFormGroup;
	private formSubscription: Subscription;

	constructor(private formBuilder: UntypedFormBuilder, private snackBarService: SnackBarService) {}

	public ngOnInit(): void {
		this.createForm();
		this.formSubscription = this.formGroup.valueChanges.subscribe(
			(value: any) => {
				this.changeValuesSource.emit(value);
			},
			() => this.snackBarService.showSnackBar('Unknown error')
		);
	}

	public addFormControl(filterItem?: any): void {
		const filtersArray: UntypedFormArray = this.formGroup.controls.filters as UntypedFormArray;

		const newFilterGroup: UntypedFormGroup = this.formBuilder.group({
			values: new UntypedFormControl(filterItem.values),
			type: new UntypedFormControl(filterItem.selectionType),
			uriPart: new UntypedFormControl(filterItem.uriPart),
			placeholder: new UntypedFormControl(filterItem.placeholder),
			selectedValue: new UntypedFormControl(null)
		});
		filtersArray.insert(filtersArray.length, newFilterGroup);
	}

	private createForm(): void {
		this.formGroup = this.formBuilder.group({
			filters: this.formBuilder.array([])
		});
		this.filters.forEach((filterItem: any) => {
			this.addFormControl(filterItem);
		});
	}

	get filtersControls(): any {
		return (this.formGroup.controls.filters as UntypedFormArray).controls;
	}
}
