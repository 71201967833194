import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AuthService } from '@shared/services/auth/auth.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SnackBarService } from '@shared/services/snack-bar/snack-bar.service';
import { isNullOrUndefined } from '@shared/tools/is-undefined-null';

@Component({
	selector: 'cactussoft-register-admin',
	templateUrl: './register-admin.component.html',
	styleUrls: ['./register-admin.component.scss']
})
export class RegisterAdminComponent implements OnInit {
	public formGroup: UntypedFormGroup;
	public isHiddenPassword: boolean = true;
	public email: string = '';
	private code: string;

	constructor(
		private authService: AuthService,
		private formBuilder: UntypedFormBuilder,
		private cdr: ChangeDetectorRef,
		private router: Router,
		private _activatedRoute: ActivatedRoute,
		private snackBarService: SnackBarService
	) {}

	public ngOnInit(): void {
		this._activatedRoute.queryParams.subscribe((params: Params) => {
			this.email = params.email.replace(' ', '+');
			this.code = params.uniqueCode;
			this.createDefaultForm();
		});
	}

	public confirmPassword(): void {
		if (this.isPasswordsMatch()) {
			this.authService.confirmPassword(this.email, this.code, this.formGroup.controls.password.value).subscribe(
				() => {
					this.snackBarService.showSnackBar('Email confirmation passed');
					this.router.navigate(['auth']);
				},
				() => {
					this.snackBarService.showSnackBar('Email confirmation failed');
				}
			);
		}
	}

	private createDefaultForm(): void {
		this.formGroup = this.formBuilder.group({
			password: new UntypedFormControl(null),
			confirmPassword: new UntypedFormControl(null)
		});
	}

	private isPasswordsMatch(): boolean {
		const err: any =
			this.formGroup.controls.password.value === this.formGroup.controls.confirmPassword.value ? null : { passwordMismatch: true };
		this.formGroup.controls.confirmPassword.setErrors(err);
		return isNullOrUndefined(err);
	}
}
