import { FilterSelectInterface } from '@shared/interfaces/filter-select.interface';

export interface FilterValuesInterface {
	sortField?: string;
	sortDirection?: string;
	nextPageIndex?: number;
	search?: string;
	filters?: FilterSelectInterface[];
}

export enum SORT_DIRECTION {
	asc,
	desc
}
