<div class='suggest-news-dialog-container'>
	<mat-icon mat-dialog-close class='cancel-button'>clear</mat-icon>
	<span class='suggest-news-dialog-container__header'>News</span>
	<div class='suggest-news-info'>
		<div class='suggest-news-field-container'>
			<span class='suggest-news-field-container__header'>Creator</span>
			<span class='suggest-news-field-container__description'>{{news?.createdBy.fullName}}</span>
		</div>
		<div class='suggest-news-field-container'>
			<span class='suggest-news-field-container__header'>Title</span>
			<span class='suggest-news-field-container__description'>{{news?.title}}</span>
		</div>
		<div class='suggest-news-field-container'>
			<span class='suggest-news-field-container__header'>Description</span>
			<span class='suggest-news-field-container__description' [innerText]='news?.description'></span>
		</div>
	</div>

	<div class='suggest-news-dialog-container__actions'>
		<div class='suggest-news-dialog-container__actions__button cancel' mat-dialog-close>
			Cancel
		</div>
		<div style='display: flex;'>
			<div class='suggest-news-dialog-container__actions__button disable'
				 (click)='changeSuggestNewsState(2)'>
				Reject
			</div>
			<div class='suggest-news-dialog-container__actions__button active'
				 (click)='changeSuggestNewsState(1)'>
				Approve
			</div>
		</div>
	</div>
</div>
