import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DeliveryCost, DeliveryPriceFormOption, ServiceDtoInterface } from '@cactussoft/services/interfaces/service-dto.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DeliveryPriceService } from './delivery-price.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MultiLanguageDialogComponent } from '@shared/components/multi-language-dialog/multi-language-dialog.component';
import { FormValidationService } from '@shared/services/update-validity/update-validity.service';
import { priceMaskConfig } from '@shared/tools/price-mask';
import { FactoryArg } from 'imask';

@Component({
	selector: 'cactussoft-delivery-price',
	templateUrl: './delivery-price.component.html',
	styleUrls: ['./delivery-price.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryPriceComponent implements OnInit, OnDestroy {
	@Input() public service: ServiceDtoInterface = null;

	public deliveryPriceForm: UntypedFormGroup;
	public maskConfig: FactoryArg = priceMaskConfig();
	private destroy$: Subject<void> = new Subject();

	constructor(
		private updateValidationService: FormValidationService,
		private formBuilder: UntypedFormBuilder,
		private deliveryPriceService: DeliveryPriceService,
		private matDialog: MatDialog,
		private cdr: ChangeDetectorRef
	) {}

	public ngOnInit(): void {
		this.createDeliveryPriceForm();
		this.sendingDeliveryPriceValues();
		this.updateValidationService.checkValidation$.subscribe(() => {
			this.updateValidationService.validateAllFormFields(this.deliveryPriceForm);
			this.cdr.detectChanges();
		});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.deliveryPriceService.deliveryPriceValues = null;
	}

	public getPrices(form: UntypedFormGroup): AbstractControl {
		return form.controls.prices['controls'];
	}

	public addPriceFormGroup(): void {
		const options: UntypedFormArray = this.deliveryPriceForm.get('options') as UntypedFormArray;
		options.push(this.addDeliveryPriceFormGroup());
	}

	public openDeliveryPriceMultiLanguageDialog(inputFormGroup: any, type: string, placeholder: string): void {
		const dialogRef: MatDialogRef<MultiLanguageDialogComponent> = this.matDialog.open(MultiLanguageDialogComponent, {
			data: { value: inputFormGroup.value, type: type, placeholder: placeholder },
			width: '800px',
			height: 'auto'
		});
		dialogRef.componentInstance.dialogSource.subscribe((data: any) => {
			inputFormGroup.patchValue({
				titles: data
			});
			inputFormGroup.markAllAsTouched();
			dialogRef.close();
		});
	}

	public addPrice(i: number): void {
		const control: UntypedFormArray = <UntypedFormArray>this.deliveryPriceForm.get('options')['controls'][i].get('prices');
		control.push(this.createPriceFormGroup({ above: 0, cost: 0 }, control.length));
	}

	public getDeliveryPriceOptionTitleControl(option: AbstractControl): AbstractControl {
		return option['controls'].titles.controls.English;
	}

	public deleteDeliveryPriceOption(index: number): void {
		const options: UntypedFormArray = this.deliveryPriceForm.get('options') as UntypedFormArray;
		options.removeAt(index);
	}

	public deleteDeliveryPrice(optionIndex: number, priceIndex: number): void {
		const control: UntypedFormArray = <UntypedFormArray>this.deliveryPriceForm.get('options')['controls'][optionIndex].get('prices');
		control.removeAt(priceIndex);
	}

	private addDeliveryPriceFormGroup(deliveryCost?: DeliveryCost): UntypedFormGroup {
		const formGroup: UntypedFormGroup = new UntypedFormGroup({
			titles: this.formBuilder.group({
				English: new UntypedFormControl(deliveryCost ? deliveryCost.titles.English : '', [Validators.required]),
				French: new UntypedFormControl(deliveryCost ? deliveryCost.titles.French : ''),
				Dutch: new UntypedFormControl(deliveryCost ? deliveryCost.titles.Dutch : ''),
				German: new UntypedFormControl(deliveryCost ? deliveryCost.titles.German : '')
			}),
			showAddressField: new UntypedFormControl(deliveryCost ? deliveryCost.isVisibleAddress : false),
			prices: new UntypedFormArray(
				deliveryCost
					? deliveryCost.ranges.map((range: { above: number; cost: number }, index: number) =>
							this.createPriceFormGroup(range, index)
					  )
					: [this.createPriceFormGroup({ above: 0, cost: 0 })]
			),
			hasDuplicatePrices: new UntypedFormControl(false)
		});

		formGroup
			.get('prices')
			.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe((_options: Array<{ price: number; above: number }>) => {
				const uniqPrices: Set<number> = new Set(_options.map((_option: { price: number; above: number }) => _option.price));
				const uniqAmount: Set<number> = new Set(
					_options.map((_option: { price: number; above: number }) => (_option?.above ? _option?.above : 0))
				);
				formGroup.get('hasDuplicatePrices').patchValue(uniqPrices.size !== _options.length || uniqAmount.size !== _options.length);
			});
		return formGroup;
	}

	private createPriceFormGroup(range: { above: number; cost: number }, index: number = 0): UntypedFormGroup {
		return new UntypedFormGroup({
			above: new UntypedFormControl(
				{
					value: range.above,
					disabled: index === 0
				},
				index > 0 ? [Validators.min(1)] : null
			),
			price: new UntypedFormControl(range.cost, [])
		});
	}

	private composeDeliveryPrice(): DeliveryCost[] {
		const values: DeliveryCost[] = this.deliveryPriceForm.getRawValue().options.map((option: DeliveryPriceFormOption) => {
			return {
				titles: option.titles,
				isVisibleAddress: !!option.showAddressField,
				ranges: option.prices.map((i: { above: number; price: number }) => {
					return {
						above: Number(i.above),
						cost: Number(i.price)
					};
				})
			};
		});
		return values;
	}

	private createDeliveryPriceForm(): void {
		if (this.service?.deliveryCosts) {
			this.deliveryPriceForm = this.formBuilder.group({
				options: this.formBuilder.array([])
			});
			const options: UntypedFormArray = this.deliveryPriceForm.get('options') as UntypedFormArray;
			this.service.deliveryCosts.forEach((deliveryCost: DeliveryCost) => {
				options.push(this.addDeliveryPriceFormGroup(deliveryCost));
			});
		} else {
			this.deliveryPriceForm = this.formBuilder.group({
				options: this.formBuilder.array([this.addDeliveryPriceFormGroup()])
			});
		}
	}

	private sendingDeliveryPriceValues(): void {
		this.deliveryPriceService.isDeliveryPriceValuesNeed.subscribe((isNeed: boolean) => {
			if (isNeed) {
				this.deliveryPriceService.deliveryPriceValues = this.composeDeliveryPrice();
				this.deliveryPriceService.isDeliveryValid = this.deliveryPriceForm.valid;
			}
		});
	}
}
