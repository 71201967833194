/* eslint-disable no-magic-numbers */
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from '@shared/tools/is-undefined-null';
import { FileService } from '@shared/services/file/file.service';
import { FormValidationService } from '@shared/services/update-validity/update-validity.service';
import { SnackBarService } from '@shared/services/snack-bar/snack-bar.service';

@Component({
	selector: 'cactussoft-gallery-builder',
	templateUrl: './gallery-builder.component.html',
	styleUrls: ['./gallery-builder.component.scss']
})
export class GalleryBuilderComponent implements OnInit {
	@Input() public iconName: string;
	@Input() public componentName: string;
	@Input() public initialValues: any;

	@Output() public removeComponentSource: EventEmitter<object> = new EventEmitter<object>();
	@Output() public changeValuesSource: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild('fileInput', { static: false }) public fileInput: ElementRef;

	public formGroup: UntypedFormGroup;
	public isUploading: boolean = false;
	public file: File;
	public uploaderId: string = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

	private formSubscription: Subscription;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private cdr: ChangeDetectorRef,
		private updateValidationService: FormValidationService,
		private fileService: FileService,
		private snackBarService: SnackBarService
	) {}

	public ngOnInit(): void {
		isNullOrUndefined(this.initialValues) ? this.createDefaultForm() : this.createFormWithValues();
		this.formSubscription = this.formGroup.valueChanges.subscribe(
			(value: any) => {
				this.changeValuesSource.emit(value);
			},
			() => this.snackBarService.showSnackBar('Unknown error')
		);
		this.updateValidationService.checkValidation$.subscribe(
			() => {
				this.updateValidationService.validateAllFormFields(this.formGroup);
				this.cdr.detectChanges();
			},
			() => this.snackBarService.showSnackBar('Unknown error')
		);
	}

	public uploadFile(file: File): void {
		if (file) {
			if (file.size > Math.pow(2, 20) * 5) {
				this.snackBarService.showSnackBar(
					'The image size is too big. Please reduce the image height, width, resolution, scaling or output type.'
				);
				return;
			}

			const fd: FormData = new FormData();
			this.file = file;

			const reader: FileReader = new FileReader();
			reader.readAsDataURL(file);

			reader.onload = (event: ProgressEvent<FileReader>) => {
				this.isUploading = true;
				fd.append('file', this.file, this.file.name);
				this.uploadFileToServer(fd);
				this.cdr.detectChanges();
			};
		}
	}

	public removeComponent(): void {
		this.removeComponentSource.emit({});
	}

	public addFormControl(variant?: any): void {
		const galleryArray: UntypedFormArray = this.formGroup.controls.variants as UntypedFormArray;
		const newImageGroup: UntypedFormGroup = isNullOrUndefined(variant)
			? this.formBuilder.group({
					pictureUrl: ['', Validators.required],
					id: null
			  })
			: this.formBuilder.group({
					pictureUrl: [variant.pictureUrl, Validators.required],
					id: variant.id
			  });
		galleryArray.insert(galleryArray.length, newImageGroup);
	}

	public removeFormControl(i: number): void {
		const productsArray: UntypedFormArray = this.formGroup.controls.variants as UntypedFormArray;
		productsArray.removeAt(i);
	}

	private uploadFileToServer(fd: FormData): void {
		this.fileService.uploadFile(fd).subscribe((res: any) => {
			this.addFormControl({ pictureUrl: res.fileUrl });
			this.isUploading = false;
		});
	}

	private createDefaultForm(): void {
		this.formGroup = this.formBuilder.group({
			id: null,
			variants: this.formBuilder.array([])
		});
		// TODO: Set empty arrays for editing template (compose template object)
		this.changeValuesSource.emit(this.formGroup.value);
	}

	private createFormWithValues(): void {
		this.formGroup = this.formBuilder.group({
			id: this.initialValues.id,
			variants: this.formBuilder.array([])
		});
		this.initialValues.variants.forEach((option: any) => {
			this.addFormControl(option);
		});
	}

	get variantsControls(): any {
		return (this.formGroup.controls.variants as UntypedFormArray).controls;
	}
}
