/* eslint-disable no-magic-numbers */
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewEncapsulation
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import { DraggableComponentPlaceholderInterface } from '@cactussoft/services/services-board/create-service/draggable-components.interface';
import { isNullOrUndefined } from '@shared/tools/is-undefined-null';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MultiLanguageDialogComponent } from '@shared/components/multi-language-dialog/multi-language-dialog.component';
import { FormValidationService } from '@shared/services/update-validity/update-validity.service';
import { priceMaskConfig } from '@shared/tools/price-mask';
import { FactoryArg } from 'imask';

@Component({
	selector: 'cactussoft-two-column-product-list',
	templateUrl: './two-column-product-list.component.html',
	styleUrls: ['./two-column-product-list.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TwoColumnProductListComponent implements OnInit {
	@Input() public iconName: string;
	@Input() public placeholder: DraggableComponentPlaceholderInterface;
	@Input() public initialValues: any;
	@Output() public removeComponentSource: EventEmitter<object> = new EventEmitter<object>();
	@Output() public changeValuesSource: EventEmitter<any> = new EventEmitter<any>();

	public formGroup: UntypedFormGroup;
	public maskConfig: FactoryArg = priceMaskConfig();

	private formSubscription: Subscription;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private dialog: MatDialog,
		private cdr: ChangeDetectorRef,
		private updateValidationService: FormValidationService
	) {}

	public ngOnInit(): void {
		isNullOrUndefined(this.initialValues) ? this.createDefaultForm() : this.createFormWithValues();
		this.formSubscription = this.formGroup.valueChanges.subscribe((value: string) => {
			this.changeValuesSource.emit(value);
		});
		this.updateValidationService.checkValidation$.subscribe(() => {
			this.updateValidationService.validateAllFormFields(this.formGroup);
			this.cdr.detectChanges();
		});
	}

	public openMultiLanguageDialog(inputFormGroup: any, type: string, placeholder: string): void {
		const dialogRef: MatDialogRef<MultiLanguageDialogComponent> = this.dialog.open(MultiLanguageDialogComponent, {
			data: { value: inputFormGroup.value, type: type, placeholder: placeholder },
			width: '800px',
			height: 'auto'
		});
		dialogRef.componentInstance.dialogSource.subscribe((data: any) => {
			inputFormGroup.setValue(data);
			inputFormGroup.markAllAsTouched();
			dialogRef.close();
		});
	}

	public addFormControl(option?: any): void {
		const productsArray: UntypedFormArray = this.formGroup.controls.variants as UntypedFormArray;
		const newProductGroup: UntypedFormGroup = isNullOrUndefined(option?.names)
			? this.formBuilder.group({
					id: new UntypedFormControl(null),
					names: this.formBuilder.group({
						English: new UntypedFormControl('', [Validators.required, Validators.maxLength(61)]),
						French: new UntypedFormControl('', [Validators.required, Validators.maxLength(61)]),
						Dutch: new UntypedFormControl('', [Validators.required, Validators.maxLength(61)]),
						German: new UntypedFormControl('', [Validators.required, Validators.maxLength(61)])
					}),
					values: this.formBuilder.group({
						English: new UntypedFormControl('', [Validators.required, Validators.maxLength(61)]),
						French: new UntypedFormControl('', [Validators.required, Validators.maxLength(61)]),
						Dutch: new UntypedFormControl('', [Validators.required, Validators.maxLength(61)]),
						German: new UntypedFormControl('', [Validators.required, Validators.maxLength(61)])
					})
			  })
			: this.formBuilder.group({
					id: new UntypedFormControl(option.id),
					names: this.formBuilder.group({
						English: new UntypedFormControl(option.names.English, [Validators.required, Validators.maxLength(61)]),
						French: new UntypedFormControl(option.names.French, [Validators.required, Validators.maxLength(61)]),
						Dutch: new UntypedFormControl(option.names.Dutch, [Validators.required, Validators.maxLength(61)]),
						German: new UntypedFormControl(option.names.German, [Validators.required, Validators.maxLength(61)])
					}),
					values: this.formBuilder.group({
						English: new UntypedFormControl(option.values.English, [Validators.required, Validators.maxLength(61)]),
						French: new UntypedFormControl(option.values.French, [Validators.required, Validators.maxLength(61)]),
						Dutch: new UntypedFormControl(option.values.Dutch, [Validators.required, Validators.maxLength(61)]),
						German: new UntypedFormControl(option.values.German, [Validators.required, Validators.maxLength(61)])
					})
			  });
		productsArray.insert(productsArray.length, newProductGroup);
	}

	public remove(index: number): void {
		index === 0 ? this.removeComponent() : this.removeFormControl(index);
	}

	private createDefaultForm(): void {
		this.formGroup = this.formBuilder.group({
			id: new UntypedFormControl(null),
			titles: this.formBuilder.group({
				English: new UntypedFormControl(null, [Validators.required, Validators.maxLength(401)]),
				French: new UntypedFormControl(null, Validators.maxLength(401)),
				Dutch: new UntypedFormControl(null, Validators.maxLength(401)),
				German: new UntypedFormControl(null, Validators.maxLength(401))
			}),
			variants: this.formBuilder.array([
				this.formBuilder.group({
					id: new UntypedFormControl(null),
					names: this.formBuilder.group({
						English: new UntypedFormControl('', [Validators.required, Validators.maxLength(61)]),
						French: new UntypedFormControl('', [Validators.required, Validators.maxLength(61)]),
						Dutch: new UntypedFormControl('', [Validators.required, Validators.maxLength(61)]),
						German: new UntypedFormControl('', [Validators.required, Validators.maxLength(61)])
					}),
					values: this.formBuilder.group({
						English: new UntypedFormControl('', [Validators.required, Validators.maxLength(61)]),
						French: new UntypedFormControl('', [Validators.required, Validators.maxLength(61)]),
						Dutch: new UntypedFormControl('', [Validators.required, Validators.maxLength(61)]),
						German: new UntypedFormControl('', [Validators.required, Validators.maxLength(61)])
					})
				})
			]),
			isMandatory: new UntypedFormControl(false)
		});
		// TODO: Set empty arrays for editing template (compose template object)
		this.changeValuesSource.emit(this.formGroup.value);
	}

	private createFormWithValues(): void {
		this.formGroup = this.formBuilder.group({
			id: new UntypedFormControl(this.initialValues.id),
			variants: this.formBuilder.array([]),
			titles: this.formBuilder.group({
				English: new UntypedFormControl(this.initialValues.titles.English, [Validators.required, Validators.maxLength(401)]),
				French: new UntypedFormControl(this.initialValues.titles.French),
				Dutch: new UntypedFormControl(this.initialValues.titles.Dutch),
				German: new UntypedFormControl(this.initialValues.titles.German)
			}),
			isMandatory: new UntypedFormControl(this.initialValues.isMandatory)
		});
		this.initialValues.variants.forEach((variant: string) => {
			this.addFormControl(variant);
		});
	}

	private removeComponent(): void {
		this.removeComponentSource.emit({});
	}

	private removeFormControl(i: number): void {
		const productsArray: UntypedFormArray = this.formGroup.controls.variants as UntypedFormArray;
		productsArray.removeAt(i);
	}

	get titlesFormGroup(): any {
		return this.formGroup.controls.titles as UntypedFormGroup;
	}

	get variantsControls(): any {
		return (this.formGroup.controls.variants as UntypedFormArray).controls;
	}
}
