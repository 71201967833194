<div class="badge" (click)="changePopoverState()">
	<span>{{popoverData ? popoverData.length : 0}}</span>
	<div class="popover" *ngIf="isOpen">
		<span class="popover__item" *ngFor="let item of popoverData">
			{{item}}
		</span>
	</div>
</div>


