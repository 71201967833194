import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
	selector: 'cactussoft-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html',
	styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
	public dialogData: {
		title: string;
		message: string;
		acceptButton?: string;
		cancelButton?: string;
	};

	constructor(
		public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			title: string;
			message: string;
			acceptButton?: string;
			cancelButton?: string;
		}
	) {
		this.dialogData = data;
	}

	public onChange(state: boolean): void {
		this.dialogRef.close(state);
	}
}
