<div class='accordion-container'>
	<div *ngFor='let menuItem of menuLinks'>
		<a class='link' *ngIf='!menuItem.children' routerLink='{{menuItem.linkUri}}' routerLinkActive='link_active'>
			<mat-icon class='link-icon'>{{menuItem.iconName}}</mat-icon>
			{{menuItem.name}}</a>
		<mat-expansion-panel *ngIf='menuItem.children' class='sub-menu'>
			<mat-expansion-panel-header class='sub-menu__link'>
				<a class='link' routerLink='{{menuItem.linkUri}}' routerLinkActive='link_active'>
					<mat-icon class='link-icon'>{{menuItem.iconName}}</mat-icon>
					{{menuItem.name}}</a>
			</mat-expansion-panel-header>
			<div class='sub-menu__list'>
				<a *ngFor='let childLink of menuItem.children'
				   routerLink='{{childLink.linkUri}}' routerLinkActive='link_active'>{{childLink.name}}</a>
			</div>
		</mat-expansion-panel>
	</div>
	<a class='link' (click)='logout()'>
		<mat-icon class='link-icon'>logout</mat-icon>
		Logout</a>
</div>
