export const ADMIN_SLIDE_MENU_LINKS: MenuLink[] = [
	{
		name: 'Services',
		linkUri: '/services',
		iconName: 'dynamic_feed',
		children: [
			{
				name: 'Templates',
				linkUri: '/templates',
				iconName: ''
			}
		]
	},
	{
		name: 'News',
		linkUri: '/news',
		iconName: 'description',
		children: [
			{
				name: 'Suggested',
				linkUri: '/suggest-news',
				iconName: ''
			}
		]
	},
	{ name: 'Buildings', linkUri: '/buildings', iconName: 'maps_home_work' },
	{ name: 'Clients', linkUri: '/clients', iconName: 'apartment' },
	{ name: 'Users', linkUri: '/mobile-users', iconName: 'people' },
	{ name: 'Managers', linkUri: '/managers', iconName: 'switch_account' }
	// { name: 'Settings', linkUri: '/settings', iconName: 'settings' }
];
export const MANAGER_SLIDE_MENU_LINKS: MenuLink[] = [
	{
		name: 'News',
		linkUri: '/news',
		iconName: 'description',
		children: [
			{
				name: 'Suggested',
				linkUri: '/suggest-news',
				iconName: ''
			}
		]
	},
	{ name: 'Users', linkUri: '/mobile-users', iconName: 'people' }
	// { name: 'Settings', linkUri: '/settings', iconName: 'settings' }
];

export interface MenuLink {
	name: string;
	linkUri: string;
	iconName: string;
	children?: MenuLink[];
}
